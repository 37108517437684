import React, { ChangeEvent, useState } from 'react';
import { SvgIcon } from '../../components/atoms/SvgIcon';
import clsx from 'clsx';
import Button from '../../components/atoms/Button';

export type SubmitOrderProps = {
  onBack: () => void;
}

const SubmitOrder = ({ onBack }: SubmitOrderProps) => {
  const [reviewTitle, setReviewTitle] = useState<string>('');
  const [tellUs, setTellUs] = useState<string>('');

  const handleBack = () => {
    onBack();
  }

  const handleChangeReviewTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setReviewTitle(e.target.value);
  }

  const handleChangeTellUs = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTellUs(e.target.value);
  }
  
  return (
    <div className="absolute top-0 left-0 w-screen h-screen z-10 flex flex-col bg-homeBg">
      <div className="pt-15 pb-5 border-b border-borderPrimary flex justify-between items-center px-5">
        <SvgIcon name="chevron-left" onClick={handleBack} />
        <h2 className="text-lg font-bold">Order  #TC12345</h2>
        <div />
      </div>
      <div className="flex-1 flex flex-col justify-between px-5">
        <div>
          <div className="mt-5">
            <p className="text-2xs text-gray-750 uppercase mb-1">Leave Review</p>
            <div className="bg-white px-4 py-3 rounded-primary">
              <p className="text-xs font-semibold opacity-50 mb-3 capitalize">Tell us how your artisan did ?</p>
              <div className="flex items-center gap-3 mb-3">
                {new Array(5).fill('').map((_, index) => (
                  <SvgIcon
                    name={index < 4 ? 'star' : 'star-outline'}
                    className={clsx(index < 4 ? 'text-primary w-6 h-6' : 'text-grayText w-5 h-5')}
                  />
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-3 mt-10">
              <p className="font-semibold capitalize">What should others know about this artisan?</p>
              <input
                placeholder="Title of Review"
                value={reviewTitle}
                onChange={handleChangeReviewTitle}
                className="border border-borderPrimary outline-none placeholder:text-grayText w-full px-3 py-3 text-xs rounded-lg bg-white"
              />
              <textarea
                placeholder="Tell us what other should know..."
                value={tellUs}
                onChange={handleChangeTellUs}
                className="h- border border-borderPrimary outline-none placeholder:text-grayText w-full h-37 px-3 py-3 text-xs rounded-lg bg-white"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-6.5 mb-10">
          <p className="text-xs text-grayText text-center">
            Thank you for leaving a review. Your feedback helps us ensure that our marketplace collaborates exclusively with the highest quality artisans.
          </p>
          <div className="w-67">
            <Button
              fullWidth
              contained
              className="!text-sm"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmitOrder;