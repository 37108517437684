import { FC, useState } from "react";
import { funnelDisabledAtom, selectedMetadataAtom } from "../../../jotai";
import { useAtom } from "jotai";
import FunnelSteps from "./FunnelSteps";
import { Button } from "../../atoms";
import { ArrowLeft2 } from "iconsax-react";
import Stepper from "../../atoms/Stepper";
import GeneralModal from "../../molecules/GeneralModal";

interface QuoteFunnelProps {
  onClose: () => void;
}

const QuoteFunnel: FC<QuoteFunnelProps> = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [isPersonal, setIsPersonal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const selectedMetadata = useAtom(selectedMetadataAtom);
  const [funnelDisabled, setFunnelDisabled] = useAtom(funnelDisabledAtom);

  const meta = selectedMetadata[0];

  const handleFunnelNav = (foward = true) => {
    if (!foward) {
      setIsPersonal(false);
      if (step === 1) setShowModal(true);
      else setStep(step - 1);
    } else {
      setFunnelDisabled(true);

      if (step === 5) return;
      else {
        if (step + 1 === 5) setIsPersonal(true);
        setStep(step + 1);
      }
    }
  };

  if (!meta) return null;

  return (
    <div className="flex flex-col fixed bottom-0 left-0 right-0 w-full h-3/4 screen-h-3/4 bg-white rounded-t-3xl z-10 p-5 shadow-2xl">
      <div className="flex items-center justify-between capitalize text-lg font-extrabold text-blue mb-3">
        <button className="mr-2" onClick={() => handleFunnelNav(false)}>
          <ArrowLeft2 size={20} className="text-blue" />
        </button>
        <p>{!isPersonal ? meta.category.name : "Personal Information"}</p>
        <p className="w-5" />
      </div>

      <div className="flex justify-center">
        <Stepper stepsCount={5} activeStep={step - 1} />
      </div>

      <div className="flex-1 overflow-y-auto my-2 hide-scrollbar">
        <FunnelSteps step={step} meta={meta} />
      </div>

      {!isPersonal && (
        <Button
          contained
          disabled={!!funnelDisabled}
          className="!font-medium"
          onClick={() => handleFunnelNav(true)}
        >
          Continue
        </Button>
      )}

      {showModal && (
        <GeneralModal
          type="alert"
          mainText="Are you sure you want to cancel your quote request?"
          helperText="If you click ‘Confirm’ you will lose all of your information"
          actionButtons={[
            <Button fullWidth contained onClick={() => onClose()}>
              Confirm
            </Button>,
            <Button onClick={() => setShowModal(false)}>Cancel</Button>,
          ]}
        />
      )}
    </div>
  );
};

export default QuoteFunnel;
