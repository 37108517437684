import Navbar from "../../components/organisms/Navbar";
import ImageButton from "../../components/molecules/ImageButton";
import ButtonList from "../../components/molecules/ButtonList";
import { useEffect, useState } from "react";
import SubmitQuote from "./SubmitQuote";
import { MetadataApi } from "../../apis/metadata.api";
import { Metadata } from "../../types/metadata";
import { useSetAtom } from "jotai";
import {
  productDetailsAtom,
  selectedBrandAtom,
  selectedMetadataAtom,
} from "../../jotai/quote.jotai";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CraftyzLogo } from "../../components";
import { CDN_NAME } from "../../constants";
import QuoteFunnel from "../../components/quote/funnel/QuoteFunnel";

const iconMap: Record<string, string> = {
  "Men Shoes": "man-shoes",
  "Women Shoes": "woman-shoes",
  "All / Funnel / Men Shoes": "man-shoes",
  "All / Funnel / Women Shoes": "woman-shoes",
  Handbags: "bag",
  Sneakers: "sneakers",
  Luggage: "luggage",
  Wallets: "wallet",
  Belt: "watch",
};

const HomePage = () => {
  const [openQuotePopup, setOpenQuotePopup] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<Metadata[]>([]);
  const [showFunel, setShowFunel] = useState<boolean>(false);
  const setSelectedMetaData = useSetAtom(selectedMetadataAtom);
  const setSelectedBrand = useSetAtom(selectedBrandAtom);
  const setProductDetails = useSetAtom(productDetailsAtom);
  const [searchParams] = useSearchParams();
  // const navigate = useNavigate();

  useEffect(() => {
    MetadataApi.getAllBrands().then((res) => {
      setMetadata(res);
    });
  }, []);

  useEffect(() => {
    if (metadata.length) {
      try {
        const category_id = searchParams.get("category_id");
        const meta = metadata.find(({ category }: any) => `${category.id}` === `${category_id}`);
        // console.log("meta: ", meta);
        !!meta && onSelectMetaData(meta);
      } catch (error) {
        console.log("error: ", error);
      }
    }
  }, [metadata]);

  const onSelectMetaData = (meta: Metadata) => {
    setSelectedMetaData(meta);
    setSelectedBrand(null);
    setProductDetails(null);
    setShowFunel(true);
    // navigate("/submit-quote");
  };

  const handleClose = () => {
    setOpenQuotePopup(false);
  };

  return (
    <div className={`flex flex-col items-center ${showFunel && "h-screen overflow-hidden"}`}>
      <div className="flex flex-col justify-end items-center w-full h-71.25 bg-loginBg">
        <CraftyzLogo />

        <div className="mt-8 w-full">
          <img className="w-full h-auto" src="/images/home_cobblers.png" alt="craftyz" />
        </div>
      </div>
      <div className="flex flex-col gap-9 bg-homeBg w-full px-4.5 pt-10 pb-25">
        <div className="mb-10">
          <p className="mb-4 text-blue text-lg font-extrabold capitalize">
            What would you like to repair ?
          </p>
          <div className="grid grid-cols-3 gap-2.5">
            {metadata.map((item, index) => {
              return (
                <div
                  key={index}
                  className="rounded-small bg-white p-3.5"
                  onClick={() => onSelectMetaData(item)}
                >
                  <div className="flex items-center justify-center w-9.5 h-9.5 p-0.5 mb-5 rounded-small border border-iconBorder">
                    <img
                      src={`https://${CDN_NAME}.blob.core.windows.net/images/category_icon_${item.category.id}.svg`}
                    />
                  </div>
                  <p className="text-blue text-xs font-semibold">{item.category.name}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <div className="mb-3.75 flex items-center">
            <p className="text-lg text-blue font-extrabold capitalize leading-none">
              Self-Checkout Services
            </p>
            <span className="text-primary ml-3.5 mt-1 text-2xs text-helperText font-normal uppercase leading-none">
              COMING SOON
            </span>
          </div>
          <div className="grid grid-cols-2 gap-3.5">
            <div>
              <ImageButton image="honiii-bag.png" label="Premium Handbag Cleaning" />
            </div>
            <div>
              <ImageButton image="shoes.png" label="Premium shoe Cleaning" />
            </div>
          </div>
        </div>
        <div>
          <p className="text-2xs text-grayText font-semibold uppercase">More Options</p>
          <div className="mt-1 bg-white">
            <ButtonList
              className="opacity-70"
              list={[
                {
                  icon: "layer",
                  label: "Download Our App!",
                  href: "https://restore.craftyz.com/about",
                },
                {
                  icon: "info",
                  label: "About Craftyz",
                  href: "https://restore.craftyz.com/about",
                },
                {
                  icon: "briefcase",
                  label: "Service Information",
                  href: "https://restore.craftyz.com/repair",
                },
                { icon: "follow", label: "Follow Us" },
              ]}
              onClick={({ href }) => href && window.open(href)}
            />
          </div>
        </div>
      </div>
      <Navbar />
      {openQuotePopup && <SubmitQuote isOpen={openQuotePopup} onClose={handleClose} />}
      {showFunel && <QuoteFunnel onClose={() => setShowFunel(false)} />}
    </div>
  );
};

export default HomePage;
