import clsx from 'clsx';
import { ReactNode } from 'react';

type Props = {
  icon: ReactNode;
  activeIcon?: ReactNode;
  label: string;
  active?: boolean;
  className?: string;
  onClick?: () => void;
};
const NavButton = ({ icon, activeIcon, label, active = false, className, onClick }: Props) => {
  return (
    <div
      className={clsx(
        'bg-white w-19.5 h-17.5 p-3 pt-3.25 flex flex-col justify-center items-center gap-2.25 cursor-pointer',
        className,
      )}
      onClick={onClick}
    >
      {active && activeIcon ? activeIcon : icon}
      <div className={clsx('text-2xs', active ? 'text-primary font-semibold' : 'text-grayText')}>{label}</div>
    </div>
  );
};

export default NavButton;
