import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAtomValue } from "jotai";
import { useFormik } from "formik";
import { Button, LoadingView } from "../../atoms";
import { Input } from "../../atoms/Input";
import personalInfoSchema from "../../../schemas/personalInfoSchema";
import { CustomerApi } from "../../../apis/customer.api";
import { InferType } from "yup";
import { useCustomers } from "../../../contexts";
import { newProductDetailsAtom } from "../../../jotai";

const FunnelPersonalInfo: FC = () => {
  const { userId, leadId } = useCustomers();
  const productDetails = useAtomValue(newProductDetailsAtom);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    window.location.hash = "submit-personal-info";
  }, []);
  const onSubmit = async (values: InferType<typeof personalInfoSchema>) => {
    if (!userId || !leadId || !productDetails) return;

    try {
      setLoading(true);

      localStorage.setItem("cobbler_lead_mobile", values.mobile);

      await CustomerApi.updatePersonalInformation(userId, {
        guid: userId,
        email: values.email,
        mobile: values.mobile,
        zip: values.zip,
        fullname: values.name,
      });

      await CustomerApi.updateProductDetails(leadId, productDetails);

      navigate("/submit-result", { state: { name: form.values.name } });
    } catch (err) {
      console.error("Update Personal Information::", err);
    } finally {
      setLoading(false);
    }
  };

  const form = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      zip: "",
    },
    validationSchema: personalInfoSchema,
    onSubmit,
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <div className="flex flex-col gap-3">
        <Input
          icon="user"
          placeholder="Full Name"
          {...form.getFieldProps("name")}
          helperText={
            form.errors.name && form.touched.name ? form.errors.name : ""
          }
          error={!!form.errors.name && !!form.touched.name}
        />
        <Input
          icon="sms"
          placeholder="Email ID"
          {...form.getFieldProps("email")}
          helperText={
            form.errors.email && form.touched.email ? form.errors.email : ""
          }
          error={!!form.errors.email && !!form.touched.email}
        />
        <Input
          icon="phone"
          placeholder="Phone Number"
          {...form.getFieldProps("mobile")}
          helperText={
            form.errors.mobile && form.touched.mobile ? form.errors.mobile : ""
          }
          error={!!form.errors.mobile && !!form.touched.mobile}
          onChange={(e) => {
            let value = e.target.value;

            if (!value.startsWith("+")) value = `+1 ${value}`;

            form.setFieldValue("mobile", value);
          }}
        />
        <Input
          icon="hash-tag"
          placeholder="Zip Code (to optimise artisan matching)"
          {...form.getFieldProps("zip")}
          helperText={
            form.errors.zip && form.touched.zip ? form.errors.zip : ""
          }
          error={!!form.errors.zip && !!form.touched.zip}
        />
      </div>

      <div className="h-[110px]" />
      <LoadingView show={isLoading} />

      <div className="fixed left-0 bottom-0 w-full p-5">
        <Button
          contained={form.isValid}
          fullWidth
          type="submit"
          disabled={!form.isValid}
        >
          Continue
        </Button>
      </div>
    </form>
  );
};

export default FunnelPersonalInfo;
