import clsx from 'clsx';
import { SvgIcon } from '../SvgIcon';

type Props = {
  label: string;
  variant?: 'default' | 'filter';
  active?: boolean;
  className?: string;
  onClick?: () => void;
};

const variantClasses = {
  default: {
    default: 'px-3.5 py-2.5 rounded-lg text-brandTag font-semibold capitalize',
    active: 'border-[1.5px] border-primary text-primary',
    inactive: 'border-[1.5px] border-tagBorder text-tagText'
  },
  filter: {
    default: 'px-3.75 h-6 flex justify-center items-center rounded-small text-xs font-normal capitalize',
    active: 'border-textPrimary text-white bg-textPrimary',
    inactive: 'border-tagBorder text-tagText'
  }
}

const BrandTag = ({
  label,
  variant = 'default',
  active,
  className,
  onClick
}: Props) => {
  return (
    <div
      className={clsx(
        'border flex items-center space-x-2 min-w-fit w-fit leading-none',
        variantClasses[variant]['default'],
        active ? variantClasses[variant]['active'] : variantClasses[variant]['inactive'],
        className,
      )}
      onClick={onClick}
    >
      {active && variant === 'default' && <SvgIcon name="tick" width={16} height={12} className="mr-3" />}
      {label}
    </div>
  );
};

export default BrandTag;
