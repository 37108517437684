import { useAtom } from "jotai";
import { FC, useEffect } from "react";
import { funnelDisabledAtom, newProductDetailsAtom } from "../../../jotai";

interface QuoteFunnelItemIntentionProps {
  setDisabled?: (disabled: boolean) => void;
}

const QuoteFunnelItemIntention: FC<QuoteFunnelItemIntentionProps> = ({
  setDisabled,
}) => {
  const [productDetail, setProductDetail] = useAtom(newProductDetailsAtom);
  const [_, setFunnelDisabled] = useAtom(funnelDisabledAtom);
  const amount = productDetail?.indicatedValue;

  useEffect(() => {
    setFunnelDisabled(false);
    window.location.hash = "submit-value";
  }, []);

  return (
    <div className="text-center">
      <p className="text-blue font-extrabold text-xl my-2">${amount}</p>
      <input
        type="range"
        min={100}
        max={15000}
        step={100}
        className="w-3/4 accent-blue slider"
        value={amount}
        onChange={(e) => {
          setProductDetail({
            ...productDetail,
            indicatedValue: parseInt(e.target.value),
          });
          setDisabled && setDisabled(false);
        }}
      />
    </div>
  );
};

export default QuoteFunnelItemIntention;
