import { ThreeDots } from "react-loader-spinner";

export const LoadingView = ({ show = false }) => {
  if (!show) return null;

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#0000004d] z-50 flex items-center justify-center">
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#4CAC96"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        visible={true}
      />
    </div>
  );
};
