import React, { ChangeEvent, useState } from 'react';
import { SvgIcon } from '../../components/atoms/SvgIcon';
import Button from '../../components/atoms/Button';
import clsx from 'clsx';

type AddressType = {
  id: number,
  street: string,
  address: string
}

const addressList: AddressType[] = [
  { id: 1, street: 'Durham, NC 27705', address: '831 Wesley Chapel Dr' },
  { id: 2, street: 'Durham, NC 27705', address: '831 Wesley Chapel Dr' },
  { id: 3, street: 'Durham, NC 27705', address: '831 Wesley Chapel Dr' },
  { id: 4, street: 'Durham, NC 27705', address: '831 Wesley Chapel Dr' },
];

export type ConfirmShippingAddressProps = {
  onClose: () => void;
  onConfirmAddress: () => void;
}

export default ({ onClose, onConfirmAddress }: ConfirmShippingAddressProps) => {
  const [search, setSearch] = useState<string>('');
  const [selectedAddress, setSelectedAddress] = useState<AddressType>();

  const handleCloseConfirmAddress = () => {
    onClose();
  }

  const handleConfirmAddress = () => {
    onConfirmAddress();
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }

  const handleSelectAddress = (address: AddressType) => {
    setSelectedAddress(address);
  }

  return (
    <div className="flex flex-col justify-between h-194 max-h-screen bg-white rounded-t-large px-5 py-6">
      <div className="flex items-center gap-3">
        <SvgIcon name="chevron-left" onClick={handleCloseConfirmAddress} />
        <p className="text-lg font-extrabold capitalize">Confirm your Shipping Address</p>
      </div>
      <div className="flex-1 flex flex-col justify-between pt-5 pb-2">
        <div>
          <input
            placeholder="Search..."
            value={search}
            onChange={handleChange}
            className="px-3 py-2 outline-none bg-brandInputBg placeholder:text-sm placeholder-grayText rounded-lg border border-phoneBorder w-full"
          />
          <div className="flex flex-col gap-3 mt-6">
            {addressList.map(detail => (
              <div
                key={detail.id}
                className={clsx(
                  'flex justify-between items-center px-3 py-2 border-[1.5px] rounded-primary',
                  selectedAddress?.id === detail.id ? 'border-primary' : 'border-transparent'
                )}
                onClick={() => handleSelectAddress(detail)}
              >
                <div className="flex gap-4">
                  <div className="w-8.5 h-8.5 flex justify-center items-center bg-mediumGray rounded-lg">
                    <SvgIcon name="location-contained" className="text-primary" />
                  </div>
                  <div className="flex-1">
                    <p className="text-sm font-semibold">
                      {detail.address}
                    </p>
                    <p className="text-xs text-grayText">
                      {detail.street}
                    </p>
                  </div>
                </div>
                <div className={clsx(
                  'border-primary rounded-full',
                  selectedAddress?.id === detail.id ? 'border-4 w-4.5 h-4.5' : 'border w-4 h-4'
                )} />
              </div>
            ))}
          </div>
        </div>
        <Button
          contained
          fullWidth
          onClick={handleConfirmAddress}
          className="!text-brandTag"
        >
          Confirm Address
        </Button>
      </div>
    </div>
  )
}