import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

import { datadogRum } from '@datadog/browser-rum'

datadogRum.init({
  applicationId: '2ed07ab3-2300-4cc4-a0f0-26cc91b4f36b',
  clientToken: 'pubc021f97e7dbfab9839efa88bdedcec7c',
  site: 'datadoghq.com',
  service: 'customer-webapp',
  env: process.env.REACT_APP_DATADOG_ENV || 'dev',
  // Specify a version number to identify the deployed version of your application in Datadog 
  // version: '1.0.0', 
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
