import React, { useEffect, useState } from "react";
import { SvgIcon } from "../../components/atoms/SvgIcon";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { InferType } from "yup";
import { useFormik } from "formik";
import { Input } from "../../components/atoms/Input";
import Button from "../../components/atoms/Button";
import { useCustomers } from "../../contexts/customerContext";
import { CustomerApi } from "../../apis/customer.api";
import { formatPhoneNumber } from "../../utils";
import { AddressButton } from "../../components/organisms";
import { Address } from "../../types";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  email: Yup.string().email("Invalid Email").required("Name is required."),
  mobile: Yup.string().required("Phone number is required"),
  address: Yup.string().required("Address is required"),
});

export const EditProfile = () => {
  const navigate = useNavigate();
  const { currentUser, fetchMe } = useCustomers();
  const [addrObj, setAddrObj] = useState<Address | null>(null);

  const handlePrev = () => {
    navigate(-1);
  };

  const onSubmit = async (values: InferType<typeof validationSchema>) => {
    if (addrObj) {
      await CustomerApi.postAddresses(addrObj).then(fetchMe);
    }
    const result = await CustomerApi.getAddresses();
    console.log("vaibhav", result);
  };

  const form = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      address: "",
    },
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (currentUser) {
      form.setValues({
        name: currentUser.fullName,
        email: currentUser.email,
        mobile: formatPhoneNumber(currentUser.mobile),
        address: currentUser.defaultShippingAddress,
      });
    }
  }, [currentUser]);

  return (
    <div className="h-screen bg-homeBg px-4.5 flex flex-col">
      <div className="pt-15 pb-10 flex justify-between items-center">
        <SvgIcon name="chevron-left" onClick={handlePrev} />
        <h2 className="text-lg font-bold">Edit Profile</h2>
        <div />
      </div>
      {/*<div className="flex flex-col items-center mt-5">*/}
      {/*  <div className="relative w-30 h-30 p-1 border border-primary border-dashed rounded-full">*/}
      {/*    <img*/}
      {/*      src="/images/kristy.png"*/}
      {/*      alt="kristy"*/}
      {/*      className="w-full h-full rounded-full"*/}
      {/*    />*/}
      {/*    <div*/}
      {/*      className="absolute left-1/2 top-full -translate-x-1/2 -translate-y-1/2 w-8 h-8 border-2 border-white bg-black rounded-full flex justify-center items-center"*/}
      {/*    >*/}
      {/*      <SvgIcon name="pencil" className="text-pencil" />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <form
        onSubmit={form.handleSubmit}
        className="flex flex-col flex-1 pb-2 mt-0"
      >
        <h2 className="text-lg font-bold mb-1">Personal Information</h2>
        <p className="text-xs font-normal opacity-60 mb-3">
          Please confirm the information below and modify if necessary.
        </p>
        <div className="flex flex-col gap-3">
          <Input
            icon="user"
            placeholder="Full Name"
            {...form.getFieldProps("name")}
            helperText={
              form.errors.name && form.touched.name ? form.errors.name : ""
            }
            error={!!form.errors.name && !!form.touched.name}
            disabled
          />
          <Input
            icon="sms"
            placeholder="Email ID"
            {...form.getFieldProps("email")}
            helperText={
              form.errors.email && form.touched.email ? form.errors.email : ""
            }
            error={!!form.errors.email && !!form.touched.email}
            disabled
          />
          <Input
            icon="phone"
            placeholder="Phone Number"
            {...form.getFieldProps("mobile")}
            helperText={
              form.errors.mobile && form.touched.mobile
                ? form.errors.mobile
                : ""
            }
            error={!!form.errors.mobile && !!form.touched.mobile}
            disabled
          />
          <div className="relative">
            <AddressButton
              {...form.getFieldProps("address")}
              onChange={(address) => form.setFieldValue("address", address)}
              onChangeValue={setAddrObj}
            />
            {/* <Input
              icon="location-outlined"
              placeholder="Address"
              className="pr-8"
              {...form.getFieldProps("address")}
              helperText={
                form.errors.address && form.touched.address
                  ? form.errors.address
                  : ""
              }
              error={!!form.errors.address && !!form.touched.address}
            />
            <div className="absolute top-1/2 right-5 translate-x-1/2 -translate-y-1/2">
              <SvgIcon name="chevron-right" />
            </div> */}
          </div>
        </div>
        <div className="w-screen p-5 -mx-4.5 mt-auto bg-white">
          <Button type="submit" contained fullWidth>
            Save Changes
          </Button>
        </div>
      </form>
    </div>
  );
};
