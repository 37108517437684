import { ChangeEvent, FC, InputHTMLAttributes, Ref, forwardRef } from "react";
import { SvgIcon } from "../SvgIcon";
import clsx from "clsx";

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  icon?: string;
  helperText?: string;
  error?: boolean;
  name?: string;
  className?: string;
  ref?: Ref<HTMLInputElement>;
  disabled?: boolean;
};

export const Input: FC<InputProps> = forwardRef(
  (
    {
      name,
      value,
      icon,
      placeholder,
      onChange,
      helperText,
      error,
      className,
      disabled,
    },
    ref
  ) => {
    return (
      <div>
        <div className="relative">
          {icon && (
            <div className="absolute flex items-center border-r-[1.5px] top-2.5 bottom-2.5 left-4 pr-3.75">
              <SvgIcon name={icon} className="text-blue" />
            </div>
          )}
          <input
            ref={ref}
            name={name}
            className={clsx(
              "w-full outline-none h-13 border border-[#9DA4AF26] text-inputText font-semibold placeholder-gray-700 placeholder:font-medium py-2.5 px-4 focus:border-primary border-[1.5px] rounded-2.5 focus:bg-primary focus:bg-opacity-[0.06]",
              icon ? "pl-17" : "pl-4",
              error ? "border-alert focus:border-alert focus:bg-alert" : "",
              className
            )}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
        {error && <div className="text-alert text-xs ml-2">{helperText}</div>}
      </div>
    );
  }
);
