import clsx from "clsx";
import { Fragment } from "react";

interface IContactStep {
  title: string;
  details: string;
  isComplete: boolean;
}
const NextSteps = ({
  artisanName,
  createDate,
}: {
  artisanName: string;
  createDate: string;
}) => {
  const formatDate = (inputDate: string) => {
    const dateObject = new Date(inputDate);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();

    const formattedDate =
      day + "/" + (month < 10 ? "0" : "") + month + "/" + year;

    return formattedDate;
  };

  const contactSteps: IContactStep[] = [
    {
      title: "Step 1",
      details: "You will be charged a 50% deposit upon order confirmation.",
      isComplete: true,
    },
    {
      title: "Step 2",
      details: `Print your label and ship your item to ${
        artisanName.split(" ")[0]
      } for restoration.`,
      isComplete: true,
    },
    {
      title: "Step 3",
      details: `${artisanName.split(" ")[0]} will began working on your order.`,
      isComplete: true,
    },
    {
      title: "Step 4",
      details: "Review quality upon order completion and pay your balance.",
      isComplete: true,
    },
    {
      title: "Step 5",
      details: `Will be returned to you within 30 days`,
      isComplete: true,
    },
  ];

  return (
    <div className="flex space-x-3">
      <div className="flex flex-col items-center py-5">
        {Array.from({ length: contactSteps.length })
          .fill("")
          .map((_, index: number) => (
            <Fragment key={index}>
              {index > 0 && (
                <div
                  className={clsx(
                    "w-0.5 h-22 sm:h-16 border-none my-1",
                    contactSteps[index].isComplete
                      ? "bg-brightPrimary"
                      : "bg-gray-600"
                  )}
                />
              )}
              {contactSteps[index].isComplete ? (
                <div className="w-4.5 h-4 rounded-full bg-brightPrimary flex items-center justify-center"></div>
              ) : (
                <div className="w-7 h-7 min-w-7 min-h-7 bg-gray-750 rounded-full" />
              )}
            </Fragment>
          ))}
      </div>
      <div className="flex-1 flex flex-col divide-y divide-gray-300">
        {contactSteps.map((step: any, index: number) => (
          <div key={index} className="py-5">
            <p
              className={clsx(
                "text-quoteStatus font-bold capitalize",
                step.isComplete ? "text-blue" : "text-gray-750"
              )}
            >
              {step?.title}
            </p>
            <div className="flex items-center mt-2 gap-1 text-gray-750">
              {step?.details}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NextSteps;
