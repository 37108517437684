import clsx from "clsx";
import { SvgIcon } from "../../atoms/SvgIcon";

type Button = {
  label: string;
  icon: string;
  href?: string;
};

type Props = {
  list: Button[];
  className?: string;
  onClick?: (item: any) => void;
};

const ButtonList = ({ list, className, onClick }: Props) => {
  return (
    <div
      className={clsx(
        "divide-y divide-borderPrimary rounded-small px-4.5 py-1.75",
        className
      )}
    >
      {list.map((item, index) => (
        <div
          key={index}
          className="flex items-center justify-between w-full h-14 bg-white cursor-pointer"
          onClick={() => onClick && onClick(item)}
        >
          <div className="flex gap-3 items-center">
            <SvgIcon name={item.icon} className="text-blue" />
            <p className="text-blue font-medium">{item.label}</p>
          </div>
          <SvgIcon name="chevron-right" className="text-backdrop" />
        </div>
      ))}
    </div>
  );
};

export default ButtonList;
