import { ArrowLeft2 } from "iconsax-react";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { productDetailsAtom } from "../jotai";
import { useAtomValue } from "jotai";
import Stepper from "../components/atoms/Stepper";
import { Button, LoadingView } from "../components";
import _ from "lodash";
import { useCustomers } from "../contexts";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InferType } from "yup";
import { CustomerApi } from "../apis/customer.api";
import { Input } from "../components/atoms/Input";
import { validatePhoneNumber } from "../utils";

export default () => {
  const navigate = useNavigate();
  const { userId, leadId } = useCustomers();
  const productDetails = useAtomValue(productDetailsAtom);
  const [isLoading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    email: Yup.string().email("Invalid Email").required("Name is required."),
    budget: Yup.number()
      .typeError("Budget must be defined in numbers")
      .moreThan(0, "Budget should greater than zero"),
    mobile: Yup.string()
      .required("Phone number is required")
      .test(
        "is-valid-phone",
        "Invalid Phone Number. Check Country Code",
        (value) => {
          const result = validatePhoneNumber(value);
          if (!result) {
            if (!value.startsWith("+")) {
              form.setFieldValue("mobile", `+1 ${value}`);
            }
          }
          return result;
        }
      ),
    zip: Yup.string().required("Zip code is required"),
  });

  const onSubmit = async (values: InferType<typeof validationSchema>) => {
    if (!userId || !leadId || !productDetails) return;

    try {
      setLoading(true);

      localStorage.setItem("cobbler_lead_mobile", values.mobile);

      await CustomerApi.updatePersonalInformation(userId, {
        guid: userId,
        email: values.email,
        mobile: values.mobile,
        zip: values.zip,
        fullname: values.name,
      });

      if (values.budget) {
        productDetails.budget = +values.budget;
      }
      await CustomerApi.updateProductDetails(leadId, productDetails);

      navigate("/submit-result", { state: { name: form.values.name } });
    } catch (err) {
      console.error("Update Personal Information::", err);
    } finally {
      setLoading(false);
    }
  };

  const form = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      budget: undefined,
      zip: "",
    },
    validationSchema,
    onSubmit,
  });

  if (!userId || !leadId || !productDetails) return <Navigate to="/" replace />;

  return (
    <div className="p-4">
      <div className="flex items-center capitalize text-lg font-extrabold text-blue mb-3">
        <button className="mr-2" onClick={() => navigate(-1)}>
          <ArrowLeft2 size={20} className="text-blue" />
        </button>
        Personal Information
      </div>

      <div className="text-[#838C92] text-xs font-medium mb-10">
        Please provide us your information. This will help us to match you with
        an expert artisan.
      </div>

      <form onSubmit={form.handleSubmit}>
        <div className="flex flex-col gap-3">
          <Input
            icon="user"
            placeholder="Full Name"
            {...form.getFieldProps("name")}
            helperText={
              form.errors.name && form.touched.name ? form.errors.name : ""
            }
            error={!!form.errors.name && !!form.touched.name}
          />
          <Input
            icon="sms"
            placeholder="Email ID"
            {...form.getFieldProps("email")}
            helperText={
              form.errors.email && form.touched.email ? form.errors.email : ""
            }
            error={!!form.errors.email && !!form.touched.email}
          />
          <Input
            icon="phone"
            placeholder="Phone Number"
            {...form.getFieldProps("mobile")}
            helperText={
              form.errors.mobile && form.touched.mobile
                ? form.errors.mobile
                : ""
            }
            error={!!form.errors.mobile && !!form.touched.mobile}
          />
          <Input
            icon="dollar-square"
            placeholder="Do you have a budget in mind?"
            {...form.getFieldProps("budget")}
            helperText={
              form.errors.budget && form.touched.budget
                ? form.errors.budget
                : ""
            }
            error={!!form.errors.budget && !!form.touched.budget}
          />
          <Input
            icon="hash-tag"
            placeholder="Zip Code (to optimise artisan matching)"
            {...form.getFieldProps("zip")}
            helperText={
              form.errors.zip && form.touched.zip ? form.errors.zip : ""
            }
            error={!!form.errors.zip && !!form.touched.zip}
          />
        </div>

        <div className="h-[110px]" />

        <div className="fixed left-0 bottom-0 w-full z-10 p-5 bg-white shadow-footer">
          <div className="flex justify-center">
            <Stepper stepsCount={3} activeStep={2} />
          </div>
          <div className="mt-5 flex justify-between items-center gap-2.5">
            <Button onClick={() => navigate(-1)} className="!px-9">
              Back
            </Button>
            <Button contained fullWidth type="submit" className="capitalize">
              Submit your Quote
            </Button>
          </div>
        </div>
      </form>

      <LoadingView show={isLoading} />
    </div>
  );
};
