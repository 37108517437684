import { FC } from "react";
import { Metadata } from "../../../types/metadata";
import QuoteFunnelBrand from "./QuoteFunnelBrand";
import QuoteFunnelItemIntention from "./QuoteFunnelItemIntention";
import QuoteFunnelItemValue from "./QuoteFunnelItemValue";
import QuoteFunnelItemFix from "./QuoteFunnelItemFix";
import FunnelPersonalInfo from "./FunnelPersonalInfo";

interface FunnelStepsProps {
  step: number;
  meta: Metadata;
}

const FunnelSteps: FC<FunnelStepsProps> = ({ step, meta }) => {
  const title =
    step === 1
      ? `What brand is your ${meta.category.name.toLowerCase()}?`
      : step === 2
      ? "What are you looking to fix?"
      : step === 3
      ? "What is your intention for the repair?"
      : step === 4
      ? `What is the estimated value of your item?`
      : "";

  return (
    <>
      <p className="text-blue font-extrabold text-center my-4">{title}</p>
      {step === 1 && <QuoteFunnelBrand />}
      {step === 2 && <QuoteFunnelItemFix />}
      {step === 3 && <QuoteFunnelItemIntention />}
      {step === 4 && <QuoteFunnelItemValue />}
      {step === 5 && <FunnelPersonalInfo />}
    </>
  );
};

export default FunnelSteps;
