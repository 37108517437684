export const getPlatform = () => {
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.includes("android");
  if (isAndroid) return "android";

  const isIPhone =
    navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i);

  if (isIPhone) return "ios";

  return "unknown";
};
