import { Navigate, useLocation } from "react-router-dom";
import { useCustomers } from "../contexts/customerContext";

const PublicRoute = ({ children }: any) => {
  const { pathname } = useLocation();

  const { isAuthenticated } = useCustomers();

  if (isAuthenticated === null) return;

  if (isAuthenticated && pathname !== "/otp-login") {
    return <Navigate to="/" />;
  }

  return children;
};

export default PublicRoute;
