import { useAtom } from "jotai";
import { Todo } from "../../../types";
import clsx from "clsx";
import { todosAtom } from "../../../jotai/todo.jotai";

type TodoItemProps = {
  item: Todo;
};

const TodoItem = ({ item }: TodoItemProps) => {
  const [, setTodos] = useAtom(todosAtom);

  const handleToggleTodo = () => {
    setTodos((prev) =>
      prev.map((todo) => {
        if (todo === item) {
          return {
            ...todo,
            completed: !todo.completed,
          };
        }
        return todo;
      })
    );
  };

  const handleDeleteTodo = () => {
    setTodos((prev) => prev.filter((todo) => todo !== item));
  };

  return (
    <div className="flex items-center w-full justify-between mb-5">
      <div className="flex items-center">
        <input
          className="mr-2"
          type="checkbox"
          checked={item.completed}
          onChange={handleToggleTodo}
        />
        <div className={clsx(item.completed ? "line-through" : "")}>
          {item.title}
        </div>
      </div>
      <div className="text-red-400 cursor-pointer" onClick={handleDeleteTodo}>
        Delete
      </div>
    </div>
  );
};

export default TodoItem;
