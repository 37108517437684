import { FC, FormEvent, Fragment, useState } from "react";
import { Lead, Picture } from "../../../types";
import {
  Button,
  LoadingView,
  NavigationBar,
  QuoteSteps,
  SvgIcon,
} from "../../../components";
import _ from "lodash";
import { format } from "date-fns";
import { FaStar, FaRegStar } from "react-icons/fa";
import { abbrNumber } from "../../../utils";
import { BsChatLeftText } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { CustomerApi } from "../../../apis/customer.api";
import { getPlatform } from "../../../utils/getPlatform";
import GeneralModal from "../../../components/molecules/GeneralModal";
import { useAtomValue } from "jotai";
import { hideBottomBarAtom } from "../../../jotai";

type ApproveServiceProps = {
  leadItem: Lead;
  pictures: Picture[];
};

export const ApproveService: FC<ApproveServiceProps> = ({ leadItem }) => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [rating, setRating] = useState(-1);
  const hideBottomBar = useAtomValue(hideBottomBarAtom);

  const currentWorkflowStepName = _.get(
    leadItem,
    "currentWorkflowStepName",
    null
  );

  const coverPictureUrl = _.get(
    leadItem,
    "coverPictureUrl",
    "/images/rouis_bag.png"
  );
  const historySteps = _.get(leadItem, "historySteps", []);
  const amountTotal = _.get(leadItem, "activeSalesOrder.amountTotal", null);

  const handleSubmitReview = async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      setLoading(true);

      await CustomerApi.postReview(leadItem.itemId, leadItem.id, {
        title,
        text,
        rating,
      });

      navigate("/profile/closet/history", { replace: true });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <NavigationBar
        headingText={`Quote #${_.get(leadItem, "displayName", null)}`}
      />

      <div className="flex flex-col p-4 min-h-[90vh]">
        {showReview ? (
          <Fragment>
            <div className="text-gray-750 text-2xs font-medium uppercase mt-2">
              Leave review
            </div>

            <div className="bg-white overflow-hidden shadow rounded-lg mb-15 p-4">
              <div className="text-xs font-semibold capitalize text-textPrimary mb-5 opacity-50">
                Tell us how your artisan did ?
              </div>

              <div className="flex items-center -mx-1">
                {Array.from(Array(5).keys()).map((idx) => (
                  <button
                    className=""
                    key={idx}
                    onClick={() => setRating(idx + 1)}
                  >
                    {idx < rating ? (
                      <FaStar size={24} className="text-primary px-1" />
                    ) : (
                      <FaRegStar size={24} className="text-primary px-1" />
                    )}
                  </button>
                ))}
              </div>
            </div>

            <div className="text-base text-textPrimary capitalize font-semibold mb-3">
              What should others know about this artisan?
            </div>

            <form
              className="flex flex-col w-full flex-1"
              onSubmit={handleSubmitReview}
            >
              <input
                placeholder="Title of Review"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="border border-borderPrimary outline-none placeholder:text-grayText w-full px-3 py-3 mb-3 text-xs rounded-lg bg-white"
              />

              <textarea
                placeholder="Tell us what other should know..."
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="h- border border-borderPrimary outline-none placeholder:text-grayText w-full h-37 px-3 py-3 text-xs rounded-lg bg-white"
              />

              <div className="text-xs font-medium text-grayText text-center mt-auto mb-6">
                Thank you for leaving a review. Your feedback helps us ensure
                that our marketplace collaborates exclusively with the highest
                quality artisans.
              </div>

              <Button
                contained
                className="!text-brandTag !font-medium"
                type="submit"
              >
                Submit
              </Button>
            </form>
          </Fragment>
        ) : (
          <Fragment>
            <div className="flex gap-3.5 bg-white p-5 rounded-xl">
              <div className="flex justify-center items-center min-w-9.5 h-9.5 rounded-primary bg-helperText">
                <SvgIcon name="truck" className="text-white" />
              </div>

              <div className="relative">
                <p className="font-semibold">
                  {_.get(leadItem, "brand", null)}&nbsp;-&nbsp;
                  {_.get(leadItem, "categoryName", null)}
                </p>
                <p className="text-xs font-semibold text-gray-800">
                  {format(
                    new Date(_.get(leadItem, "createDate", undefined)),
                    "d MMM, y"
                  )}
                </p>
                <div className="absolute top-0 right-0 bg-notification px-2 py-1 text-xs font-semibold text-primary rounded-small">
                  {currentWorkflowStepName}
                </div>
                <div className="border-b border-pinBorder my-5" />
                <div className="grid grid-cols-2 gap-2">
                  {["before", "after"].map((badgeText) => (
                    <div className="relative border rounded-lg" key={badgeText}>
                      <div className="absolute z-10 top-2 left-2 px-1.5 py-0.5 text-2xs text-white font-medium bg-grayText rounded capitalize">
                        {badgeText}
                      </div>

                      <img
                        src={coverPictureUrl}
                        alt="before"
                        className="rounded-lg w-30 h-30 object-contain"
                        style={
                          badgeText === "before"
                            ? {
                                filter: "grayscale(1)",
                              }
                            : {}
                        }
                      />
                    </div>
                  ))}
                </div>

                <div className="text-xs text-helperText text-right whitespace-nowrap my-2">
                  COMING SOON
                </div>

                <div className="relative flex justify-between items-center gap-2.5">
                  <Button
                    contained
                    className="!text-sm !px-4"
                    onClick={() => setShowReview(true)}
                  >
                    Leave a Review
                  </Button>

                  <Button className="relative !pl-10 !pr-4">
                    <SvgIcon
                      name="instagram"
                      className="absolute top-1/2 left-3.75 -translate-y-1/2 w-4.5 h-4.5"
                    />
                    Share
                  </Button>
                </div>
              </div>
            </div>

            <div className="text-gray-750 text-2xs font-medium uppercase mt-2">
              Artisan Info:
            </div>

            <div className="bg-white overflow-hidden shadow rounded-lg mb-6">
              <div className="p-4 flex items-center justify-between">
                <div>
                  <div className="text-sm font-bold mb-3">
                    <div className="text-textPrimary">
                      {_.get(leadItem, "artisanName", null)}
                    </div>
                  </div>

                  <div className="flex items-center gap-1.5 text-m-xs">
                    <FaStar className="text-primary" size={16} />

                    <div className="text-[11px] font-semibold text-textPrimary opacity-60">
                      {abbrNumber(_.get(leadItem, "artisanJobsCount", 0))}{" "}
                      resotrations completed
                    </div>
                  </div>
                </div>

                <button
                  className="inline-flex items-center text-xs text-textPrimary font-semibold py-2 px-3 bg-pinBorder rounded-md outline-none"
                  onClick={() => setShowDownloadModal(true)}
                >
                  <BsChatLeftText size={12} className="text-textPrimary mr-2" />
                  Chat
                </button>
              </div>
            </div>

            <div className="text-gray-750 text-2xs font-medium uppercase mt-2">
              Order Status:
            </div>

            <div className="bg-white overflow-hidden shadow rounded-lg mb-9">
              <div className="p-4">
                <QuoteSteps steps={historySteps} />
              </div>
            </div>

            {!!amountTotal && (
              <div className="flex justify-between items-center -mx-4 p-4 bg-white shadow-footer">
                <div>
                  <p className="text-xs opacity-50 mb-1">Total cost:</p>
                  <p className="text-2xl font-extrabold">
                    ${amountTotal.toFixed(2)}
                  </p>
                </div>
                <Button
                  contained
                  className="!text-brandTag !font-medium"
                  onClick={() => navigate("/", { replace: true })}
                >
                  Request a new quote
                </Button>
              </div>
            )}
          </Fragment>
        )}
      </div>

      {showDownloadModal && (
        <GeneralModal
          helperText={
            hideBottomBar
              ? "Please click the Inbox icon on the bottom of your screen to start the chat"
              : "To Chat Directly with Your Artisan"
          }
          onClose={() => setShowDownloadModal(false)}
          {...(!hideBottomBar && {
            mainText: "Download the craftyz App",
            actionButtons: [
              <Button
                fullWidth
                contained
                onClick={() =>
                  window.open(
                    `https://deeplink.thecobblers.com/downloadcustomerapp?platform=${getPlatform()}`,
                    "_blank"
                  )
                }
              >
                Download
              </Button>,
            ],
          })}
        />
      )}

      <LoadingView show={isLoading} />
    </Fragment>
  );
};
