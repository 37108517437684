import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import { ArrowDown2, Box1 } from "iconsax-react";
import { FC, Fragment } from "react";
import { useAtom, useAtomValue } from "jotai";
import { selectedBrandAtom, selectedMetadataAtom } from "../../jotai";

const BrandMenu: FC = () => {
  const selectedMetadata = useAtomValue(selectedMetadataAtom);
  const [selectedBrand, setSelectedBrand] = useAtom(selectedBrandAtom);

  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <Menu.Button className="inline-flex w-full rounded-md border border-phoneBorder text-sm divide-x py-2.5">
        <div className="px-4 py-2.5">
          <Box1 size={20} className="text-blue" />
        </div>
        <div
          className={clsx(
            "flex-1 flex items-center justify-between pl-4 py-2.5 pr-3",
            selectedBrand?.name ? "text-blue font-semibold" : "text-silver-2"
          )}
        >
          {selectedBrand?.name ? selectedBrand.name : "Select One"}

          <ArrowDown2 size={20} className="text-blue" />
        </div>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 w-full mt-2 rounded-md shadow-lg bg-white px-4 divide-y max-h-[240px] overflow-y-auto">
          {selectedMetadata?.brands?.map((brand) => (
            <Menu.Item>
              {({ active }) => (
                <button
                  key={brand.id}
                  className={clsx(
                    "w-full text-start py-4 text-sm font-semibold",
                    selectedBrand?.id === brand.id ? "text-rose" : "text-blue"
                  )}
                  onClick={() => setSelectedBrand(brand)}
                >
                  {brand.name}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default BrandMenu;
