import React, { useState } from "react";
import { Button } from "../atoms";
import { IoIosArrowDown } from "react-icons/io";
import { Picture } from "../../types";
import { ILeadItem, ISalesOrder } from "./interfaces/LeadItem";
import { SetStateAction } from "jotai";

interface IQuoteContent {
  leadItem: ILeadItem;
  selectedSalesOrder: ISalesOrder;
  pictures?: Picture[];
  setActiveQuoteState?: React.Dispatch<SetStateAction<number>>;
}

const QuoteContent: React.FC<IQuoteContent> = ({
  leadItem,
  selectedSalesOrder,
  pictures,
  setActiveQuoteState,
}) => {
  const [productState, setPrductState] = useState<any>();
  const [showProductDetails, setShowProductDetails] = useState<boolean>(false);
  const [isProductImage, setIsProductImage] = useState<boolean>(false);
  const [showArtisanNoteArray, setShowArtisanNoteArray] = useState(
    Array(
      selectedSalesOrder?.orderLines && selectedSalesOrder?.orderLines.length
    ).fill(false)
  );

  const toggleArtisanNote = (index: number) => {
    const newShowArtisanNoteArray = [...showArtisanNoteArray];
    newShowArtisanNoteArray[index] = !newShowArtisanNoteArray[index];
    setShowArtisanNoteArray(newShowArtisanNoteArray);
  };

  const formatDate = (inputDate: string) => {
    const dateObject = new Date(inputDate);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();

    const formattedDate =
      day + "/" + (month < 10 ? "0" : "") + month + "/" + year;

    return formattedDate;
  };

  const showProductInfo = (order: any) => {
    setPrductState(order);
    setShowProductDetails(true);
  };
  const hasEstimatedCompletionDays =
    selectedSalesOrder.estimatedCompletionDays &&
    selectedSalesOrder.estimatedCompletionDays > 0;
  return (
    <>
      <div>
        <div className="mt-5 rounded-md bg-white mb-4 p-3 h-auto">
          <div className="h-auto mb-5">
            <div className="flex flex-col space-y-6">
              {selectedSalesOrder?.orderLines &&
                selectedSalesOrder?.orderLines.map((order: any, i: number) => (
                  <div
                    key={i}
                    className="bg-gray-100 p-2 flex items-center justify-between cursor-pointer"
                  >
                    <div className="flex gap-1 items-center">
                      <img
                        onClick={() => showProductInfo(order)}
                        src={
                          pictures?.find(
                            (picture) => picture.id === order?.pictureId
                          )?.url
                        }
                        alt="img"
                        className="w-15 rounded-lg border border-brightPrimary"
                      />
                      <div className="flex flex-col justify-center flex-1 mx-1.5 text-quoteStatus">
                        <p className="text-blue font-semibold text-xs">{`${order?.name} x ${order?.quantity}`}</p>
                        <p className="text-gray-750 text-m-xs">{}</p>
                      </div>
                    </div>
                    <div className="relative text-blue font-semibold text-quoteStatus ">
                      <p>${order?.unitPrice * order?.quantity}</p>
                      <div
                        onClick={() => toggleArtisanNote(i)}
                        className="flex items-center gap-0.5 mt-2 text-blue tetx-2xs"
                      >
                        <p>Artisans Notes</p> <IoIosArrowDown />
                      </div>
                      {showArtisanNoteArray[i] && (
                        <div className="absolute right-0 mt-1  bg-white rounded-md shadow-md w-30 h-auto p-2 text-blue z-50">
                          <p>{order?.artisanNotes}</p>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {hasEstimatedCompletionDays && (
            <div className="text-brightPrimary text-sm flex items-center justify-between py-3 px-3">
              <p>
                After receiving your item {leadItem.artisanName} expects to
                finish the services for {selectedSalesOrder.categoryName} within{" "}
                {selectedSalesOrder.estimatedCompletionDays} days
              </p>
            </div>
          )}
        </div>

        {/*Product Pop up */}

        {showProductDetails && (
          <div className="fixed top-0 left-0 bottom-0 right-0 bg-backdrop flex flex-col justify-end z-[100]">
            <div className="w-full h-[90vh] md:h-auto  bg-white rounded-t-large">
              <div
                className="py-4 px-4 text-right text-4xl font-normal text-backdrop cursor-pointer"
                onClick={() =>
                  showProductDetails && setShowProductDetails(false)
                }
              >
                &times;
              </div>
              <div className="flex flex-col px-10 pt-3 pb-6">
                <div className="bg-gray-100 py-2 px-3 h-auto rounded-md flex items-center justify-between font-semibold text-sm text-blue">
                  <div className="flex items-center">
                    <img
                      src={
                        productState &&
                        pictures?.find(
                          (picture) => picture.id === productState?.pictureId
                        )?.url
                      }
                      alt="img"
                      className="w-14 h-12 rounded-lg border border-brightPrimary"
                    />
                    <div className="flex flex-col justify-center ml-2 space-y-1">
                      <p className="">
                        {`${productState && productState?.name} x ${
                          productState && productState?.quantity
                        }`}
                      </p>
                      <p className="font-normal text-xs text-gray-750">{}</p>
                    </div>
                  </div>
                  <p>${productState && productState.unitPrice}</p>
                </div>

                <div
                  onClick={() => setIsProductImage(true)}
                  className="my-6 cursor-pointer"
                >
                  <img
                    src={
                      productState &&
                      pictures?.find(
                        (picture) => picture.id === productState?.pictureId
                      )?.url
                    }
                    alt="img"
                    className="w-full h-70 rounded-xl border-2 border-orange-600"
                  />
                </div>
                <div className="mb-8 bg-gray-100 pl-5 pr-3  py-4 h-auto rounded-md flex flex-col space-y-1.5 justify-center font-semibold text-sm text-blue">
                  <p className="text-gray-750 font-semibold">Artisian Notes</p>
                  <p className="font-medium">
                    {productState && productState?.artisanNotes}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/*Show Full Photo */}
        {isProductImage && (
          <div className="fixed top-0 left-0 bottom-0 right-0 bg-backdrop flex flex-col justify-end z-[1000]">
            <div className="w-full h-screen  bg-black">
              <div
                onClick={() => setIsProductImage && setIsProductImage(false)}
                className="flex flex-row-reverse m-4 mb-18 cursor-pointer"
              >
                <div className="text-right text-4xl font-normal inline text-gray-750">
                  &times;
                </div>
              </div>
              <img
                className="w-full h-[70%] border-y-2 border-y-brightPrimary mb-18"
                src={
                  productState &&
                  pictures?.find(
                    (picture) => picture.id === productState?.pictureId
                  )?.url
                }
                alt="img"
              />
            </div>
          </div>
        )}

        {/*Quote Cost */}
        <div className="pb-24">
          <p className="capitalize my-3 text-gray-700 font-medium text-2xs">
            Quote Cost
          </p>
          <div className="bg-white rounded-lg h-auto p-3 text-blue">
            <div className="flex flex-col pb-5 space-y-2 text-sm">
              <div className="flex justify-between">
                <p>Subtotal</p>
                <p className="font-semibold">
                  ${selectedSalesOrder?.amountUntaxed}
                </p>
              </div>
              <div className="flex justify-between">
                <p>Insured Shopping</p>
                <p className="font-semibold">{}</p>
              </div>
              <div className="flex justify-between">
                <p>Estimated Taxes + Fees</p>
                <p className="font-semibold">
                  ${selectedSalesOrder?.amountTax}
                </p>
              </div>
            </div>
            <hr className="border-b-borderPrimary pb-5" />
            <div className="flex justify-between text-lg font-semibold text-brightPrimary">
              <p className=" capitalize">Total</p>
              <p className="font-semibold capitalize">
                ${selectedSalesOrder?.amountTotal}
              </p>
            </div>
          </div>
        </div>

        <div className="fixed left-0 right-0 bottom-0 z-10 p-4 bg-white shadow flex items-center justify-between">
          <div className="flex flex-col gap-1">
            <div className="text-textPrimary opacity-50 text-xs font-medium">
              Total cost:
            </div>
            <div className="text-xl text-blue font-extrabold">
              ${selectedSalesOrder?.amountTotal}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <Button
              onClick={() => {
                setActiveQuoteState && setActiveQuoteState(3);
              }}
              contained
              className="w-54.5 text-sm"
            >
              Select {selectedSalesOrder.categoryName}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuoteContent;
