import { useAtom } from "jotai";
import { todosAtom } from "../../jotai/todo.jotai";
import { Todo } from "../../types";
import { useState } from "react";
import TodoItem from "./components/TodoItem";
import { useNavigate } from "react-router-dom";
import Button from "../../components/atoms/Button";

const ToDoPage = () => {
  const [todos, setTodos] = useAtom(todosAtom);
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  return (
    <div className="w-full h-full p-20 flex flex-col">
      <h1 className="text-4xl font-bold text-center">To Do Page</h1>
      <div className="max-w-[350px] flex-1 overflow-auto">
        <h2 className="text-2xl text-left mt-5">List of to-dos</h2>
        <div className="my-5">
          {todos.length > 0 ? (
            todos.map((todo, index) => {
              return <TodoItem key={index} item={todo as Todo} />;
            })
          ) : (
            <div className="italic">No to-do added yet!</div>
          )}
        </div>
        <div className="flex items-center">
          <input
            className="px-2 py-1 border border-black rounded-lg mr-2"
            placeholder="Type ..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <Button
            onClick={() => {
              setTodos((prev) => [
                ...prev,
                { title: inputValue, completed: false },
              ]);
              setInputValue("");
            }}
          >
            Add
          </Button>
        </div>
      </div>
      <div
        className="text-[20px] text-blue-500 hover:text-blue-700 cursor-pointer"
        onClick={() => {
          navigate("/");
        }}
      >
        Back to HomePage
      </div>
    </div>
  );
};

export default ToDoPage;
