import clsx from "clsx";
import NavButton from "../../molecules/NavButton";
import { useAtom } from "jotai";
import { navbarAtom, hideBottomBarAtom } from "../../../jotai/navbar.jotai";
import { SvgIcon } from "../../atoms/SvgIcon";
import { useLocation, useNavigate } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { getPlatform } from "../../../utils/getPlatform";
import GeneralModal from "../../molecules/GeneralModal";
import { Button } from "../../atoms";

export type NavButtonType = {
  icon: React.ReactNode;
  activeIcon: React.ReactNode;
  label: string;
  path: string;
};

const navButtons = [
  {
    icon: <SvgIcon name="home" className="text-grayText" />,
    activeIcon: <SvgIcon name="home-contained" className="text-primary" />,
    label: "Home",
    path: "/",
  },
  {
    icon: <SvgIcon name="search" className="text-grayText" />,
    activeIcon: <SvgIcon name="search" className="text-primary" />,
    label: "Explore",
    path: "https://www.craftyz.com/repair ",
  },
  {
    icon: <SvgIcon name="orders" className="text-grayText" />,
    activeIcon: <SvgIcon name="orders" className="text-primary" />,
    label: "Orders",
    path: "/profile/closet/history",
  },
  {
    icon: <SvgIcon name="inbox" className="text-grayText" />,
    activeIcon: <SvgIcon name="inbox" className="text-primary" />,
    label: "Inbox",
    path: "https://www.craftyz.com/about",
  },
  {
    icon: <SvgIcon name="user" className="text-grayText" />,
    activeIcon: <SvgIcon name="user-contained" className="text-primary" />,
    label: "Profile",
    path: "/profile",
  },
];

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [{ selectedButton, ...others }, setNavbarAtom] = useAtom(navbarAtom);
  const [hideBottomBar, setHideBottomBar] = useAtom(hideBottomBarAtom);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  useEffect(() => {
    setHideBottomBar(window.localStorage.getItem("hide_bottombar") === "true");
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const hide_bottombar = searchParams.get("hide_bottombar");
    if (hide_bottombar !== null) {
      const shouldHide = ["1", "true", "yes"].includes(hide_bottombar);
      setHideBottomBar(shouldHide);
      localStorage.setItem("hide_bottombar", shouldHide.toString()); // Convert boolean to string
    }
  }, [window.location.search]);

  useEffect(() => {
    const navBtnForCurrentPage = navButtons.find(
      (nav) => nav.path === location.pathname
    );
    if (navBtnForCurrentPage) {
      setNavbarAtom({
        ...others,
        selectedButton: navBtnForCurrentPage.label,
      });
    }
  }, [location]);

  const handleClick = (navBtn: NavButtonType) => {
    if (["Orders", "Inbox", "Profile"].includes(navBtn.label)) {
      setShowDownloadModal(true);

      return;
    }

    if (navBtn.path.startsWith("/")) {
      setNavbarAtom({
        ...others,
        selectedButton: navBtn.label,
      });
      navigate(navBtn.path);
    } else {
      window.open(navBtn.path, "_self");
    }
  };

  if (hideBottomBar) return null;

  return (
    <Fragment>
      <div
        className={clsx(
          "fixed left-0 bottom-0 w-full bg-white flex justify-center items-center"
        )}
      >
        {navButtons.map((navBtn, index) => (
          <NavButton
            key={index}
            icon={navBtn.icon}
            activeIcon={navBtn.activeIcon}
            label={navBtn.label}
            active={selectedButton === navBtn.label}
            onClick={() => handleClick(navBtn)}
          />
        ))}
      </div>

      {showDownloadModal && (
        <GeneralModal
          mainText="Download the craftyz App"
          helperText="To Chat Directly with Your Artisan"
          onClose={() => setShowDownloadModal(false)}
          actionButtons={[
            <Button
              fullWidth
              contained
              onClick={() =>
                window.open(
                  `https://deeplink.thecobblers.com/downloadcustomerapp?platform=${getPlatform()}`,
                  "_blank"
                )
              }
            >
              Download
            </Button>,
          ]}
        />
      )}
    </Fragment>
  );
};

export default Navbar;
