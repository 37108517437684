import * as Yup from "yup";
import { validatePhoneNumber } from "../utils";

const personalInfoSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  email: Yup.string().email("Invalid Email").required("Name is required."),
  mobile: Yup.string()
    .required("Phone number is required")
    .test("is-valid-phone", "Invalid Phone Number. Check Country Code", (value) => {
      const result = validatePhoneNumber(value);
      return result;
    }),
  zip: Yup.string().required("Zip code is required"),
});

export default personalInfoSchema;
