import { FC, useEffect, useState } from "react";
import BrandMenu from "../BrandMenu";
import {
  funnelDisabledAtom,
  newProductDetailsAtom,
  selectedBrandAtom,
} from "../../../jotai";
import { useAtom, useAtomValue } from "jotai";
import { Gallery } from "../../molecules/Gallery";
import { Perspective } from "../../../types/metadata";
const QuoteFunnelBrand: FC = () => {
  const selectedBrand = useAtomValue(selectedBrandAtom);
  const [productDetails, setProductDetails] = useAtom(newProductDetailsAtom);
  const [_, setFunnelDisabled] = useAtom(funnelDisabledAtom);
  const [currentPerspectiveIndex, setCurrentPerspectiveIndex] =
    useState<number>(0);

  const pictures = productDetails?.pictures || [];

  useEffect(() => {
    window.location.hash = "submit-quote";
  }, []);
  useEffect(() => {
    if (!selectedBrand?.products[0]?.id || !productDetails?.pictures.length)
      setFunnelDisabled(true);
    else setFunnelDisabled(false);
  }, [selectedBrand, productDetails]);

  const getImageUrl = (perspectiveId: number) => {
    return pictures.find(({ perspectiveId: _pId }) => _pId === perspectiveId)
      ?.url;
  };

  const onChangePerspective = (perspective: Perspective, url: string) => {
    setCurrentPerspectiveIndex((prev) => prev + 1);

    const updatedPictures = pictures.map((item) =>
      item.perspectiveId === perspective.id
        ? { url, perspectiveId: perspective.id }
        : item
    );

    const isExist = updatedPictures.find(
      (item) => item.perspectiveId === perspective.id
    );

    if (!isExist) {
      updatedPictures.push({ url, perspectiveId: perspective.id });
    }

    setProductDetails({
      ...productDetails,
      pictures: updatedPictures,
      productId: selectedBrand?.products[0]?.id || 0,
    });
  };

  const handleRemoveImage = (perspectiveId: number) => {
    setProductDetails({
      ...productDetails,
      pictures: pictures.filter(
        ({ perspectiveId: _pId }) => _pId !== perspectiveId
      ),
    });
  };

  return (
    <div className="">
      <BrandMenu />

      <div className="mt-6">
        <div className="flex items-end font-semibold mb-3">
          <div className="text-blue">Upload Images&nbsp;</div>
          <div className="text-xs text-rose">&nbsp;(At least one)</div>
        </div>
        <div className="grid grid-cols-3 gap-4 w-full">
          {selectedBrand?.products[0]?.picturePerspectives?.map(
            (perspective, index) => {
              // show next picturePerspectives after uploading previous one
              if (index <= currentPerspectiveIndex)
                return (
                  <Gallery
                    key={index}
                    image={getImageUrl(perspective.id)}
                    title={perspective.name}
                    description={perspective.description}
                    onChange={(url) => onChangePerspective(perspective, url)}
                    onRemove={() => handleRemoveImage(perspective.id)}
                  />
                );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default QuoteFunnelBrand;
