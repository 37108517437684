import api from "./axios.config";
import {
  Address,
  PersonalInformationRequest,
  ProductDetailRequest,
} from "../types";

export class CustomerApi {
  static async getMe() {
    return api.get(`/customer/Me`).then((res) => res.data);
  }

  static async updateMe(data: any) {
    return api.post(`/customer/Me`, data).then((res) => res.data);
  }

  static async getCustomerItems() {
    return api.get(`/customer/Item`).then((res) => res.data);
  }

  static async getCustomerItemDetail(id: string, leadId: string) {
    return api
      .get(`/customer/Item/${id}/leads/${leadId}`)
      .then((res) => res.data);
  }

  static async getCustomerItemPictures(
    id: string,
    leadId: string,
    params: any = { filterType: "lead" }
  ) {
    return api
      .get(`/customer/Item/${id}/leads/${leadId}/pictures`, { params })
      .then((res) => res.data);
  }

  static async startOTP(data: any) {
    return api
      .post(`/customer/Authentication/otp/start`, data)
      .then((res) => res.data);
  }

  static async completeOTP(data: any) {
    return api
      .post(`/customer/Authentication/otp/complete`, data)
      .then((res) => res.data);
  }

  static async addPaymentMethod(data: any) {
    return api
      .post(`/customer/Me/paymentmethods`, data)
      .then((res) => res.data);
  }

  static async getPaymentMethods() {
    return api.get(`/customer/Me/paymentmethods`).then((res) => res.data);
  }

  static async getUserGuid(payload = {}) {
    return api
      .post(`/customer/User/anonymous`, payload)
      .then((res) => res.data);
  }

  static async getLeadId(userGuid: string, utmPayload: any = {}) {
    return api
      .post(`/customer/LeadSubmission/start`, { userGuid, ...utmPayload })
      .then((res) => res.data);
  }

  static async getUploadSasToken(leadId: string) {
    return api
      .get(`/customer/LeadSubmission/${leadId}/upload-sas`)
      .then((res) => res.data);
  }

  static async updateProductDetails(
    leadId: string,
    data: ProductDetailRequest
  ) {
    return api
      .put(`/customer/LeadSubmission/${leadId}/complete`, data)
      .then((res) => res.data);
  }

  static async updatePersonalInformation(
    guid: string,
    data: PersonalInformationRequest
  ) {
    return api
      .put(`/customer/User/${guid}/amend`, data)
      .then((res) => res.data);
  }

  static async getAddresses() {
    const res = await api.get(`/customer/Me/addresses`);

    return res.data;
  }

  static async postAddresses(address: Address) {
    const res = await api.post(`/customer/Me/addresses`, address);

    return res.data;
  }

  static async putAcceptSaleOrderItem(
    itemId: number | string,
    leadId: number | string,
    activeSaleOrderId: number | string,
    data: any
  ) {
    const res = await api.put(
      `/customer/Item/${itemId}/leads/${leadId}/sales/${activeSaleOrderId}/accept`,
      data
    );

    return res.data;
  }

  static async putApproveQC(
    itemId: number | string,
    leadId: number | string,
    activeSaleOrderId: number | string,
    data: any
  ) {
    const res = await api.put(
      `/customer/Item/${itemId}/leads/${leadId}/sales/${activeSaleOrderId}/approve-qc`,
      data
    );

    return res.data;
  }

  static async postReview(
    itemId: number | string,
    leadId: number | string,
    data: any
  ) {
    const res = await api.post(
      `/customer/Item/${itemId}/leads/${leadId}/review`,
      data
    );

    return res.data;
  }

  static async putDelete() {
    const res = await api.put("/customer/Me/delete");

    return res.data;
  }

  static async getDeclineReasons(
    itemId: number | string,
    leadId: number | string,
    saleOrderId: number
  ) {
    const res = await api.get(
      `/customer/Item/${itemId}/leads/${leadId}/sales/${saleOrderId}/declinereasons`
    );

    return res.data;
  }

  static async putDecline(
    itemId: number | string,
    leadId: number | string,
    saleOrderId: number,
    data: any
  ) {
    const res = await api.put(
      `/customer/Item/${itemId}/leads/${leadId}/sales/${saleOrderId}/decline`,
      data
    );

    return res.data;
  }
}
