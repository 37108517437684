import { Button, CraftyzLogo } from "../components";
import { FaCheck } from "react-icons/fa6";
import {
  imagesAtom,
  newProductDetailsAtom,
  selectedBrandAtom,
  selectedMetadataAtom,
} from "../jotai";
import { useAtomValue } from "jotai";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import GeneralModal from "../components/molecules/GeneralModal";
import { useState } from "react";
import { getPlatform } from "../utils/getPlatform";
import { CustomerApi } from "../apis/customer.api";
import { getUTMPayload } from "../utils";
import { useCustomers } from "../contexts";

export default () => {
  const navigate = useNavigate();
  const images = useAtomValue(imagesAtom);
  const selectedMeta = useAtomValue(selectedMetadataAtom);
  const selectedBrand = useAtomValue(selectedBrandAtom);
  const productDetails = useAtomValue(newProductDetailsAtom);
  const { state } = useLocation();
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const { setUserId, setLeadId, leadName, setLeadName } = useCustomers();

  const getCuttedLeadNumber = (leadNumber: string) => {
    if (leadNumber.length > 8) {
      return leadNumber.slice(0, 8) + "...";
    }
    return leadNumber;
  };

  const steps = [
    {
      title: "Step 1",
      description: `Your quote #${getCuttedLeadNumber(
        leadName || ""
      )} is in progress, we will send you an update when you have been matched with an artisan and your quote is ready.`,
    },
    {
      title: "Step 2",
      description:
        "To review your quote and get in touch with your artisan, you will need to download our app.",
    },
    {
      title: "Step 3",
      description: "Once downloaded, all future requests can be made directly through the app.",
    },
  ];

  const handleClickDownload = async () => {
    try {
      localStorage.removeItem("cobbler_user_id");
      localStorage.removeItem("cobbler_lead_id");

      const user = await CustomerApi.getUserGuid(getUTMPayload());
      const lead = await CustomerApi.getLeadId(user.guid, getUTMPayload());

      localStorage.setItem("cobbler_user_id", user.guid);
      localStorage.setItem("cobbler_lead_id", lead.guid);
      localStorage.setItem("cobbler_lead_name", lead.name);

      setLeadId(lead.guid);
      setLeadName(lead.name);
      setUserId(user.guid);

      setShowDownloadModal(true);
    } catch (error) {}
  };
  if (!state?.name || !productDetails || !selectedMeta || !selectedBrand)
    return <Navigate to="/" replace />;

  return (
    <div className="relative flex flex-col w-screen min-h-screen">
      <div className="bg-loginBg px-6 py-5">
        <CraftyzLogo className="mb-10" />

        <div className="w-10 h-10 bg-blue rounded-full flex items-center justify-center mb-3">
          <FaCheck size={20} color="#FFF" />
        </div>

        <div className="text-blue font-medium capitalize text-brandTag mb-3">Hi {state?.name},</div>
        <div className="text-[28px] font-semibold capitalize text-blue">
          Thank you for requesting your quote.
        </div>
      </div>

      <div className="flex-1 bg-homeBg px-6 py-5">
        <div className="mb-3">
          <div className="flex justify-between items-center text-xs mb-2">
            <p className="font-semibold opacity-50">Product info:</p>
            <p className="text-primary font-semibold italic">Quote Requested</p>
          </div>
          <div className="flex p-3 rounded bg-white gap-3">
            <div className="w-16 h-20">
              <img src={images[0]} alt="quote" className="w-full h-20" width="100%" height="100%" />
            </div>
            <div className="flex-1 flex flex-col justify-between text-blue">
              <p className="text-sm font-bold mb-3">
                {selectedMeta?.category.name}&nbsp;/&nbsp;{selectedBrand?.name}
              </p>
              <div>
                <p className="text-[11px] font-semibold opacity-60">Description</p>
                <p className="text-[13px] font-semibold">{productDetails?.note || ""}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="text-lg text-blue font-extrabold mb-2">What’s Next?</div>

        <div className="bg-white rounded-xl p-5">
          {steps.map((step, index) => (
            <div key={index} className="flex gap-4">
              <div className="flex flex-col items-center gap-1">
                <div className="w-4 h-4 rounded-full bg-primary" />
                {steps.length > index + 1 && (
                  <div className="flex-1 flex justify-between pb-1">
                    <div className="h-full border-l border-rose" />
                  </div>
                )}
              </div>
              <div className="pb-1">
                <p className="text-blue text-sm font-semibold mb-1">{step.title}</p>
                <p className="text-xs text-gray-800 font-semibold">{step.description}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="h-[85px]" />
      </div>

      <div className="fixed left-0 bottom-0 w-full z-10 p-5 bg-white shadow-footer">
        <Button fullWidth contained onClick={handleClickDownload} className="!text-[15px]">
          Download the App
        </Button>
      </div>

      {showDownloadModal && (
        <GeneralModal
          mainText="Download the craftyz App"
          helperText="To Chat Directly with Your Artisan"
          onClose={() => setShowDownloadModal(false)}
          actionButtons={[
            <Button
              fullWidth
              contained
              onClick={() => {
                window.open(
                  `https://deeplink.thecobblers.com/downloadcustomerapp?platform=${getPlatform()}`,
                  "_blank"
                );

                navigate("/otp-login");
              }}
            >
              Download
            </Button>,
          ]}
        />
      )}
    </div>
  );
};
