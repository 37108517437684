import { Provider } from "jotai";
import RootRouter from "./routes";
import { CustomerProvider } from "./contexts/customerContext";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { initializeTagManager } from "./utils/googleTagManagerInit";

const App = () => {
  useEffect(() => {
    initializeTagManager();
  }, []);
  return (
    <Provider>
      <Router>
        <CustomerProvider>
          <RootRouter />

          <ToastContainer />
        </CustomerProvider>
      </Router>
    </Provider>
  );
};

export default App;
