import Stepper from "../../../components/atoms/Stepper";
import Button from "../../../components/atoms/Button";
import React from "react";
import { useAtom } from "jotai";
import { stepAtom } from "../../../jotai/quote.jotai";

type StepFooterProps = {
  disabledNext?: boolean;
  onNext?: () => void;
};

export const StepFooter = ({
  onNext,
  disabledNext = false,
}: StepFooterProps) => {
  const [step, setStep] = useAtom(stepAtom);

  const onBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  return (
    <div className="fixed left-0 bottom-0 w-full z-10 p-5 bg-white shadow-footer">
      <div className="flex justify-center">
        <Stepper stepsCount={3} activeStep={step} />
      </div>
      <div className="mt-5 flex justify-between items-center gap-2.5">
        <Button onClick={onBack} className="!px-9">
          Back
        </Button>
        <Button
          contained
          fullWidth
          onClick={onNext}
          type="submit"
          disabled={disabledNext}
        >
          {step < 3 ? "Continue" : "Submit your Quote"}
        </Button>
      </div>
    </div>
  );
};
