import React from 'react';
import { SvgIcon } from '../../components/atoms/SvgIcon';
import Button from '../../components/atoms/Button';
import { useNavigate } from 'react-router-dom';

const steps = [
  {
    title: 'Step 1',
    description: 'Securely package your item. Do not include dust bags, original boxes or any additional items.'
  },
  {
    title: 'Step 2',
    description: 'Ship it or Bring it to the UPS Store'
  },
];

const QuoteDetailPrintPage = () => {
  const navigate = useNavigate();

  const handlePrev = () => {
    navigate(-1);
  }

  return (
    <div className="min-h-screen bg-homeBg px-4 py-10">
      <div className="flex justify-between items-center pb-4">
        <SvgIcon name="chevron-left" onClick={handlePrev} />
        <p className="text-lg font-bold capitalize">Drop off details</p>
        <div />
      </div>
      <div className="mt-5">
        <h2 className="text-lg font-extrabold capitalize mb-2.5">Your Address</h2>
        <div className="flex justify-between items-center bg-white rounded-xl p-2.5">
          <div className="flex gap-3">
            <div className="w-10.5 h-10.5 flex justify-center items-center bg-mediumGray rounded-primary">
              <SvgIcon name="location-contained" className="text-primary" />
            </div>
            <div>
              <p className="text-sm font-semibold mb-0.5">831 Wesley Chapel Dr</p>
              <p className="text-xs font-semibold text-gray-800">Durham, NC 27705</p>
            </div>
          </div>
          <p className="text-xs text-primary font-bold mr-2">Change</p>
        </div>
      </div>
      <div className="mt-5">
        <h2 className="text-lg font-extrabold capitalize mb-2.5">Print Label</h2>
        <div className="bg-white rounded-xl p-5">
          <p className="text-lg font-semibold">Print Label</p>
          <p className="text-xs opacity-70 mt-1 mb-4">Print your label now!</p>
          <Button contained className="!px-6.25 text-sm">Print</Button>
        </div>
      </div>
      <div className="flex justify-center mt-5 mb-10">
        <img src="/images/print.png" alt="print" />
      </div>
      <div className="">
        <p className="text-lg font-extrabold mb-2.5">Instrustions</p>
        <div className="bg-white rounded-xl p-5">
          {steps.map((step, index) => (
            <div key={index} className="flex gap-4">
              <div className="flex flex-col items-center gap-1">
                <div className="w-4 h-4 rounded-full bg-primary" />
                {steps.length > index + 1 && (
                  <div className="flex-1 flex justify-between pb-1">
                    <div className="h-full border-l border-primary" />
                  </div>
                )}
              </div>
              <div className="pb-1">
                <p className="text-sm font-semibold mb-0.5">{step.title}</p>
                <p className="text-xs text-gray-800 font-semibold">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default QuoteDetailPrintPage;
