import { FC, useCallback, useEffect, useRef, useState } from "react";
import { SvgIcon } from "../../atoms/SvgIcon";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import _ from "lodash";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useClickOutside } from "../../../hooks";

type AddressModalProps = {
  show: boolean;
  defaultValue: string;
  onClose: () => void;
  onChange: (...args: any) => void;
};

export const AddressModal: FC<AddressModalProps> = ({
  show = false,
  defaultValue = "",
  onClose = () => null,
  onChange = () => null,
}) => {
  const inputRef = useRef(null),
    suggestionRef = useRef(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [placeDetails, setPlaceDetails] = useState<any[]>([]);
  const [keyword, setKeyword] = useState(defaultValue);
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  useClickOutside([inputRef, suggestionRef], () => {
    setShowSuggestions(false);
  });

  useEffect(() => {
    if (!show) {
      setKeyword(defaultValue);
      setShowSuggestions(false);
      setPlaceDetails([]);
    }
  }, [show, defaultValue]);

  useEffect(() => {
    if (!_.isEmpty(keyword))
      getPlacePredictions({
        input: keyword,
        componentRestrictions: { country: "us" },
      });
  }, [keyword]);

  useEffect(() => {
    if (_.isEmpty(placePredictions)) return;

    new Promise((resolve, reject) => {
      placesService?.getDetails(
        { placeId: placePredictions[0].place_id },
        (placeDetail: any, status: string) => {
          if (status === "OK") resolve(placeDetail);
          else reject();
        }
      );
    });
  }, [placePredictions]);

  const getPlaceDetails = useCallback(async () => {
    try {
      const placeDetails = await Promise.all(
        placePredictions.map(
          ({ place_id }: any) =>
            new Promise((resolve, reject) => {
              placesService?.getDetails(
                { placeId: place_id },
                (placeDetail: any, status: string) => {
                  if (status === "OK") resolve(placeDetail);
                  else reject();
                }
              );
            })
        )
      );

      setPlaceDetails(placeDetails);

      setShowSuggestions(true);
    } catch (error) {
      return [];
    }
  }, [placePredictions]);

  useEffect(() => {
    if (show && !_.isEmpty(placePredictions) && !_.isEmpty(keyword))
      getPlaceDetails();
  }, [show, placePredictions]);

  if (!show) return null;

  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 bg-backdrop flex flex-col justify-end z-50">
      <div className="w-full h-[95vh] max-h-screen bg-white px-5 py-6 rounded-t-large flex flex-col">
        <div className="flex items-center mb-4">
          <div className="p-2 mr-2">
            <SvgIcon name="chevron-left" onClick={onClose} />
          </div>

          <div className="text-lg font-extrabold capitalize">
            Add Your Address
          </div>
        </div>

        <div className="">
          <input
            ref={inputRef}
            className="w-full outline-none h-13 border border-[#9DA4AF26] text-sm font-semibold placeholder-gray-700 placeholder:font-medium py-2.5 px-4 focus:border-primary border-[1.5px] rounded-2.5 focus:bg-primary focus:bg-opacity-[0.06] mb-4"
            placeholder="Search..."
            value={keyword}
            onChange={(evt) => {
              if (evt.target.value !== keyword) {
                setKeyword(evt.target.value);
              }
            }}
          />

          <div ref={suggestionRef}>
            {!isPlacePredictionsLoading &&
              showSuggestions &&
              placeDetails.map((placeDetail) => {
                const address_components = _.get(
                  placeDetail,
                  "address_components",
                  []
                );

                let streetNumber = "",
                  route = "",
                  city = "",
                  stateCode = "",
                  stateName = "",
                  zip = "",
                  countryCode = "",
                  countryName = "";

                for (const component of address_components) {
                  if (component.types.includes("street_number")) {
                    streetNumber = component.short_name;
                  } else if (component.types.includes("route")) {
                    route = component.short_name;
                  } else if (component.types.includes("locality")) {
                    city = component.short_name;
                  } else if (
                    component.types.includes("administrative_area_level_1")
                  ) {
                    stateName = component.long_name;
                    stateCode = component.short_name;
                  } else if (component.types.includes("postal_code")) {
                    zip = component.short_name;
                  } else if (component.types.includes("country")) {
                    countryCode = component.short_name;
                    countryName = component.long_name;
                  }
                }

                const mainText = `${streetNumber} ${route}`;
                const secondaryText = `${city}, ${stateCode} ${zip}, ${countryName}`;

                return (
                  <div
                    className="flex items-center cursor-pointer"
                    key={placeDetail.place_id}
                    onClick={() => {
                      onChange({
                        street: `${streetNumber} ${route}`,
                        city,
                        stateCode,
                        stateName,
                        zip,
                        countryCode,
                        countryName,
                      });

                      setKeyword(`${mainText}, ${secondaryText}`);

                      setShowSuggestions(false);

                      onClose();
                    }}
                  >
                    <div className="p-2 rounded-pin bg-[#4CAC9614]">
                      <FaMapMarkerAlt color="#4CAC96" />
                    </div>

                    <div className="flex-grow p-4 border-b border-pinBorder">
                      <div className="text-sm font-semibold text-textPrimary mb-1">
                        {mainText}
                      </div>
                      <div className="text-m-xs font-semibold text-gray-800">
                        {secondaryText}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
