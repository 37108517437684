import { FC, Fragment, SetStateAction } from "react";
import _ from "lodash";
import { Button, NavigationBar } from "../../../components";
import { QuoteItem, QuoteSteps } from "../../../components/molecules";
import { Picture } from "../../../types";
import ArtisianInfo from "./ArtisianInfo";

type QuoteDetail = {
  leadItem: any;
  pictures: Picture[];
  setActiveQuoteState: React.Dispatch<SetStateAction<number>>;
};

export const QuoteDetail: FC<QuoteDetail> = ({
  leadItem,
  pictures,
  setActiveQuoteState,
}) => {
  const historySteps = _.get(leadItem, "historySteps", []);

  return (
    <Fragment>
      <NavigationBar
        headingText={`Quote #${_.get(leadItem, "activeSalesOrder.name", null)}`}
        showRightButton={false}
      />
      <div className="flex flex-col pb-3 pt-4 px-4">
        <ArtisianInfo leadItem={leadItem} />
      </div>

      <div className="px-4 pb-1">
        <div className="text-gray-750 text-2xs font-medium uppercase mt-2">
          Quote:
        </div>

        <QuoteItem
          className="mb-6"
          leadItem={leadItem}
          pictures={pictures || []}
        />
        <div className="text-gray-750 text-2xs font-medium uppercase mt-2">
          Quote Status:
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg mb-22 sm:mb-2">
          <div className="p-4">
            <QuoteSteps steps={historySteps} />
          </div>
        </div>
      </div>
      <div className="fixed left-0 right-0 bottom-0 z-10 p-4 bg-white shadow flex items-center justify-between">
        <div className="w-full flex items-center justify-center">
          <Button
            onClick={() => setActiveQuoteState(1)}
            contained
            className="w-67 text-sm"
          >
            Review Quote
          </Button>
        </div>
      </div>
    </Fragment>
  );
};
