export const getUTMPayload = () => {
  try {
    const searchParams = new URLSearchParams(window.location.search);

    let utm_source = searchParams.get("utm_source");
    if (!utm_source) utm_source = localStorage.getItem("cobblers_utm_source");
    let utm_medium = searchParams.get("utm_medium");
    if (!utm_medium) utm_medium = localStorage.getItem("cobblers_utm_medium");
    let utm_campaign = searchParams.get("utm_campaign");
    if (!utm_campaign)
      utm_campaign = localStorage.getItem("cobblers_utm_campaign");
    let utm_content = searchParams.get("utm_content");
    if (!utm_content)
      utm_content = localStorage.getItem("cobblers_utm_content");

    return {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
    };
  } catch (error) {
    return {};
  }
};
