import { FC, useEffect, useState } from "react";
import { SvgIcon } from "../../atoms/SvgIcon";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AddressButton } from "../../organisms";
import { Address } from "../../../types";
import { useCustomers } from "../../../contexts/customerContext";
import { Input } from "../../../components/atoms/Input";
import { CustomerApi } from "../../../apis/customer.api";
import { toast } from "react-toastify";
import { LoadingView } from "../../atoms";

type ConfirmAddressModalProps = {
  show: boolean;
  onClose: () => void;
  onConfirm: (addressId: number) => void;
};

const validationSchema = Yup.object().shape({
  address: Yup.string().required("Address is required"),
});

export const ConfirmAddressModal: FC<ConfirmAddressModalProps> = ({
  show = false,
  onClose = () => null,
  onConfirm = () => null,
}) => {
  const { currentUser } = useCustomers();
  const [addrObj, setAddrObj] = useState<Address | null>(null);
  const [isLoading, setLoading] = useState(false);

  const defaultShippingAddressId = _.get(
    currentUser,
    "defaultShippingAddressId",
    null
  );

  const onSubmit = async (values: Yup.InferType<typeof validationSchema>) => {
    setLoading(true);
    let addressId = defaultShippingAddressId;
    try {
      if (addrObj) {
        // unit / suite
        if (!_.isEmpty(form.values.unit)) {
          addrObj.street2 = form.values.unit;
        }
        const addRes = await CustomerApi.postAddresses(addrObj);
        addressId = addRes.id;
      }

      if (addressId) {
        onConfirm(addressId);
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const form = useFormik({
    initialValues: {
      address: "",
      unit: "",
    },
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (currentUser) {
      form.setValues({
        address: currentUser.defaultShippingAddress,
        unit: "",
      });
    }
  }, [currentUser]);

  if (!show) return null;

  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 bg-backdrop flex flex-col justify-end z-50">
      <div className="w-full h-[65vh] max-h-screen bg-white px-5 py-6 rounded-t-large flex flex-col">
        <div className="flex items-center mb-4">
          <div className="p-2 mr-2">
            <SvgIcon name="chevron-left" onClick={onClose} />
          </div>

          <div className="text-lg font-extrabold capitalize">
            Confirm your Shipping Address
          </div>
        </div>

        <form
          onSubmit={form.handleSubmit}
          className="w-[89%] mx-auto flex flex-col rounded-4 mt-7 h-full justify-between"
        >
          <div>
            <div className="mb-4">
              <div className="rounded-2.5 border border-gray-200">
                <AddressButton
                  placeholder="Address"
                  {...form.getFieldProps("address")}
                  onChange={(address) => form.setFieldValue("address", address)}
                  onChangeValue={setAddrObj}
                />
              </div>
              {form.errors.address && (
                <small className="text-red-500 ml-2 mt-1">
                  {form.errors.address}
                </small>
              )}
            </div>

            <div className="rounded-2.5 border border-gray-200">
              <Input
                icon="location-outlined"
                placeholder="Unit / Suite (optional)"
                {...form.getFieldProps("unit")}
                helperText={
                  form.errors.unit && form.touched.unit ? form.errors.unit : ""
                }
                error={!!form.errors.unit && !!form.touched.unit}
              />
            </div>
          </div>

          <button
            type="submit"
            className="w-full mx-auto py-2.5 bg-blue font-semibold text-brightPrimary rounded-md"
          >
            Confirm Address
          </button>
        </form>
      </div>

      <LoadingView show={isLoading} />
    </div>
  );
};
