import { FC, Fragment, useState } from "react";
import { Button, NavigationBar, QuoteSteps } from "../../../components";
import _ from "lodash";
import { FaStar } from "react-icons/fa";
import { abbrNumber } from "../../../utils";
import { format } from "date-fns";
import { BsChatLeftText } from "react-icons/bs";
import { getPlatform } from "../../../utils/getPlatform";
import GeneralModal from "../../../components/molecules/GeneralModal";
import { useAtomValue } from "jotai";
import { hideBottomBarAtom } from "../../../jotai";

type ToPrintLabelItemProps = {
  leadItem: any;
  hidePrintLabel?: boolean;
};

export const ToPrintLabel: FC<ToPrintLabelItemProps> = ({
  leadItem,
  hidePrintLabel = false,
}) => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const historySteps = _.get(leadItem, "historySteps", []);
  const shippingLabelPrintUrl = _.get(leadItem, "shippingLabelPrintUrl", null);
  const hideBottomBar = useAtomValue(hideBottomBarAtom);

  const handleClickPrintMyLabel = () => {
    if (shippingLabelPrintUrl) window.open(shippingLabelPrintUrl, "__target");
  };

  return (
    <Fragment>
      <NavigationBar
        headingText={`Quote #${_.get(leadItem, "activeSalesOrder.name", null)}`}
      />

      <div className="p-4">
        <div className="text-gray-750 text-2xs font-medium uppercase mt-2">
          Quote:
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="grid grid-cols-1 p-4">
            <div className="flex gap-4">
              <div className="w-[76px] h-[76px] min-w-[76px] min-h-[76px] rounded-md p-2">
                <img
                  src={_.get(leadItem, "coverPictureUrl")}
                  className="w-full h-full max-h-hull max-w-full object-cover"
                />
              </div>

              <div className="flex-grow flex flex-col justify-between">
                <div className="flex items-center justify-between mb-3">
                  <div className="text-textPrimary text-sm font-semibold">
                    {_.get(leadItem, "brand", null)}&nbsp;-&nbsp;
                    {_.get(leadItem, "categoryName", null)}
                  </div>

                  <div className="text-m-xs text-gray-750 font-medium">
                    {format(
                      new Date(_.get(leadItem, "createDate", undefined)),
                      "d MMM, y"
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="text-m-xs opacity-50 font-semibold text-gray-750">
                    #{_.get(leadItem, "displayName", null)}
                  </div>

                  <div className="bg-textPrimary rounded-md	text-white px-2.5 py-1.5 text-2xs capitalize">
                    {_.get(leadItem, "currentWorkflowStepName", null)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-gray-750 text-2xs font-medium uppercase mt-2">
          Artisan Info:
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg mb-6">
          <div className="p-4 flex items-center justify-between">
            <div>
              <div className="text-sm font-bold mb-3">
                <div className="text-textPrimary">
                  {_.get(leadItem, "artisanName", null)}
                </div>
              </div>

              <div className="flex items-center gap-1.5 text-m-xs">
                <FaStar className="text-primary" size={16} />

                <div className="text-[11px] font-semibold text-textPrimary opacity-60">
                  {abbrNumber(_.get(leadItem, "artisanJobsCount", 0))}{" "}
                  Restorations completed
                </div>
              </div>
            </div>

            <button
              className="inline-flex items-center text-xs text-textPrimary font-semibold py-2 px-3 bg-pinBorder rounded-md outline-none"
              onClick={() => setShowDownloadModal(true)}
            >
              <BsChatLeftText size={12} className="text-textPrimary mr-2" />
              Chat
            </button>
          </div>
        </div>

        <div className="text-gray-750 text-2xs font-medium uppercase mt-2">
          Quote Status:
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg mb-9">
          <div className="p-4">
            <QuoteSteps steps={historySteps} />
          </div>
        </div>

        <div className="text-center text-xs font-medium text-grayText mb-9">
          Important: Once approved, your quote is guaranteed for a period of 14
          days. To secure the quoted price and artisan availability, we kindly
          request that you ship your items promptly.
        </div>

        <div className={hidePrintLabel ? "hidden" : ""}>
          <Button contained fullWidth onClick={handleClickPrintMyLabel}>
            Print My Label
          </Button>
        </div>
      </div>

      {showDownloadModal && (
        <GeneralModal
          helperText={
            hideBottomBar
              ? "Please click the Inbox icon on the bottom of your screen to start the chat"
              : "To Chat Directly with Your Artisan"
          }
          onClose={() => setShowDownloadModal(false)}
          {...(!hideBottomBar && {
            mainText: "Download the craftyz App",
            actionButtons: [
              <Button
                fullWidth
                contained
                onClick={() =>
                  window.open(
                    `https://deeplink.thecobblers.com/downloadcustomerapp?platform=${getPlatform()}`,
                    "_blank"
                  )
                }
              >
                Download
              </Button>,
            ],
          })}
        />
      )}
    </Fragment>
  );
};
