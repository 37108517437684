import React, { useState, useEffect, useRef } from "react";
import { NavigationBar } from "../atoms";
import NextSteps from "./NextSteps";
import { SvgIcon } from "../atoms/SvgIcon";
import { SetStateAction } from "jotai";
import * as Yup from "yup";
import { InferType } from "yup";
import { useFormik } from "formik";
import { AddressButton } from "../organisms";
import { Address } from "../../types";
import { CustomerApi } from "../../apis/customer.api";
import StripeInput, {
  StripeInputRef,
} from "../organisms/AddPaymentMethodDialog/StripeInput";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from "../../constants";
import { LoadingView } from "../../components";
import _ from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import { ILeadItem, ISalesOrder } from "./interfaces/LeadItem";
import { toast } from "react-toastify";
import { Input } from "../atoms/Input";

const stripePublicKey = loadStripe(STRIPE_PUBLIC_KEY);

const validationSchema = Yup.object().shape({
  address: Yup.string().required("Address is required"),
});

const NextStepsAboutPage = ({
  selectedSalesOrder,
  leadItem,
  artisanName,
  createDate,
  setActiveQuoteState,
}: {
  selectedSalesOrder: ISalesOrder | null;
  leadItem: ILeadItem;
  artisanName: string | any;
  createDate: string | any;
  setActiveQuoteState: React.Dispatch<SetStateAction<number>>;
}) => {
  const appearance = {
    theme: "stripe",
  } as const;

  const navigate = useNavigate();

  // current itemId & leadId from route
  const { itemId, leadId } = useParams();

  // card field error
  const [cardError, setCardError] = useState("");

  const stripeInputRef = useRef<StripeInputRef>(null);

  // loading view
  const [isLoading, setLoading] = useState(false);

  // address
  const [addrObj, setAddrObj] = useState<Address | null>(null);

  // clean up method to reset ui before submitting
  const reset = () => {
    setCardError("");
  };

  const onSubmit = async (values: InferType<typeof validationSchema>) => {
    reset();
    try {
      setLoading(true);

      // save address
      let addressId;
      if (addrObj != null) {
        // unit / suite
        if (!_.isEmpty(form.values.unit)) {
          addrObj.street2 = form.values.unit;
        }
        const addRes = await CustomerApi.postAddresses(addrObj);
        addressId = addRes.id;
      }

      // save payment
      if (!stripeInputRef.current) {
        setLoading(false);
        toast.error("Something went wrong!");
        return;
      }
      const cardRes = await stripeInputRef.current.addPaymentMethod();
      if (cardRes.error) {
        setLoading(false);
        setCardError(cardRes.error.message);
        toast.error(cardRes.error.message);
        return;
      }
      const addPaymentMethodRes = await CustomerApi.addPaymentMethod({
        token: cardRes.paymentMethod?.id,
      });
      if (!addPaymentMethodRes.id) {
        setLoading(false);
        toast.error("Something went wrong!");
        return;
      }

      // accept sales order item
      const saleOrderId = selectedSalesOrder?.id;

      const data = {
        saleOrderId,
        paymentMethodId: addPaymentMethodRes.id,
        addressId,
      };
      const submitRes = await CustomerApi.putAcceptSaleOrderItem(
        itemId!,
        leadId!,
        saleOrderId!,
        data
      );
      if (!submitRes.isSuccess) {
        setLoading(false);
        toast.error("Something went wrong!");
        return;
      }

      // process complete!
      setLoading(false);
      toast.success("Accepted Sale Order Item!");
      navigate(-1);
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const form = useFormik({
    initialValues: {
      address: "",
      unit: "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <div className="relative placeholder:px-4 pt-4 w-full h-auto pb-5">
      <NavigationBar
        headingText="Next Steps"
        showRightButton={false}
        showLeftButton={false}
      />
      <button
        onClick={() => setActiveQuoteState && setActiveQuoteState(1)}
        className="absolute top-7 right-6  text-4xl text-gray-750"
      >
        &times;
      </button>
      <div className="w-[91%] mx-auto flex flex-col pb-3 px-4 mt-8 bg-white rounded-md h-auto">
        <NextSteps artisanName={artisanName} createDate={createDate} />
      </div>

      {/*Contact Form */}
      <form
        onSubmit={form.handleSubmit}
        className="w-[89%] mx-auto flex flex-col rounded-md mt-7"
      >
        <div className="relative mb-4">
          <AddressButton
            placeholder="Address"
            {...form.getFieldProps("address")}
            onChange={(address) => form.setFieldValue("address", address)}
            onChangeValue={setAddrObj}
          />
          {form.errors.address && (
            <small className="text-red-500 ml-2 mt-1">
              {form.errors.address}
            </small>
          )}
        </div>

        <div className="w-full mb-4">
          <Input
            icon="location-outlined"
            placeholder="Unit / Suite (optional)"
            {...form.getFieldProps("unit")}
            helperText={
              form.errors.unit && form.touched.unit ? form.errors.unit : ""
            }
            error={!!form.errors.unit && !!form.touched.unit}
          />
        </div>

        <div className="flex items-center bg-white py-4 px-4 rounded-md">
          <div className="border-r border-r-gray-300">
            <SvgIcon name="card" className="mr-4" />
          </div>
          <div className="pl-4" style={{ width: "100%" }}>
            <Elements stripe={stripePublicKey} options={{ appearance }}>
              <StripeInput ref={stripeInputRef} />
            </Elements>
          </div>
        </div>
        {cardError && (
          <small className="text-red-500 ml-2 mt-1">{cardError}</small>
        )}
        <button
          type="submit"
          className="w-full mx-auto py-2.5 bg-blue font-semibold text-brightPrimary rounded-md my-13"
        >
          Continue
        </button>
      </form>

      <LoadingView show={isLoading} />
    </div>
  );
};

export default NextStepsAboutPage;
