import { FC, ReactNode } from "react";

type IconButtonProps = {
  icon: ReactNode;
  onClick?: () => void;
};

export const IconButton: FC<IconButtonProps> = ({
  icon = null,
  onClick = () => null,
}) => (
  <button
    onClick={onClick}
    className="inline-flex items-center justify-center focus:outline-none w-6 h-6"
  >
    {icon}
  </button>
);
