import React, { useEffect, useState } from "react";
import { SvgIcon } from "../../components/atoms/SvgIcon";
import { useNavigate } from "react-router-dom";
import { CustomerApi } from "../../apis/customer.api";
import Button from "../../components/atoms/Button";
import AddPaymentMethodDialog from "../../components/organisms/AddPaymentMethodDialog";
import { useCustomers } from "../../contexts/customerContext";

export const EditPaymentMethod = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const { fetchMe } = useCustomers();

  useEffect(() => {
    CustomerApi.getPaymentMethods().then((res) => {
      setPaymentMethods(res);
    });
  }, []);

  const handlePrev = () => {
    navigate(-1);
  };

  const handleAfterAdded = (paymentMethods: any[]) => {
    setPaymentMethods(paymentMethods);

    fetchMe();
  };

  return (
    <div className="h-screen bg-homeBg px-4.5">
      <div className="pt-15 pb-10 flex justify-between items-center">
        <SvgIcon name="chevron-left" onClick={handlePrev} />
        <h2 className="text-lg font-bold">Payment Method</h2>
        <div />
      </div>
      <div className="flex justify-end mb-4">
        <Button
          className="!px-8 !font-medium"
          contained
          onClick={() => setIsOpen(true)}
        >
          Add payment method
        </Button>
      </div>
      <div>
        {paymentMethods.map((paymentMethod, index) => (
          <div className="bg-white rounded-lg p-4 mb-4" key={index}>
            <div className="flex justify-between items-center pb-5 border-b border-borderPrimary">
              <p className="text-lg font-semibold uppercase">
                {paymentMethod.cardBrand}
              </p>
              <p className="text-xs font-semibold opacity-60">
                xxxxxxxxxxx{paymentMethod.last4}
              </p>
            </div>
            <div className="flex justify-end items-center opacity-60 gap-2 pt-2">
              <p className="text-2xs">Edit Information</p>
              <SvgIcon name="chevron-right" />
            </div>
          </div>
        ))}
      </div>
      <AddPaymentMethodDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onAfterAdded={handleAfterAdded}
      />
    </div>
  );
};
