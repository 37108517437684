import { FC,useMemo,useState } from "react";
import { Lead, Picture, OrderLine } from "../../types";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import clsx from "clsx";
import _ from "lodash";
import styled from "styled-components";

type AccordionProps = {
  orderLines: OrderLine[];
};
const Accordion = styled.div<AccordionProps>(({ orderLines }) => ({
  maxHeight: 0,

  "&.opened": {
    maxHeight: 92 * orderLines.length + 32,
  },
}));

type QuoteItemProps = {
  className?: string;
  leadItem: Lead;
  pictures: Picture[];
};

export const QuoteItem: FC<QuoteItemProps> = ({
  leadItem,
  className,
  pictures,
}) => {
  const [isOpened, setOpened] = useState(false);

  const orderLines: OrderLine[] = _.get(
    leadItem,
    "activeSalesOrder.orderLines",
    []
  );


  const priceArray = leadItem?.saleOrders?.map((order: any) =>
    parseFloat(order?.amountTotal)
  );

  const getMinMax = useMemo(() => {
    if (priceArray && priceArray.length > 0) {
      const min = Math.min(...priceArray);
      const max = Math.max(...priceArray);
      return {
        min,
        max,
      };
    }
  }, [priceArray]);

  return (
    <div
      className={clsx("bg-white overflow-hidden shadow rounded-lg", className)}
    >
      <div className="grid grid-cols-1 p-4">
        <div className="flex gap-4">
          <div className="w-[76px] h-[76px] min-w-[76px] min-h-[76px] rounded-md p-2">
            <img
              src={_.get(leadItem, "coverPictureUrl")}
              className="w-full h-full max-h-hull max-w-full object-cover"
            />
          </div>

          <div className="">
            <div className="inline-flex mb-2 px-2.5 py-1.5 text-textPrimary text-2xs border rounded-md border-borderPrimary capitalize">
              {_.get(leadItem, "currentWorkflowStepName", null)}
            </div>

            {/* <div className="mb-2.5 text-brandTag font-semibold text-textPrimary text-ellipsis overflow-hidden w-[240px] h-[40px]">
              Lorem ipsum dolor sit amet, cons ectetur adipiscing elit. Proin
              pulvinar nibh leo.
            </div> */}

            <div className="mb-3 text-textPrimary text-xs font-semibold opacity-60">
              {_.get(leadItem, "brand", null)}&nbsp;-&nbsp;
              {_.get(leadItem, "categoryName", null)}
            </div>

            <div className="text-lg text-primary font-bold">
              {`$${getMinMax?.min} - $${getMinMax?.max}`}
            </div>
          </div>
        </div>

        <hr className="my-4" />

        <Accordion
          className={clsx("overflow-hidden duration-500", isOpened && "opened")}
          orderLines={orderLines}
        >
          <div className="grid grid-cols-1 gap-4">
            {orderLines.map((orderLine: OrderLine, key: number) => {
              const picture: Picture | undefined = pictures.find(
                ({ id }) => id === orderLine.pictureId
              );
              const lineName = _.get(
                _.get(
                  _.get(orderLine, "name", "").split("] "),
                  "[1]",
                  ""
                ).split(" ["),
                "[0]",
                ""
              );

              return (
                <div className="bg-gray-100 rounded-pin p-2 flex" key={key}>
                  <div className="w-[60px] h-[60px] min-w-[60px] min-h-[60px] bg-white rounded-md p-2">
                    {!!picture && (
                      <img
                        src={picture.url}
                        className="w-full h-full max-h-hull max-w-full object-cover"
                      />
                    )}
                  </div>

                  <div className="flex flex-col">
                    <div className="mt-auto text-quoteStatus font-semibold text-textPrimary mb-2">
                      {lineName} x {_.get(orderLine, "quantity", 1)}
                    </div>

                    <div className="mb-auto text-m-xs font-medium text-textPrimary opacity-50">
                      {_.get(orderLine, "name", "")}
                    </div>
                  </div>

                  <div className="ml-auto text-quoteStatus text-textPrimary font-semibold">
                    ${_.get(orderLine, "unitPrice", 0).toFixed(2)}
                  </div>
                </div>
              );
            })}
          </div>

          <hr className="my-4" />
        </Accordion>

        <div className="flex items-center justify-between">
          <div className="text-xs opacity-50 font-semibold text-textPrimary">
            #{_.get(leadItem, "displayName", null)}
          </div>

          <button
            className="flex items-center text-xs text-textPrimary opacity-50 font-medium"
            onClick={() => setOpened(!isOpened)}
          >
            {isOpened ? "Show less " : "Show more "}
            {isOpened ? <FiChevronUp size={24} /> : <FiChevronDown size={24} />}
          </button>
        </div>
      </div>
    </div>
  );
};
