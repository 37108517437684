import { FC, Fragment, useState } from "react";
import { Address, Lead, Picture } from "../../../types";
import {
  Button,
  LoadingView,
  NavigationBar,
  QuoteSteps,
  SvgIcon,
} from "../../../components";
import _ from "lodash";
import { format } from "date-fns";
import { FaStar } from "react-icons/fa";
import { abbrNumber } from "../../../utils";
import { BsChatLeftText } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { CustomerApi } from "../../../apis/customer.api";
import { useCustomers } from "../../../contexts";
import { getPlatform } from "../../../utils/getPlatform";
import GeneralModal from "../../../components/molecules/GeneralModal";
import { useAtomValue } from "jotai";
import { hideBottomBarAtom } from "../../../jotai";
import clsx from "clsx";
import { ConfirmAddressModal } from "../../../components/organisms/ConfirmAddressModal";

type ApproveQualityProps = {
  leadItem: Lead;
};

export const ApproveQuality: FC<ApproveQualityProps> = ({ leadItem }) => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useCustomers();
  const hideBottomBar = useAtomValue(hideBottomBarAtom);
  const [showModal, setShowModal] = useState(false);

  const currentWorkflowStepName = _.get(
    leadItem,
    "currentWorkflowStepName",
    null
  );

  const coverPictureUrl = _.get(
    leadItem,
    "coverPictureUrl",
    "/images/rouis_bag.png"
  );
  const historySteps = _.get(leadItem, "historySteps", []);
  const amountTotal = _.get(leadItem, "activeSalesOrder.amountTotal", null);
  const activePaymentMethodId = _.get(
    currentUser,
    "activePaymentMethodId",
    null
  );
  const defaultShippingAddressId = _.get(
    currentUser,
    "defaultShippingAddressId",
    null
  );

  const handleApproveConfirmAddress = () => {
    setShowModal(true);
  };

  const handleApprove = async (addressId: number) => {
    try {
      setShowModal(false);
      setLoading(true);

      const acceptedSaleOrderId = leadItem.acceptedSaleOrder?.id;

      await CustomerApi.putApproveQC(
        leadItem.itemId,
        leadItem.id,
        acceptedSaleOrderId,
        {
          saleOrderId: acceptedSaleOrderId,
          paymentMethodId: activePaymentMethodId,
          addressId,
        }
      );
    } catch (error) {
    } finally {
      setLoading(false);
      navigate(-1);
    }
  };

  return (
    <Fragment>
      <NavigationBar
        headingText={`Quote #${_.get(leadItem, "displayName", null)}`}
      />

      <div
        className={clsx(
          "relative px-4 pt-4",
          !amountTotal ? "pb-4" : "pb-[90px]"
        )}
      >
        <div className="flex gap-3.5 bg-white p-5 rounded-xl">
          <div className="flex justify-center items-center min-w-9.5 h-9.5 rounded-primary bg-helperText">
            <SvgIcon name="truck" className="text-white" />
          </div>

          <div className="relative">
            <p className="font-semibold">
              {_.get(leadItem, "brand", null)}&nbsp;-&nbsp;
              {_.get(leadItem, "categoryName", null)}
            </p>
            <p className="text-xs font-semibold text-gray-800">
              {format(
                new Date(_.get(leadItem, "createDate", undefined)),
                "d MMM, y"
              )}
            </p>
            <div className="absolute top-0 right-0 bg-notification px-2 py-1 text-xs font-semibold text-primary rounded-small">
              {currentWorkflowStepName}
            </div>
            <div className="border-b border-pinBorder my-5" />
            <div className="grid grid-cols-2 gap-2">
              {["before", "after"].map((badgeText) => (
                <div className="relative border rounded-lg" key={badgeText}>
                  <div className="absolute z-10 top-2 left-2 px-1.5 py-0.5 text-2xs text-white font-medium bg-grayText rounded capitalize">
                    {badgeText}
                  </div>

                  <img
                    src={coverPictureUrl}
                    alt="before"
                    className="rounded-lg w-30 h-30 object-contain"
                    style={
                      badgeText === "before"
                        ? {
                            filter: "grayscale(1)",
                          }
                        : {}
                    }
                  />
                </div>
              ))}
            </div>
            <div className="relative flex justify-between items-center my-5 gap-2.5">
              <Button
                fullWidth
                className="!px-6.25 text-sm border-primary !text-primary"
                onClick={() =>
                  navigate(
                    `/profile/review-after-photos/item/${leadItem.itemId}/lead/${leadItem.id}`
                  )
                }
              >
                Review After Photos
              </Button>
            </div>
          </div>
        </div>

        <div className="text-gray-750 text-2xs font-medium uppercase mt-2">
          Artisan Info:
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg mb-6">
          <div className="p-4 flex items-center justify-between">
            <div>
              <div className="text-sm font-bold mb-3">
                <div className="text-textPrimary">
                  {_.get(leadItem, "artisanName", null)}
                </div>
              </div>

              <div className="flex items-center gap-1.5 text-m-xs">
                <FaStar className="text-primary" size={16} />

                <div className="text-[11px] font-semibold text-textPrimary opacity-60">
                  {abbrNumber(_.get(leadItem, "artisanJobsCount", 0))}{" "}
                  Restorations completed
                </div>
              </div>
            </div>

            <button
              className="inline-flex items-center text-xs text-textPrimary font-semibold py-2 px-3 bg-pinBorder rounded-md outline-none"
              onClick={() => setShowDownloadModal(true)}
            >
              <BsChatLeftText size={12} className="text-textPrimary mr-2" />
              Chat
            </button>
          </div>
        </div>

        <div className="text-gray-750 text-2xs font-medium uppercase mt-2">
          Order Status:
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg mb-9">
          <div className="p-4">
            <QuoteSteps steps={historySteps} />
          </div>
        </div>

        {!!amountTotal && (
          <div className="fixed bottom-0 w-full flex justify-between items-center -mx-4 p-4 bg-white shadow-footer">
            <div>
              <p className="text-xs opacity-50 mb-1">Total cost:</p>
              <p className="text-2xl font-extrabold">
                ${amountTotal.toFixed(2)}
              </p>
            </div>
            <Button
              contained
              className="!text-brandTag !font-medium"
              onClick={handleApproveConfirmAddress}
            >
              Approve & pay balance
            </Button>
          </div>
        )}
      </div>

      <ConfirmAddressModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={handleApprove}
      />

      {showDownloadModal && (
        <GeneralModal
          helperText={
            hideBottomBar
              ? "Please click the Inbox icon on the bottom of your screen to start the chat"
              : "To Chat Directly with Your Artisan"
          }
          onClose={() => setShowDownloadModal(false)}
          {...(!hideBottomBar && {
            mainText: "Download the craftyz App",
            actionButtons: [
              <Button
                fullWidth
                contained
                onClick={() =>
                  window.open(
                    `https://deeplink.thecobblers.com/downloadcustomerapp?platform=${getPlatform()}`,
                    "_blank"
                  )
                }
              >
                Download
              </Button>,
            ],
          })}
        />
      )}

      <LoadingView show={isLoading} />
    </Fragment>
  );
};
