import { useEffect } from "react";
import { useScrollbarWidth } from "./useScrollbarWidth";

export const useScrollLock = (lock: boolean, lockTouch = true) => {
  const scrollbarWidth = useScrollbarWidth();

  useEffect(() => {
    if (lock) {
      if (
        !!scrollbarWidth &&
        scrollbarWidth > 0 &&
        document.getElementsByTagName("body")[0].scrollHeight >
          window.innerHeight
      ) {
        document.body.style.paddingRight = `${scrollbarWidth}px`;
      }

      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.paddingRight = "";
      document.body.style.overflow = "";
    };
  }, [lock, lockTouch, scrollbarWidth]);
};
