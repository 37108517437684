const dummyUtils = () => {
  return;
};

export function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  } else {
    return "";
  }
}

export default dummyUtils;

export * from "./abbrNumber";

export * from "./getUTMPayload";

export * from "./validations";
