import { FC, ReactNode, SetStateAction, useState } from "react";
import { IconButton } from "./IconButton";
import { FaChevronLeft } from "react-icons/fa";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

type NavigationBarProps = {
  headingText?: string;
  showLeftButton?: boolean;
  showRightButton?: boolean;
  leftButton?: ReactNode;
  rightButton?: ReactNode;
  deleteButton?: boolean;
  onClickLeftButton?: () => void;
  onClickRightButton?: () => void;
  showDeclinePopUp?: React.Dispatch<SetStateAction<boolean>>;
  setActiveQuoteState?: React.Dispatch<SetStateAction<any>>;
};

export const NavigationBar: FC<NavigationBarProps> = ({
  headingText = "",
  showLeftButton = true,
  showRightButton = true,
  leftButton = null,
  rightButton = null,
  showDeclinePopUp,
  onClickLeftButton,
}) => {
  const [showAllDecline, setShowAllDecline] = useState(false);
  const navigate = useNavigate();

  const handleClickLeftButton = () => {
    if (!showLeftButton) return;

    if (onClickLeftButton && typeof onClickLeftButton === "function")
      onClickLeftButton();
    else {
      navigate(-1);
    }
  };
 
  const handleDeclinePop = () => {    
    if(showDeclinePopUp){
      showDeclinePopUp(true)
    }
    setShowAllDecline(false)
  }

  return (
    <div className="w-full relative p-4">
      <div className="absolute left-14 right-14 text-center text-blue text-[1.125rem] font-bold z-10">
        {headingText}
      </div>

      <div className="flex items-center justify-between z-20">
        <div className={clsx("w-6 h-6", !showLeftButton && "invisible")}>
          {leftButton ? (
            leftButton
          ) : (
            <IconButton
              icon={<FaChevronLeft size={24} className="text-textPrimary" />}
              onClick={handleClickLeftButton}
            />
          )}
        </div>

        <div className={clsx("w-6 h-6", !showRightButton && "invisible")}>
          {rightButton ? (
            rightButton
          ) : (
            <IconButton
              icon={
                <BiDotsHorizontalRounded
                  size={24}
                  className="text-textPrimary"
                />
              }
              onClick={() => setShowAllDecline(!showAllDecline)}
            />
          )}
          {showAllDecline && (
            <button
              onClick={() => handleDeclinePop()}
              className="rounded-md bg-white p-3 text-red-500 font-medium w-40 shadow-md absolute top-7 right-11 cursor-pointer z-20"
            >
              Decline all
            </button>
          )}
           
        </div>
      </div>
    </div>
  );
};
