import React, { ChangeEvent, useEffect, useState } from "react";
import { SvgIcon } from "../../components/atoms/SvgIcon";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { CustomerApi } from "../../apis/customer.api";

const items = [
  {
    id: 1,
    image: "/images/shoe2.png",
    title: "High Heels",
    manufacturer: "Christian Louboutin",
  },
  {
    id: 2,
    image: "/images/hand_bag.png",
    title: "Handbag",
    manufacturer: "Louis Vuitton",
  },
];

const tabs = [
  { label: "My Closet", value: "my-closet" },
  { label: "Active Items", value: "active-items" },
  { label: "Other Option", value: "other-option" },
];

export const Closet = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>("my-closet");
  const [products, setProducts] = useState<any[]>([]);

  const handlePrev = () => {
    navigate(-1);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    CustomerApi.getCustomerItems().then((res) => {
      setProducts(res);
    });
  }, []);

  const goToHistory = () => {
    const path = location.pathname;
    if (path[path.length - 1] === "/") {
      navigate(path + "history");
    } else {
      navigate(path + "/history");
    }
  };

  return (
    <div className="flex flex-col h-screen bg-homeBg">
      <div className="pt-15 pb-5 flex justify-between items-center px-3.75">
        <SvgIcon name="chevron-left" onClick={handlePrev} />
        <h2 className="text-lg font-bold">Closet</h2>
        <SvgIcon name="three-dots" />
      </div>
      <div className="flex items-center gap-3 mb-7.5 mx-3.75 px-3 py-2 bg-white rounded-lg">
        <SvgIcon name="search" className="text-backdrop w-5 h-5" />
        <input
          placeholder="Search..."
          value={search}
          onChange={handleChange}
          className="outline-none border-none text-brandTag placeholder:text-backdrop"
        />
      </div>
      <div className="flex justify-between items-center border-b border-b-borderPrimary px-3.75">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={clsx("px-4 pb-3 text-sm font-semibold w-fit", {
              "border-b border-b-primary text-primary": activeTab === tab.value,
            })}
            onClick={() => setActiveTab(tab.value)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="flex-1 flex flex-col gap-3 overflow-y-auto px-3.75 py-5">
        {products.map((item, index: number) => (
          <div key={index} className="flex gap-4.5 bg-white rounded-lg p-3.25">
            <div className="flex justify-center items-center w-19 h-19 rounded-md bg-gray-500 bg-opacity-10">
              <img
                src={item.mainPictureUrl}
                alt={item.mainPictureUrl}
                className="w-19 h-19 rounded-md object-cover"
              />
            </div>
            <div className="flex-1">
              <p className="text-brandTag font-semibold mb-2">{item.name}</p>
              <p className="text-xs font-semibold opacity-60 mt-1">
                {item.brand} {item.itemNumber}
              </p>
              <div className="border-b opacity-50 mt-8" />
              <div className="flex justify-end pt-3">
                <div className="flex items-center" onClick={goToHistory}>
                  <p className="text-2xs">Show Service History</p>
                  <SvgIcon name="chevron-right" className="w-3 h-3" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
