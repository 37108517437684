import { parsePhoneNumber, AsYouType } from "libphonenumber-js";

export const validatePhoneNumber = (value: string): boolean => {
  try {
    const phoneNumber = parsePhoneNumber(value);
    return phoneNumber && phoneNumber.isValid();
  } catch (error) {
    return false;
  }
};

export const formatInternationalPhoneNumber = (value: string): string => {
  return new AsYouType().input(value);
};
