import { useAtom, useAtomValue } from "jotai";
import React, { ChangeEvent, useState } from "react";
import {
  productDetailsAtom,
  selectedBrandAtom,
  selectedMetadataAtom,
  stepAtom,
} from "../../../jotai/quote.jotai";
import { SvgIcon } from "../../../components/atoms/SvgIcon";
import BrandTag from "../../../components/atoms/BrandTag";
import { StepFooter } from "./StepFooter";
import { useSetAtom } from "jotai/index";

const categories = ["Top Rated", "Popular", "New Brands", "New Brand"];

const SelectYourBrand = () => {
  const [search, setSearch] = useState<string>("");
  const selectedMetadata = useAtomValue(selectedMetadataAtom);
  const [selectedBrand, setSelectedBrand] = useAtom(selectedBrandAtom);
  const setProductDetails = useSetAtom(productDetailsAtom);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const setStep = useSetAtom(stepAtom);

  const handleChangeSearchBrand = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <div
      className="divide-y overflow-y-auto"
      style={{ maxHeight: "calc(100% - 150px)" }}
    >
      {/* <div className="flex flex-col mb-3 gap-4">
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-1 pb-2">
            <SvgIcon name="filters" />
            <p className="text-xs font-normal">Filters</p>
          </div>
          <div className="flex items-center gap-2 overflow-x-auto pb-2">
            {categories.map((category, index) => (
              <BrandTag
                key={index}
                variant="filter"
                label={category}
                active={selectedCategory === category}
                onClick={() => setSelectedCategory(category)}
              />
            ))}
          </div>
        </div>
      </div> */}
      <div>
        <p className="mt-5 text-2xs font-semibold text-grayText">
          SELECT ONE BRAND
        </p>
        <div className="mt-3 flex items-center flex-wrap gap-x-2 gap-y-4">
          {selectedMetadata?.brands?.map((brand) => (
            <BrandTag
              key={brand.id}
              label={brand.name}
              active={selectedBrand?.id === brand.id}
              onClick={() => {
                setSelectedBrand(brand);
                setProductDetails(null);
                setStep(1);
              }}
            />
          ))}
        </div>
      </div>

      <StepFooter
        onNext={() => !!selectedBrand && setStep(1)}
        disabledNext={!selectedBrand}
      />
    </div>
  );
};

export default SelectYourBrand;
