import clsx from "clsx";
import { MouseEvent, ReactNode, useMemo } from "react";
import { SvgIcon } from "../../atoms/SvgIcon";

type modalType = "success" | "alert" | "confirm";

type Props = {
  isOpen?: boolean;
  type?: modalType;
  mainText?: string;
  helperText?: string;
  actionButtons?: ReactNode;
  className?: string;
  onClose?: () => void;
  children?: ReactNode;
};

const GeneralModal = ({
  isOpen = true,
  type = "success",
  mainText,
  helperText,
  actionButtons,
  className,
  onClose,
  children,
}: Props) => {
  if (!isOpen) {
    return null;
  }

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const icon = useMemo(() => {
    switch (type) {
      case "success":
        return <SvgIcon name="tick-circle" className="text-primary" />;
      case "alert":
        return <SvgIcon name="close-circle" className="text-red-close" />;
      case "confirm":
        return <SvgIcon name="wallet-contained" className="text-primary" />;
    }
  }, [type]);

  return (
    <div
      className="fixed top-0 left-0 flex items-center justify-center bg-backdrop w-screen h-screen px-3 z-50"
      onClick={handleClose}
    >
      <div
        className={clsx(
          "flex flex-col items-center gap-8.5 px-6.5 py-7.5 w-full rounded-medium bg-white",
          className
        )}
        onClick={handleClick}
      >
        <div
          className={clsx(
            "flex items-center justify-center h-19 w-19",
            type === "alert"
              ? "rounded-full bg-red-closeOpacity"
              : "rounded-large bg-mediumGray"
          )}
        >
          {type === "confirm" ? (
            <>{icon}</>
          ) : (
            <div
              className={clsx(
                "flex items-center justify-center h-9 w-9 rounded-full",
                type === "success" ? "" : ""
              )}
            >
              {icon}
            </div>
          )}
        </div>
        <div className="flex flex-col items-center text-center gap-2.5">
          {mainText && (
            <p className="text-lg font-bold text-blue capitalize">
              {mainText}
            </p>
          )}
          {helperText && <p className="text-xs text-grayText">{helperText}</p>}
        </div>

        {children}

        {actionButtons && (
          <div className="flex items-center justify-between w-full h-12 gap-3">
            {actionButtons}
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralModal;
