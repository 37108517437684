import { SvgIcon } from "../../components/atoms/SvgIcon";
import ButtonList from "../../components/molecules/ButtonList";
import Navbar from "../../components/organisms/Navbar";
import { useNavigate } from "react-router-dom";
import { useCustomers } from "../../contexts/customerContext";
import { Button, LoadingView } from "../../components";
import { Fragment, useState } from "react";
import GeneralModal from "../../components/molecules/GeneralModal";
import { CustomerApi } from "../../apis/customer.api";

export const ProfilePage = () => {
  const navigate = useNavigate();
  const { currentUser, onLogOut } = useCustomers();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const goToEditPaymentMethod = ({ label }: any) => {
    if (label === "Payment Method") {
      navigate("/profile/edit-payment-method");
    }
    if (label === "Account Info") {
      navigate("/profile/edit");
    }
    if (label === "Closet") {
      navigate("/profile/closet/history");
    }
  };

  const handleDeleteProfile = async () => {
    try {
      setLoading(true);

      await CustomerApi.putDelete();

      onLogOut();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <div className="flex flex-col items-center w-screen h-screen bg-homeBg px-4.5">
        <p className="text-lg font-bold mt-15 mb-14">My Profile</p>
        <div className="flex flex-col items-center">
          <p className="text-xl font-bold mt-5">{currentUser?.fullName}</p>
          <p className="text-sm font-normal opacity-70 mt-1">
            {currentUser?.email}
          </p>
        </div>
        <div className="w-full bg-white mt-12 rounded-small">
          <ButtonList
            list={[
              { icon: "payment", label: "Payment Method" },
              { icon: "closet", label: "Closet" },
              { icon: "info", label: "Account Info" },
            ]}
            onClick={goToEditPaymentMethod}
          />
        </div>

        <div
          className="flex justify-center items-center gap-3.5 mt-6 text-red-logout"
          onClick={onLogOut}
        >
          <SvgIcon name="logout" />
          <p className="capitalize">Log Out</p>
        </div>

        <Button
          contained
          fullWidth
          className="mt-6"
          onClick={() => setShowConfirmModal(true)}
        >
          Delete Customer Profile
        </Button>

        <Navbar />
      </div>

      {showConfirmModal && (
        <GeneralModal
          type="confirm"
          mainText="Delete Customer profile"
          actionButtons={[
            <Button onClick={() => setShowConfirmModal(false)}>Cancel</Button>,
            <Button contained onClick={handleDeleteProfile}>
              Confirm
            </Button>,
          ]}
        >
          <div>
            <p className="text-brandTag font-bold text-center text-grayText mb-4">
              Are you sure that you want to delete your profile?
            </p>

            <p className="text-xs text-center text-grayText">
              This action cannot be undone.
            </p>
          </div>
        </GeneralModal>
      )}

      <LoadingView show={isLoading} />
    </Fragment>
  );
};
