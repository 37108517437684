import React, { useState } from 'react';
import { SvgIcon } from '../../components/atoms/SvgIcon';
import QuoteStepper from '../../components/molecules/QuoteStepper';
import Button from '../../components/atoms/Button';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import AfterPhotos from './AfterPhotos';
import SubmitOrder from './SubmitOrder';
import ConfirmShippingAddress from './ConfirmShippingAddress';

const steps = [
  { title: 'Send your item', timeLog: 'Saturday, 4 Dec, 2021' },
  { title: 'Package in Transit', timeLog: 'Saturday, 4 Dec, 2021' },
  { title: 'Package Received', timeLog: 'By Saturday, 5 Dec, 2021' },
  { title: 'Service in Progress', timeLog: 'By Saturday, 5 Dec, 2021' },
  { title: 'Order Shipped', timeLog: 'By Saturday, 5 Dec, 2021' },
  { title: 'Order Received', timeLog: 'By Saturday, 5 Dec, 2021' },
];

const stepsForApproveWork = [
  { title: 'Send your item', timeLog: 'Saturday, 4 Dec, 2021' },
  { title: 'Package in Transit', timeLog: 'Saturday, 4 Dec, 2021' },
  { title: 'Package Received', timeLog: 'By Saturday, 5 Dec, 2021' },
  { title: 'Service in Progress', timeLog: 'By Saturday, 5 Dec, 2021' },
  { title: 'Approve Work', timeLog: 'By Saturday, 5 Dec, 2021' },
  { title: 'Order Received', timeLog: 'By Saturday, 5 Dec, 2021' },
];

const trackSteps = [
  { },
  { status: 'Send your item' },
  { status: 'In Transit', buttonLabel: 'Print My Label' },
  { status: 'Received', buttonLabel: 'Track my order' },
  { status: 'In Progress', buttonLabel: 'Track my order' },
  { status: 'Approval Pend', buttonLabel: 'Review After Photos' },
  { status: 'Completed', buttonLabel: 'Print My Label' },
];

const TrackOrder = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(2);
  const [file, setFile] = useState<any>();
  const [openAfterPhotos, setOpenAfterPhotos] = useState<boolean>(false);
  const [openSubmit, setOpenSubmit] = useState<boolean>(false);
  const [openConfirmAddress, setOpenConfirmAddress] = useState<boolean>(false);

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': []
    },
    multiple: false,
    onDrop: (acceptedFiles: File[]) => {
      setFile(Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0])
      }));
    }
  });

  const handlePrev = () => {
    if (step === 1) {
      navigate(-1);
    } else {
      setStep(prev => prev - 1);
    }
  }

  const handleNextStep = () => {
    if (step === 5) {
      setOpenAfterPhotos(true);
    } else {
      setStep(prev => prev + 1);
    }
  }

  const handleBack = () => {
    setOpenAfterPhotos(false);
    setOpenSubmit(false);
  }

  const handleConfirmAddress = () => {
    if (openAfterPhotos) {
      setOpenAfterPhotos(false);
    }
    if (openConfirmAddress) {
      setOpenConfirmAddress(false);
    }
    setStep(prev => prev + 1);
  }

  const handleApproveAndRequest = () => {
    if (step === 5) {
      setOpenConfirmAddress(true);
    } else {
      setOpenSubmit(true);
    }
  }

  return (
    <div className="relative flex flex-col h-screen bg-homeBg">
      <div className="pt-15 pb-5 flex justify-between items-center px-3.75">
        <SvgIcon name="chevron-left" onClick={handlePrev} />
        <h2 className="text-lg font-bold">Order #TC003</h2>
        <div />
      </div>
      <div className="flex-1 flex flex-col gap-3 overflow-y-auto px-3.75 py-5">
        <div className="flex gap-3.5 bg-white p-5 rounded-xl">
          <div className="flex justify-center items-center min-w-9.5 h-9.5 rounded-primary bg-helperText">
            <SvgIcon name="truck" className="text-white" />
          </div>
          <div className="relative">
            <p className="font-semibold">Bag/Louis Vuitton</p>
            <p className="text-xs font-semibold text-gray-800">4 Dec, 2021<span>•</span>$440.00</p>
            <div className="absolute top-0 right-0 bg-notification px-2 py-1 text-xs font-semibold text-primary rounded-small">
              {trackSteps[step].status}
            </div>
            <div className="border-b border-pinBorder my-5" />
            <div className="grid grid-cols-2 gap-2">
              <div className="relative border rounded-lg">
                <div className="absolute z-10 top-2 left-2 px-1.5 py-0.5 text-2xs text-white font-semibold bg-grayText rounded">
                  {step < 4 ? 'Quote' : 'Before'}
                </div>
                <img
                  src="/images/rouis_bag.png"
                  alt="before"
                  className="rounded-lg"
                  style={{
                    filter: "grayscale(1)"
                  }}
                />
              </div>
              {file ? (
                <div className="relative flex justify-center items-center bg-dndBg border border-borderPrimary border-dashed rounded-lg">
                  <div className="absolute z-10 top-2 left-2 px-1.5 py-0.5 text-2xs text-white font-semibold bg-grayText rounded">
                    After
                  </div>
                  <img
                    src={file.preview}
                    alt="before"
                    className="rounded-lg"
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                  />
                </div>
              ) : (
                <section className="relative bg-dndBg border border-borderPrimary border-dashed rounded-lg">
                  <div {...getRootProps({className: 'w-full h-full flex justify-center items-center'})}>
                    <input {...getInputProps()} />
                    <p className="text-2xs font-semibold opacity-60 capitalize">Not Uploaded Yet</p>
                  </div>
                  <div className="absolute z-10 top-2 left-2 px-1.5 py-0.5 text-2xs text-grayText font-semibold bg-tagBg rounded">
                    {step < 4 ? 'Before' : 'After'}
                  </div>
                </section>
              )}
            </div>
            <div className="relative flex justify-between items-center my-5 gap-2.5">
              {step === 6 ? (
                <>
                  <Button
                    contained
                    className="!text-sm !px-6.25"
                  >
                    Leave a Review
                  </Button>
                  <Button className="relative !pl-10 !pr-6.25">
                    <SvgIcon name="instagram" className="absolute top-1/2 left-3.75 -translate-y-1/2 w-4.5 h-4.5" />
                    Share
                    <p className="absolute -top-5 left-1/2 -translate-x-1/2 text-xs text-helperText whitespace-nowrap">
                      COMING SOON
                    </p>
                  </Button>
                </>
              ) : (
                <Button
                  contained={step < 5}
                  fullWidth
                  className={clsx(
                    '!px-6.25 text-sm',
                    { 'border-primary !text-primary': step === 5 }
                  )}
                  onClick={handleNextStep}
                >
                  {trackSteps[step].buttonLabel}
                </Button>                       
              )}
              
            </div>
          </div>
        </div>
        <div className="mt-5">
          <p className="text-2xs text-gray-750 uppercase mb-2">Artisan info:</p>
          <div className="flex justify-between items-center p-3 bg-white rounded-primary">
            <div className="flex gap-2.5">
              <div className="w-9.5 h-9.5">
                <img src="/images/kristy.png" alt="kristy" />
              </div>
              <div>
                <div className="flex items-center gap-1.5 text-sm font-bold">
                  <p>Kristy P.</p>
                  <SvgIcon name="star" className="w-3 text-primary" />
                  <span className="text-[11px] font-semibold text-primary">4.9 (231 Reviews)</span>
                </div>
                <p className="text-[11px] font-semibold opacity-60">
                  Boutique Tailor
                  <span className="font-medium"> • </span>
                  <span>2.4k Jobs</span>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-1.5 pl-2 pr-3 py-2 bg-pinBorder rounded-md">
              <SvgIcon name="inbox" className="w-3 h-3" />
              <p className="text-xs font-semibold">Chat</p>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <p className="text-2xs text-gray-750 uppercase mb-2">ORDER STATUS:</p>
          <div className="px-3.75 py-5 bg-white rounded-primary">
            <QuoteStepper
              steps={step === 5 ? stepsForApproveWork : steps}
              activeStep={step}
            />
          </div>
        </div>
      </div>
      {openAfterPhotos && (
        <AfterPhotos
          onBack={handleBack}     
          onConfirmAddress={handleConfirmAddress}
        />
      )}
      {openSubmit && (
        <SubmitOrder onBack={handleBack} />
      )}
      {openConfirmAddress && (
        <div className="fixed top-0 left-0 z-20 w-screen h-screen bg-backdrop flex flex-col justify-end">
          <ConfirmShippingAddress
            onClose={() => setOpenConfirmAddress(false)}
            onConfirmAddress={handleConfirmAddress}
          />
        </div>
      )}
      {step > 4 && (
        <div className="flex justify-between items-center gap-4 px-4 py-4 bg-white shadow-footer">
          <div>
            <p className="text-xs opacity-50 mb-1">Total cost:</p>
            <p className="text-2xl font-extrabold">$200.00</p>
          </div>
          <Button
            fullWidth
            contained
            onClick={handleApproveAndRequest}
            className="!text-brandTag"
          >
            {step === 5 ? 'Approve & pay balance' : 'Request a new quote'}
          </Button>
        </div>
      )}
    </div>
  );
}

export default TrackOrder;