import React, { useEffect, useState } from "react";
import { SvgIcon } from "../../components/atoms/SvgIcon";
import { useNavigate } from "react-router-dom";
import { CustomerApi } from "../../apis/customer.api";
import clsx from "clsx";
import _ from "lodash";
import GeneralModal from "../../components/molecules/GeneralModal";
import { Button } from "../../components";
import { getPlatform } from "../../utils/getPlatform";


export const ClosetHistory = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState<any[]>([]);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const handlePrev = () => {
    navigate(-1);
  };


  const goToDetail = (product: any) => {
    navigate(`/profile/closet/history/${product.id}/${product.activeLeadId}`);
  };

  useEffect(() => {
    CustomerApi.getCustomerItems().then((res) => {
      setProducts(res);
    });
  }, []);

  return (
    <>
      <div className="flex flex-col h-screen bg-homeBg">
        <div className="pt-15 pb-5 flex justify-between items-center px-3.75">
          <SvgIcon name="chevron-left" onClick={handlePrev} />
          <h2 className="text-lg font-bold">Closet</h2>
          <SvgIcon name="three-dots" />
        </div>

        <div className="border-b border-b-borderPrimary px-3.75">
          <div className="border-b border-b-primary pb-3 text-sm font-semibold text-primary w-fit">
            Service History
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-3 overflow-y-auto px-3.75 py-5">
          {products.map((item, index: number) => {
            const activeLeadCurrentWorkflowStep = _.get(
              item,
              "activeLeadCurrentWorkflowStep",
              -1
            );
            const isShowMoreDisabled = activeLeadCurrentWorkflowStep < 3;

            return (
              <div
                key={index}
                className="flex gap-4.5 bg-white rounded-lg p-3.25"
              >
                <div className="flex justify-center items-center w-19 h-19 rounded-md bg-gray-500 bg-opacity-10">
                  <img
                    className="w-19 h-19 rounded-md object-cover"
                    src={item.mainPictureUrl}
                    alt={item.mainPictureUrl}
                  />
                </div>
                <div className="flex-1">
                  <div className="flex justify-between items-center mb-3">
                    <span className="text-2xs text-helperText border border-helperText px-2 pt-1.25 pb-1 leading-none rounded-small">
                      {item.activeLeadCurrentWorkflowStepName ||
                        "Quote Received"}
                    </span>
                    <p
                      className={clsx(
                        "text-lg font-bold text-primary",
                        !item.activeLeadTotalAmount && "hidden"
                      )}
                    >
                      ${(item.activeLeadTotalAmount || 0).toFixed(2)}
                    </p>
                  </div>
                  <p className="text-brandTag font-semibold mb-2">
                    {item.name}
                  </p>
                  <div className="flex items-center h-4 text-xs font-semibold">
                    {item.itemNumber || ""}
                  </div>
                  <p className="text-xs font-semibold opacity-60 mt-1">
                    {item.brand}
                  </p>
                  <div className="border-b opacity-50 mt-4" />
                  <div className="flex justify-end pt-4">
                    <div
                      className={clsx(
                        "flex items-center",
                        isShowMoreDisabled && "invisible"
                      )}
                      onClick={() => !isShowMoreDisabled && goToDetail(item)}
                    >
                      <p className="text-2xs">Show more</p>
                      <SvgIcon name="chevron-right" className="w-3 h-3" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {showDownloadModal && (
        <GeneralModal
          mainText="Download the craftyz App"
          helperText="To view your quote and get in touch with the artisan, please download our App"
          onClose={() => setShowDownloadModal(false)}
          actionButtons={[
            <Button
              fullWidth
              contained
              onClick={() =>
                window.open(
                  `https://deeplink.thecobblers.com/downloadcustomerapp?platform=${getPlatform()}`,
                  "_blank"
                )
              }
            >
              Download
            </Button>,
          ]}
        />
      )}
    </>
  );
};
