import { FC, useEffect } from "react";
import { Button } from "../../atoms";
import { useAtom } from "jotai";
import { funnelDisabledAtom, newProductDetailsAtom } from "../../../jotai";

interface QuoteFunnelItemValueProps {
  setDisabled?: (disabled: boolean) => void;
}

const intentMap = {
  1: "Planning To Resell",
  2: "Sentimental Value",
  3: "Daily Use",
};

const QuoteFunnelItemValue: FC<QuoteFunnelItemValueProps> = ({
  setDisabled,
}) => {
  const [productDetail, setProductDetail] = useAtom(newProductDetailsAtom);
  const [_, setFunnelDisabled] = useAtom(funnelDisabledAtom);
  const intent = productDetail?.indicatedIntent;

  useEffect(() => {
    window.location.hash = "submit-intent";
  }, []);
  useEffect(() => {
    if (productDetail.indicatedIntent <= 0) setFunnelDisabled(true);
    else setFunnelDisabled(false);
  }, [productDetail]);

  const handleIntent = (intentID: number) => {
    setProductDetail({ ...productDetail, indicatedIntent: intentID });
    setDisabled && setDisabled(false);
  };

  return (
    <div className="flex flex-col gap-2 text-center">
      {Object.entries(intentMap).map(([id, currIntent]) => (
        <Button
          key={id}
          contained={intent === Number(id)}
          className="flex-1 !font-medium"
          onClick={() => handleIntent(Number(id))}
        >
          {currIntent}
        </Button>
      ))}
    </div>
  );
};

export default QuoteFunnelItemValue;
