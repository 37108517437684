import clsx from 'clsx';
import { SvgIcon } from '../../atoms/SvgIcon';

type Props = {
  image: string;
  label: string;
  className?: string;
  onClick?: () => void;
};

const ImageButton = ({ image, label, className, onClick }: Props) => {
  return (
    <div className={clsx('w-42.5', className)}>
      <div className="flex justify-center items-center w-full h-27.5 rounded-tr-small rounded-tl-small bg-gradient-to-r from-darkGray to-brightGray">
        <img className="drop-shadow-image w-20" src={`/images/${image}`} alt="bag" />
      </div>
      <div
        className="flex items-center justify-between w-full h-13.5 p-3 bg-white rounded-br-small rounded-bl-small cursor-pointer"
        onClick={onClick}
      >
        <p className="text-xs font-semibold text-blue">{label}</p>
        <SvgIcon name="chevron-right" className='text-blue' />
      </div>
    </div>
  );
};

export default ImageButton;
