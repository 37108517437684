import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import * as stripeJs from "@stripe/stripe-js";
import { PaymentMethodResult } from "@stripe/stripe-js/types/stripe-js/stripe";
export interface StripeInputRef {
  addPaymentMethod: () => any;
}

const StripeInput: ForwardRefRenderFunction<StripeInputRef> = (_, ref) => {
  const stripe = useStripe();
  const elements = useElements();

  useImperativeHandle(ref, () => ({
    addPaymentMethod: async () => {
      if (!stripe || !elements) return;
      const card = elements.getElement(CardElement);

      if (!card) return;

      const result: PaymentMethodResult = await stripe.createPaymentMethod({
        type: "card",
        card,
      });

      console.log("result: ", result);

      return result;
    },
  }));

  const handleCardChange = (event: stripeJs.StripeCardElementChangeEvent) => {
    if (event.error) {
      // Show `event.error.message` in the payment form.
    }
  };

  return <CardElement onChange={handleCardChange} />;
};

export default forwardRef(StripeInput);
