import clsx from 'clsx';
import { MouseEvent, ReactNode } from 'react';

type Props = {
  isOpen?: boolean;
  className?: string;
  onClose?: () => void;
  children: ReactNode | ReactNode[];
};

export const Modal = ({
  isOpen = true,
  className,
  onClose,
  children,
}: Props) => {

  if (!isOpen) {
    return <></>;
  }
  
  const stopPropagation = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }
  
  const handleClose = () => {
    onClose && onClose();
  }
  
  return (
    <div
      className="fixed top-0 left-0 flex items-center justify-center bg-backdrop w-screen h-screen px-5 z-20"
      onClick={handleClose}
    >
      <div
        className={clsx(
          'px-6.5 py-7.5 w-full rounded-medium bg-white',
          className,
        )}
        onClick={stopPropagation}
      >
        <div>
          {children}
        </div>
      </div>
    </div>
  );
};
