import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "../pages/HomePage";
import ToDoPage from "../pages/ToDoPage";
import LoginPage from "../pages/LoginPage";
import {
  ProfilePage,
  EditPaymentMethod,
  EditProfile,
  ClosetHistory,
  CloseItemDetail,
  Closet,
  ReviewAfterPhotos,
} from "../pages/ProfilePage";

import ApprovedQuoteDetailsPage from "../pages/QuoteDetailsPage/ApprovedQuoteDetailsPage";
import QuoteDetailPrintPage from "../pages/QuoteDetailsPage/QuoteDetailPrintPage";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import TrackOrder from "../pages/OrderPage";
import SubmitQuotePage from "../pages/SubmitQuotePage";
import SubmitPersonalnfoPage from "../pages/SubmitPersonalnfoPage";
import SubmitResult from "../pages/SubmitResult";

const RootRouter = () => {
  return (
    <Routes>
      <Route
        path="/otp-login"
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route path="/" element={<HomePage />} />
      <Route path="/submit-quote" element={<SubmitQuotePage />} />
      <Route path="/submit-personal-info" element={<SubmitPersonalnfoPage />} />
      <Route path="/submit-result" element={<SubmitResult />} />
      <Route path="/to-do" element={<ToDoPage />} />
      <Route path="/details/print" element={<QuoteDetailPrintPage />} />
      <Route path="/details" element={<ApprovedQuoteDetailsPage />} />
      <Route
        path="/profile/edit"
        element={
          <PrivateRoute>
            <EditProfile />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile/edit-payment-method"
        element={
          <PrivateRoute>
            <EditPaymentMethod />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile/review-after-photos/item/:itemId/lead/:leadId"
        element={
          <PrivateRoute>
            <ReviewAfterPhotos />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile/closet/history/:itemId/:leadId"
        element={
          <PrivateRoute>
            <CloseItemDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile/closet/history"
        element={
          <PrivateRoute>
            <ClosetHistory />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile/closet"
        element={
          <PrivateRoute>
            <Closet />
          </PrivateRoute>
        }
      />
      <Route path="/track-order" element={<TrackOrder />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default RootRouter;
