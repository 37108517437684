import { FC, Fragment } from "react";
import { HistoryStep } from "../../types";
import clsx from "clsx";
import { FiCheck, FiClock } from "react-icons/fi";
import { format } from "date-fns";

type QuoteStepsProps = {
  steps: HistoryStep[];
};

export const QuoteSteps: FC<QuoteStepsProps> = ({ steps }) => {
  return (
    <div className="flex space-x-3">
      <div className="flex flex-col items-center py-5">
        {new Array(steps.length).fill("").map((_, index: number) => (
          <Fragment key={index}>
            {index > 0 && (
              <div
                className={clsx(
                  "w-0.5 h-11.5 border-none my-1",
                  steps[index].isComplete ? "bg-blue" : "bg-gray-600"
                )}
              />
            )}
            {steps[index].isComplete ? (
              <div className="w-7 h-7 rounded-full bg-blue flex items-center justify-center">
                <FiCheck size={16} color="#FFF" />
              </div>
            ) : (
              <div className="w-7 h-7 min-w-7 min-h-7 bg-gray-750 rounded-full" />
            )}
          </Fragment>
        ))}
      </div>
      <div className="flex-1 flex flex-col divide-y divide-gray-300">
        {steps.map((step: HistoryStep, index: number) => (
          <div key={index} className="py-5">
            <p
              className={clsx(
                "text-quoteStatus font-bold capitalize",
                step.isComplete ? "text-blue" : "text-gray-750"
              )}
            >
              {step.title}
            </p>
            <div className="flex items-center mt-2 gap-1 text-gray-750">
              <FiClock size={16} />
              <p className="text-2xs">
                {!!step.dateTime &&
                  format(new Date(step.dateTime), "d MMM, h:mm a")}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
