import { Modal } from "../../atoms/Modal";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import * as stripeJs from "@stripe/stripe-js";
import { PaymentMethodResult } from "@stripe/stripe-js/types/stripe-js/stripe";
import Button from "../../atoms/Button";
import { CustomerApi } from "../../../apis/customer.api";
import { FC, useState } from "react";
import { STRIPE_PUBLIC_KEY } from "../../../constants";

const stripe = loadStripe(
  STRIPE_PUBLIC_KEY
);

type AddPaymentMethodDialogProps = {
  isOpen?: boolean;
  onClose: () => void;
  onAfterAdded: (...args: any) => void;
};

type CheckoutFormProps = {
  onClose: () => void;
  onAfterAdded: (...args: any) => void;
};

const CheckoutForm: FC<CheckoutFormProps> = ({
  onClose = () => null,
  onAfterAdded = () => null,
}) => {
  const [isLoading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) return;
    const card = elements.getElement(CardElement);

    if (!card) return;

    setLoading(true);

    const result: PaymentMethodResult = await stripe.createPaymentMethod({
      type: "card",
      card,
      // billing_details: {
      //   name: "Jenny Rosen",
      // },
    });

    console.log("result>>", result);

    try {
      if (result.error) {
        // An error happened when collecting card details,
        // show `result.error.message` in the payment form.
      } else {
        // Otherwise send paymentMethod.id to your server (see Step 3)
        const res = await CustomerApi.addPaymentMethod({
          token: result.paymentMethod.id,
        });

        console.log("res", res);

        CustomerApi.getPaymentMethods().then((res) => {
          onAfterAdded(res);
          onClose();
        });
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  const handleCardChange = (event: stripeJs.StripeCardElementChangeEvent) => {
    if (event.error) {
      // Show `event.error.message` in the payment form.
    }
  };

  return (
    <form>
      <h1 className="text-xl mb-4 text-center font-semibold">
        Add Payment Card
      </h1>
      <CardElement onChange={handleCardChange} />
      <div className="mt-5 flex justify-center">
        <Button
          contained
          disabled={!stripe || isLoading}
          className="!font-medium"
          onClick={handleSubmit}
        >
          {isLoading ? "Loading ..." : "Submit Payment"}
        </Button>
      </div>
    </form>
  );
};

export const AddPaymentMethodDialog = ({
  isOpen,
  onClose,
  onAfterAdded,
}: AddPaymentMethodDialogProps) => {
  const appearance = {
    theme: "stripe",
  } as const;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Elements stripe={stripe} options={{ appearance }}>
        <CheckoutForm onClose={onClose} onAfterAdded={onAfterAdded} />
      </Elements>
    </Modal>
  );
};

export default AddPaymentMethodDialog;
