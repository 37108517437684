import { useState, useCallback } from "react";
import { SvgIcon } from "../../atoms/SvgIcon";
import { useDropzone } from "react-dropzone";
import { BlobServiceClient } from "@azure/storage-blob";
import { v4 as uuidv4 } from "uuid";
import { useCustomers } from "../../../contexts/customerContext";
import { useSetAtom, useAtom } from "jotai";
import { imagesAtom } from "../../../jotai/quote.jotai";
import { AZURE_BLOB_ACCOUNT_NAME } from "../../../constants";
import Resizer from "react-image-file-resizer";


const resizeFile = (file: File): Promise<Blob> =>
  new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      2000,
      2000,
      "JPEG",
      60,
      0,
      (value) => {
        // Check if the value is a Blob
        if (value instanceof Blob) {
          resolve(value);
        } else {
          // If not a Blob, reject the promise
          reject(new Error("Expected a Blob, but received a different type"));
        }
      },
      "blob",
    );
  });



export type GalleryProps = {
  image?: string;
  title: string;
  description?: string;
  onChange: (value: string) => void;
  onRemove: () => void;
};

export const Gallery = ({
  image,
  title,
  description,
  onChange,
  onRemove,
}: GalleryProps) => {
  const { sasToken, leadId } = useCustomers();
  const [url, setUrl] = useState("");
  const images = useAtom(imagesAtom);
  const setImages = useSetAtom(imagesAtom);
  const containerName = "leadimages/" + leadId;

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length <= 0) {
        console.error("No files were uploaded");
        return;
      }

      const file = acceptedFiles[0];
      const ext = file.name.split(".").pop()?.toLowerCase();
      let fileToUpload = file;

      if (ext === "heic" || ext === "heif") {
        const heic2any = (await import("heic2any")).default;
        const arrayBuffer = await file.arrayBuffer();
        const blob = new Blob([arrayBuffer], { type: file.type });
        const output = await heic2any({
          blob,
          toType: "image/jpeg",
          quality: 0.7,
        });
        const outputBlob = Array.isArray(output) ? output[0] : output;

        fileToUpload = new File([outputBlob], file.name.replace(/\.[^/.]+$/, "") + ".jpeg", {
          type: 'image/jpeg',
          lastModified: new Date().getTime()
        });
      } else if (ext === "jpg" || ext === "jpeg") {
        const resizedBlob = await resizeFile(file);
        fileToUpload = new File([resizedBlob], file.name, {
          type: resizedBlob.type,
          lastModified: new Date().getTime()
        });
      }

      // Set the preview for the processed file
      const preview = URL.createObjectURL(fileToUpload);
      setUrl(preview);
      setImages([...images[0], preview]);

      // Upload process
      const extension = fileToUpload.name.split(".").pop();
      const blobName = uuidv4() + "." + extension;

      const blobServiceClient = new BlobServiceClient(
        `https://${AZURE_BLOB_ACCOUNT_NAME}.blob.core.windows.net?${sasToken}`
      );
      const containerClient = blobServiceClient.getContainerClient(containerName);
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      const uploadBlobResponse = await blockBlobClient.uploadBrowserData(fileToUpload, {
        blobHTTPHeaders: { blobContentType: fileToUpload.type },
      });

      onChange(
        `https://${AZURE_BLOB_ACCOUNT_NAME}.blob.core.windows.net/${containerName}/${blobName}`
      );
    },
    [leadId, sasToken, images]
  );


  const handleRemove = () => {
    setUrl("");
    onRemove();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
    multiple: false,
  });

  return (
    <div className="max-w-image-card">
      <div
        className="w-full h-38 border-[1.5px] border-dashed border-gray-400 flex items-center justify-center rounded-2"
        {...getRootProps()}
      >
        {image || url ? (
          <img
            src={image || url}
            className="w-full h-full rounded-2 object-cover"
          />
        ) : (
          <SvgIcon name="camera" className="text-gray-700" />
        )}
        <input {...getInputProps()} />
      </div>
      <div className="mt-2 flex items-center justify-between">
        <div>
          <h6 className="text-xs font-medium">{title}</h6>
          <p className="text-xxs">{description || "-"}</p>
        </div>
        {image || url ? (
          <div
            className="h-5.5 w-5.5 flex items-center justify-center rounded-full shadow-sm cursor-pointer"
            onClick={handleRemove}
          >
            <SvgIcon name="trash" className="w-3 h-3 text-alert" />
          </div>
        ) : (
          <div className="h-5.5 w-5.5 flex items-center justify-center rounded-full border-2 border-[#1111110A]"></div>
        )}
      </div>
    </div>
  );
};
