import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useSetAtom, useAtomValue, useAtom } from "jotai";
import {
  productDetailsAtom,
  selectedBrandAtom,
  stepAtom,
} from "../../../jotai/quote.jotai";
import { Gallery } from "../../../components/molecules/Gallery";
import Checkbox from "../../../components/atoms/Checkbox";
import { StepFooter } from "./StepFooter";
import { Perspective } from "../../../types/metadata";
import { PerspectivePicture } from "../../../types";
import { CustomerApi } from "../../../apis/customer.api";
import { useCustomers } from "../../../contexts/customerContext";
import _ from "lodash";
import GeneralModal from "../../../components/molecules/GeneralModal";
import { Button } from "../../../components";

const generalServices = [
  "Repair (Straps/Restitching/Replace)",
  "Surface/Edge Treatment (Painting / Refinish)",
  "Cleaning",
  "Not Sure?",
];

const ProductDetails = () => {
  const selectedBrand = useAtomValue(selectedBrandAtom);
  const [selectedHints, setSelectedHints] = useState<number[]>([]);
  const [notes, setNotes] = useState("");
  const setStep = useSetAtom(stepAtom);
  const [pictures, setPictures] = useState<PerspectivePicture[]>([]);
  const { leadId } = useCustomers();
  const [productDetails, setProductDetails] = useAtom(productDetailsAtom);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    setPictures(_.get(productDetails, "pictures", []));
  }, []);

  const onSelectHint = (index: number) => {
    if (selectedHints.includes(index)) {
      setSelectedHints(selectedHints.filter((item) => item !== index));
    } else {
      setSelectedHints([...selectedHints, index]);
    }
  };

  const handleContinue = () => {
    if (!leadId || !selectedBrand?.products[0]?.id) return;

    setProductDetails({
      serviceHints: selectedHints,
      note: notes,
      pictures,
      productId: selectedBrand?.products[0]?.id,
    });
    setStep(2);
  };

  const onSubmitProduct = () => {
    if (
      selectedBrand?.products[0]?.picturePerspectives.length !== pictures.length
    ) {
      setShowConfirmModal(true);
      return;
    }

    handleContinue();
  };

  const onChangePerspective = useCallback(
    (perspective: Perspective, url: string) => {
      setPictures((prev) => {
        const isExist = prev.find(
          (item) => item.perspectiveId === perspective.id
        );
        if (isExist) {
          return prev.map((item) =>
            item.perspectiveId === perspective.id
              ? {
                  url,
                  perspectiveId: perspective.id,
                }
              : item
          );
        }
        return [
          ...prev,
          {
            url,
            perspectiveId: perspective.id,
          },
        ];
      });
    },
    [pictures]
  );

  const getImageUrl = (perspectiveId: number) => {
    return pictures.find(({ perspectiveId: _pId }) => _pId === perspectiveId)
      ?.url;
  };

  const handleRemoveImage = (perspectiveId: number) => {
    setPictures((pictures) =>
      pictures.filter(({ perspectiveId: _pId }) => _pId !== perspectiveId)
    );
  };

  return (
    <Fragment>
      <div className="h-175 max-h-quote-popup overflow-y-auto pb-36">
        <div>
          <p className="font-semibold mb-3">Upload Images</p>
          <div className="grid grid-cols-3 gap-4 w-full">
            {selectedBrand?.products[0]?.picturePerspectives?.map(
              (perspective, index) => (
                <Gallery
                  key={index}
                  image={getImageUrl(perspective.id)}
                  title={perspective.name}
                  description={perspective.description}
                  onChange={(url) => onChangePerspective(perspective, url)}
                  onRemove={() => handleRemoveImage(perspective.id)}
                />
              )
            )}
          </div>
        </div>
        {/* <div className="mt-5">
        <p className="font-semibold mb-3">
          What general services are you looking for?
        </p>
        <div className="flex flex-col gap-2">
          {generalServices.map((service, index) => (
            <Checkbox
              key={index}
              label={service}
              onChange={() => onSelectHint(index)}
              checked={selectedHints.includes(index)}
            />
          ))}
        </div>
      </div> */}
        <div className="mt-5">
          <p className="font-semibold mb-3">Tell us more about your item</p>
          <textarea
            placeholder="Type Here..."
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="w-full h-26 resize-none p-3 border border-borderPrimary text-xs placeholder-textPrimary rounded-lg"
          />
        </div>
        <StepFooter
          disabledNext={_.isEmpty(pictures)}
          onNext={onSubmitProduct}
        />
      </div>

      {showConfirmModal && (
        <GeneralModal
          type="alert"
          mainText="Not all Images uploaded"
          helperText="More Images help to expedite and provide a more accurate quote. Are you sure you want to send your request without all images?"
          actionButtons={[
            <Button contained onClick={handleContinue}>
              Confirm
            </Button>,
            <Button onClick={() => setShowConfirmModal(false)}>Cancel</Button>,
          ]}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </Fragment>
  );
};

export default ProductDetails;
