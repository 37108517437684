import { MouseEvent, useEffect, useState } from "react";
import PhoneNumberInput from "../../components/molecules/PhoneNumberInput";
import Button from "../../components/atoms/Button";
import GeneralModal from "../../components/molecules/GeneralModal";
import { CustomerApi } from "../../apis/customer.api";
import { useNavigate } from "react-router-dom";
import { ACCESS_TOKEN } from "../../constants";
import { useCustomers } from "../../contexts/customerContext";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import ReactCodeInput from "react-verification-code-input";
import styled from "styled-components";
import { CraftyzLogo } from "../../components";

const OtpCodeInput = styled(ReactCodeInput)((props) => ({
  minWidth: 340,

  input: {
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: 28,
    fontWeight: 700,
    letterSpacing: -0.1,
    color: "#111",
    borderRadius: 8,
    border: "1.5px solid rgba(17, 17, 17, 0.06)",
    marginLeft: 4,
    marginRight: 4,
    fontFamily: "Manrope",

    "&:first-child": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      marginLeft: 0,
    },

    "&:last-child": {
      borderRight: "1.5px solid rgba(17, 17, 17, 0.06)",
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      marginRight: 0,
    },

    "&:focus": {
      border: "1.5px solid #4CAC96",
      caretColor: "#4CAC96",

      "& + input": {
        borderLeft: "1.5px solid rgba(17, 17, 17, 0.06)",
      },
    },
  },
}));

const LoginPage = () => {
  const [timerKey, setTimerKey] = useState(new Date().getTime());
  const [sendClicked, setSendClicked] = useState<boolean>(false);
  const [verifyClicked, setVerifyClicked] = useState<boolean>(false);
  const [mobileNumber, setMobileNumber] = useState<string>();
  const [otpCode, setOtpCode] = useState<string>("");
  const navigate = useNavigate();
  const { fetchMe } = useCustomers();

  useEffect(() => {
    const mobileNumber = localStorage.getItem("cobbler_lead_mobile");

    if (mobileNumber) setMobileNumber(mobileNumber);
  }, []);

  const handleVerify = () => {
    CustomerApi.completeOTP({
      mobileNumber,
      otp: otpCode,
    }).then((res) => {
      setSendClicked(false);
      setVerifyClicked(true);

      if (res.jwt) {
        localStorage.setItem(ACCESS_TOKEN, res.jwt);
        fetchMe(() => navigate("/profile"));
      }
    });
  };

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const sendOTPCode = () => {
    CustomerApi.startOTP({
      mobileNumber,
    }).then((res) => {
      setSendClicked(true);
    });
  };

  const handleResend = () => {
    setOtpCode("");
    setTimerKey(new Date().getTime());
  };

  return (
    <div className="relative flex flex-col items-center">
      <div className="flex flex-col justify-end items-center text-center w-full h-88 bg-loginBg">
        <CraftyzLogo />

        <div className="-mt-8">
          <img src="/images/login_cobblers.png" alt="craftyz" />
        </div>
      </div>
      <div className="px-6.25 pt-10">
        <h1 className="text-pin font-semibold">OTP Verification Login</h1>
        <p className="text-brandTag">
          Please enter your phone number to receive a verification code.
        </p>
        <div className="mt-9 flex flex-col gap-3">
          <PhoneNumberInput value={mobileNumber} onChange={setMobileNumber} />
          <Button contained onClick={sendOTPCode}>
            Send OTP
          </Button>
          <Button onClick={() => navigate("/")}>Cancel</Button>
        </div>
      </div>
      {sendClicked && (
        <div
          className="absolute top-0 left-0 w-screen h-screen flex flex-col justify-end items-center bg-backdrop"
          onClick={() => setSendClicked(false)}
        >
          <div
            className="flex flex-col bg-white w-full px-5 py-6.25 rounded-t-2xl"
            onClick={handleClick}
          >
            <CountdownCircleTimer
              key={timerKey}
              isPlaying
              size={65}
              strokeWidth={4}
              duration={60}
              rotation="counterclockwise"
              colors="#D69188"
              onComplete={() => ({ shouldRepeat: false, delay: 1 })}
            >
              {({ remainingTime }) => <span>{remainingTime}</span>}
            </CountdownCircleTimer>

            <p className="mt-4.5 text-lg font-extrabold">
              Please Check Your Inbox
            </p>
            <div className="flex justify-between items-center text-brandTag">
              <p className="text-grayText">
                We've sent a code to{" "}
                <span className="font-bold">+1{mobileNumber}</span>
              </p>
              <p className="underline" onClick={() => setSendClicked(false)}>
                Edit
              </p>
            </div>
            <div className="mt-10">
              <OtpCodeInput
                onChange={setOtpCode}
                fieldWidth={50}
                fieldHeight={70}
              />
              <div className="flex justify-center items-center text-sm mt-4.5 gap-1">
                <p className="text-grayText">Didn't get a code?</p>
                <p className="underline" onClick={handleResend}>
                  Click to resend
                </p>
              </div>
            </div>
            <div className="mt-16 flex justify-between items-center gap-3">
              <Button fullWidth onClick={() => setSendClicked(false)}>
                Cancel
              </Button>
              <Button contained fullWidth onClick={handleVerify}>
                Verify
              </Button>
            </div>
          </div>
        </div>
      )}
      {verifyClicked && (
        <GeneralModal
          mainText="OTP Login Verification Completed Successfully."
          helperText="Your redirection is in progress...."
          onClose={() => {
            setVerifyClicked(false);
            navigate("/profile");
          }}
        />
      )}
    </div>
  );
};

export default LoginPage;
