import React from "react";
import { useNavigate } from "react-router-dom";
import { CraftyzLogo, SvgIcon } from "../../components/atoms/SvgIcon";
import Button from "../../components/atoms/Button";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  imagesAtom,
  productDetailsAtom,
  selectedBrandAtom,
  serviceAtom,
} from "../../jotai/quote.jotai";
import { CustomerApi } from "../../apis/customer.api";
import { useCustomers } from "../../contexts/customerContext";
import { getUTMPayload } from "../../utils";

export type QuoteresultProps = {
  name?: string;
  brand?: string;
};

const QuoteResult = ({ name, brand }: QuoteresultProps) => {
  const navigate = useNavigate();
  const images = useAtom(imagesAtom);
  const { setUserId, setLeadId, leadName, setLeadName } = useCustomers();
  const setSelectedBrand = useSetAtom(selectedBrandAtom);
  const setService = useSetAtom(serviceAtom);
  const productDetails = useAtomValue(productDetailsAtom);

  const goToMyAccount = async () => {
    try {
      localStorage.removeItem("cobbler_user_id");
      localStorage.removeItem("cobbler_lead_id");

      const user = await CustomerApi.getUserGuid(getUTMPayload());
      const lead = await CustomerApi.getLeadId(user.guid, getUTMPayload());

      localStorage.setItem("cobbler_user_id", user.guid);
      localStorage.setItem("cobbler_lead_id", lead.guid);
      localStorage.setItem("cobbler_lead_name", lead.name);

      setLeadId(lead.guid);
      setLeadName(lead.name);
      setUserId(user.guid);

      setSelectedBrand(null);
      setService("");

      navigate("/otp-login");
    } catch (error) {}
  };

  const getCuttedLeadNumber = (leadNumber: string) => {
    if (leadNumber.length > 8) {
      return leadNumber.slice(0, 8) + "...";
    }
    return leadNumber;
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex flex-col z-20">
      <div className="flex flex-col justify-between w-full h-88 bg-loginBg px-6.25 py-10">
        <CraftyzLogo />

        <div className="mt-7">
          <SvgIcon name="tick-circle" className="text-textPrimary" />
          <p className="mt-3">Hi {name},</p>
        </div>
        <h1 className="text-pin font-semibold capitalize max-w-[330px]">
          Thank you for requesting your quote.
        </h1>
      </div>
      <div className="flex-1 flex flex-col justify-between bg-homeBg px-6.25 pt-7 pb-10">
        <div>
          <div>
            <p className="text-xs font-semibold opacity-50 mb-1">Quote Info:</p>
            <p className="text-sm">
              Your quote {getCuttedLeadNumber(leadName || "")} is in progress,
              we will send you an update when you have been matched with an
              artisan and your quote is ready.
            </p>
          </div>
          <div className="mt-7">
            <div className="flex justify-between items-center text-xs mb-2">
              <p className="font-semibold opacity-50">Product info:</p>
              <p className="text-primary font-semibold italic">
                Quote Requested
              </p>
            </div>
            <div className="flex p-3 rounded bg-white gap-3">
              <div className="w-20 h-full">
                <img
                  src={images[0][0]}
                  alt="quote"
                  className="w-full h-20"
                  width="100%"
                  height="100%"
                />
              </div>
              <div className="flex-1 flex flex-col justify-between">
                <p className="text-sm font-bold">{brand}</p>
                <div>
                  <p className="text-[11px] font-semibold opacity-60">
                    Description
                  </p>
                  <p className="text-[13px] font-semibold">
                    {productDetails?.note || ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          fullWidth
          contained
          onClick={goToMyAccount}
          className="!text-[15px]"
        >
          Go To My Account
        </Button>
      </div>
    </div>
  );
};

export default QuoteResult;
