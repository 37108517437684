import clsx from "clsx";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { SvgIcon } from "../../atoms/SvgIcon";

type Props = {
  className?: string;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  prefixNumber?: string;
  value?: string;
};
const PhoneNumberInput = ({
  className,
  onChange,
  readOnly = false,
  prefixNumber = "450",
  value,
}: Props) => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  useEffect(() => {
    if (value && phoneNumber !== value) {
      setPhoneNumber(value);
    }
  }, [value]);

  const handleChange = (event: BaseSyntheticEvent) => {
    setPhoneNumber(event.target.value);
    onChange && onChange(event.target.value.replaceAll(/[^0-9]/g, ""));
  };

  return (
    <div
      className={clsx(
        "flex gap-3.75 items-center py-2.5 px-3.75 h-13 rounded-primary border bg-white",
        className
      )}
    >
      <SvgIcon name="phone" />
      <div className="h-full w-0.25 bg-borderPrimary" />
      <input
        value={phoneNumber}
        onChange={handleChange}
        className={clsx("outline-none w-full")}
        readOnly={readOnly}
        placeholder={`(${prefixNumber}) `}
      />
    </div>
  );
};

export default PhoneNumberInput;
