import React from 'react';
import { SvgIcon } from '../../components/atoms/SvgIcon';
import Button from '../../components/atoms/Button';
import QuoteStepper from '../../components/molecules/QuoteStepper';
import { useLocation, useNavigate } from 'react-router-dom';

const ApprovedQuoteDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goPrintPage = () => {
    const path = location.pathname;
    if (path[path.length - 1] === '/') {
      navigate(path + 'print');
    } else {
      navigate(path + '/print');
    }
  }

  return (
    <div className="h-screen bg-homeBg pt-10 pb-28">
      <div className="flex justify-between items-center px-4 pb-4 border-b border-b-borderPrimary">
        <SvgIcon name="chevron-left" />
        <p className="text-lg font-bold capitalize">Order #tC003</p>
        <div />
      </div>
      <div className="flex flex-col gap-5 px-4 mt-5">
        <div>
          <p className="text-2xs text-gray-750 uppercase mb-2">Quote:</p>
          <div className="flex gap-3 p-3 rounded-lg bg-white">
            <div className="flex justify-center items-center w-19 h-19 rounded bg-gray-500 bg-opacity-10">
              <img src="/images/shoe2.png" alt="" className="w-15" />
            </div>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <p className="text-sm font-bold capitalize w-43 truncate">
                  Women Shoes dolor sit amet
                </p>
                <p className="text-m-xs text-gray-750">13 Dec, 2022</p>
              </div>
              <p className="text-xs font-semibold text-primary mt-1">Christian Louboutin</p>
              <div className="flex justify-between items-center mt-2">
                <p className="text-m-xs text-gray-750">Quote #TC0031</p>
                <p className="text-2xs text-white font-normal px-2.5 py-1.25 bg-textPrimary rounded-md">
                  Quote Approved
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="text-2xs text-gray-750 uppercase mb-2">Artisan info:</p>
          <div className="flex justify-between items-center p-3 gap-2.5 bg-white rounded-lg">
            <div className="flex gap-2.5">
              <div className="w-9.5 h-9.5 bg-gray-200 rounded-full">
                <img src="/images/kristy.png" alt="kristy" />
              </div>
              <div>
                <p className="font-bold">Jim S.</p>
                <div className="flex items-center">
                  <SvgIcon name="star" className="w-3 text-primary" />
                  <p className="text-m-xs opacity-60 ml-1">1.5k restorations completed</p>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-1 pl-2 pr-3 py-1 rounded-md bg-pinBorder">
              <SvgIcon name="inbox" width={12} height={12} />
              <p className="text-xs font-semibold">Chat</p>
            </div>
          </div>
        </div>
        <div>
          <p className="text-2xs text-gray-750 uppercase mb-2">Quote Status:</p>
          <div className="px-4 py-1 bg-white rounded-lg">
            <QuoteStepper
              steps={[
                { title: 'Quote requested', timeLog: '10 Apr, 12:33 PM', },
                { title: 'Quote Received', timeLog: '10 Apr, 12:33 PM', },
                { title: 'Quote Approved', timeLog: '10 Apr, 12:33 PM', },
              ]}
              activeStep={3}
            />
          </div>
        </div>
        <p className="text-xs text-grayText w-full mt-6 mb-3 px-1 text-center">
          Important: Once approved, your quote is guaranteed for a period of 14 days. To secure the quoted price and artisan availability, we kindly request that you ship your items promptly.
        </p>
        <div className="flex flex-col items-center">
          <Button
            contained
            className="w-67 text-sm"
            onClick={goPrintPage}
          >
            Print My Label
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ApprovedQuoteDetailsPage;
