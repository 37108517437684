import { useCallback, useEffect, useState } from "react";
import { ArrowLeft2 } from "iconsax-react";
import { Navigate, useNavigate } from "react-router-dom";
import { productDetailsAtom, selectedBrandAtom, selectedMetadataAtom } from "../jotai";
import { useAtom, useAtomValue } from "jotai";
import { Gallery } from "../components/molecules/Gallery";
import { PerspectivePicture } from "../types";
import { Perspective } from "../types/metadata";
import Stepper from "../components/atoms/Stepper";
import { Button } from "../components";
import _ from "lodash";
import { useCustomers } from "../contexts";
import GeneralModal from "../components/molecules/GeneralModal";
import BrandMenu from "../components/quote/BrandMenu";

export default () => {
  const navigate = useNavigate();
  const selectedMetadata = useAtomValue(selectedMetadataAtom);
  const [selectedBrand] = useAtom(selectedBrandAtom);
  const [pictures, setPictures] = useState<PerspectivePicture[]>([]);
  const [notes, setNotes] = useState("");
  const { leadId } = useCustomers();
  const [productDetails, setProductDetails] = useAtom(productDetailsAtom);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const getImageUrl = (perspectiveId: number) => {
    return pictures.find(({ perspectiveId: _pId }) => _pId === perspectiveId)?.url;
  };

  const handleRemoveImage = (perspectiveId: number) => {
    setPictures((pictures) => pictures.filter(({ perspectiveId: _pId }) => _pId !== perspectiveId));
  };

  const onChangePerspective = useCallback(
    (perspective: Perspective, url: string) => {
      setPictures((prev) => {
        const isExist = prev.find((item) => item.perspectiveId === perspective.id);
        if (isExist) {
          return prev.map((item) =>
            item.perspectiveId === perspective.id
              ? {
                  url,
                  perspectiveId: perspective.id,
                }
              : item
          );
        }
        return [
          ...prev,
          {
            url,
            perspectiveId: perspective.id,
          },
        ];
      });
    },
    [pictures]
  );

  const handleClickNext = () => {
    if (!leadId || !selectedBrand?.products[0]?.id) return;

    setProductDetails({
      serviceHints: [],
      note: notes,
      pictures,
      productId: selectedBrand?.products[0]?.id,
    });

    navigate("/submit-personal-info");
  };

  useEffect(() => {
    setPictures(_.get(productDetails, "pictures", []));
    setNotes(_.get(productDetails, "note", ""));
  }, []);

  if (!selectedMetadata) return <Navigate to="/" replace />;

  return (
    <div className="p-4">
      <div className="flex items-center capitalize text-lg font-extrabold text-blue mb-3">
        <button className="mr-2" onClick={() => setShowCancelModal(true)}>
          <ArrowLeft2 size={20} className="text-blue" />
        </button>
        Submit your Quote
      </div>

      <div className="text-[#838C92] text-xs font-medium mb-3">
        Fill out your product details and get a connected with an artisan within 24-48 hours.
      </div>

      <div className="font-bold text-blue mb-3">Select Your Brand</div>

      <BrandMenu />

      <div className="mt-6">
        <div className="flex items-end font-semibold mb-3">
          <div className="text-blue">Upload Images&nbsp;</div>
          <div className="text-xs text-rose">&nbsp;(At least one)</div>
        </div>
        <div className="grid grid-cols-3 gap-4 w-full">
          {selectedBrand?.products[0]?.picturePerspectives?.map((perspective, index) => (
            <Gallery
              key={index}
              image={getImageUrl(perspective.id)}
              title={perspective.name}
              description={perspective.description}
              onChange={(url) => onChangePerspective(perspective, url)}
              onRemove={() => handleRemoveImage(perspective.id)}
            />
          ))}
        </div>
      </div>
      <div className="my-6">
        <div className="flex items-end font-semibold mb-3">
          <div className="text-blue capitalize">What are you looking to fix?&nbsp;</div>
          <div className="text-xs text-rose">&nbsp;(required)</div>
        </div>

        <textarea
          placeholder="Type Here..."
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          className="w-full h-26 resize-none p-3 border border-borderPrimary placeholder-silver-2 rounded-lg"
        />
      </div>

      <div className="h-[110px]" />

      <div className="fixed left-0 bottom-0 w-full z-10 p-5 bg-white shadow-footer">
        <div className="flex justify-center">
          <Stepper stepsCount={3} activeStep={1} />
        </div>
        <div className="mt-5 flex justify-between items-center gap-2.5">
          <Button onClick={() => setShowCancelModal(true)} className="!px-9">
            Back
          </Button>
          <Button
            contained
            fullWidth
            onClick={() => handleClickNext()}
            type="submit"
            disabled={_.isEmpty(pictures) || _.isEmpty(notes)}
          >
            Continue
          </Button>
        </div>
      </div>

      {showCancelModal && (
        <GeneralModal
          type="alert"
          mainText="Are you sure you want to cancel your quote request?"
          helperText="If you click ‘Confirm’ you will lose all of your information"
          actionButtons={[
            <Button
              contained
              fullWidth
              onClick={() => {
                setShowCancelModal(false);
                navigate(-1);
              }}
            >
              Confirm
            </Button>,
            <Button onClick={() => setShowCancelModal(false)}>Cancel</Button>,
          ]}
          onClose={() => setShowCancelModal(false)}
        />
      )}
    </div>
  );
};
