import React, { useEffect, useState } from "react";
import { useAtom, useSetAtom } from "jotai";
import SelectYourBrand from "./steps/SelectYourBrand";
import SelectServiceType from "./steps/SelectServiceType";
import ProductDetails from "./steps/ProductDetails";
import PersonalInformation from "./steps/PersonalInformation";
import {
  selectedBrandAtom,
  selectedMetadataAtom,
  serviceAtom,
  stepAtom,
} from "../../jotai/quote.jotai";
import Button from "../../components/atoms/Button";
import GeneralModal from "../../components/molecules/GeneralModal";
import { useScrollLock } from "../../hooks";

type SubmitQuoteProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SubmitQuote = ({ isOpen, onClose }: SubmitQuoteProps) => {
  const [step, setStep] = useAtom(stepAtom);
  const setSelectedMetadata = useSetAtom(selectedMetadataAtom);
  const setSelectedBrand = useSetAtom(selectedBrandAtom);
  const setService = useSetAtom(serviceAtom);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  useEffect(() => {
    setStep(0);
  }, []);

  useScrollLock(true);

  const handleClose = () => {
    setConfirmDelete(true);
  };

  const handleConfirm = () => {
    setConfirmDelete(false);
    setStep(0);
    setSelectedMetadata(null);
    setSelectedBrand(null);
    setService("");
    onClose();
  };

  const headers = [
    "Select Your Brand",
    // "Select service Type",
    "Product details",
    "Personal Information",
  ];

  const steps = [
    <SelectYourBrand />,
    // <SelectServiceType />,
    <ProductDetails />,
    <PersonalInformation />,
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 bg-backdrop flex flex-col justify-end">
      <div className="w-full h-[95vh] max-h-screen bg-white px-5 py-6 rounded-t-large flex flex-col">
        <div className="flex justify-between items-center mb-5">
          <p className="text-lg font-extrabold capitalize">{headers[step]}</p>
          <p
            className="text-4xl font-normal text-backdrop"
            onClick={handleClose}
          >
            &times;
          </p>
        </div>
        {step === 2 && (
          <p className="-mt-5 mb-10 text-xs text-grayText max-w-[300px]">
            Please provide us your information. This will help us to match you
            with an expert artisan.
          </p>
        )}
        {steps[step]}
      </div>
      {confirmDelete && (
        <GeneralModal
          type="alert"
          mainText="Are you sure you want to cancel your quote request?"
          helperText="If you click “Confirm”, you will lose all of your information."
          actionButtons={[
            <Button contained onClick={handleConfirm}>
              Confirm
            </Button>,
            <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>,
          ]}
          onClose={() => setConfirmDelete(false)}
        />
      )}
    </div>
  );
};

export default SubmitQuote;
