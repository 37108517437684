import { FC, useState } from "react";
import { Picture } from "../../../types";
import { QuoteDetail } from "./QuoteDetail";
import ReviewQuote from "./ReviewQuote";
import QuoteConfirmationPage from "../../../components/quote/QuoteConfirmationPage";
import {
  ILeadItem,
  ISalesOrder,
} from "../../../components/quote/interfaces/LeadItem";
import NextStepsAboutPage from "../../../components/quote/NextStepsAboutPage";

type DefaultClosetItemProps = {
  leadItem: ILeadItem;
  pictures: Picture[];
};

export const DefaultClosetItem: FC<DefaultClosetItemProps> = ({
  leadItem,
  pictures,
}) => {
  const [selectedSalesOrder, setSelectedSalesOrder] = useState<ISalesOrder>({});
  const [activeQuoteState, setActiveQuoteState] = useState<number>(0);

  switch (activeQuoteState) {
    case 1:
      return (
        <ReviewQuote
          leadItem={leadItem}
          pictures={pictures}
          setActiveQuoteState={setActiveQuoteState}
          selectedSalesOrder={selectedSalesOrder}
          setSelectedSalesOrder={setSelectedSalesOrder}
        />
      );
    case 2:
      return (
        <QuoteConfirmationPage
          leadItem={leadItem}
          setActiveQuoteState={setActiveQuoteState}
        />
      );
    case 3:
      return (
        <NextStepsAboutPage
          selectedSalesOrder={selectedSalesOrder}
          leadItem={leadItem}
          artisanName={leadItem?.artisanName}
          createDate={leadItem?.createDate}
          setActiveQuoteState={setActiveQuoteState}
        />
      );
    default:
      return (
        <QuoteDetail
          leadItem={leadItem}
          pictures={pictures}
          setActiveQuoteState={setActiveQuoteState}
        />
      );
  }
};
