import { FC, useState } from "react";
import { SvgIcon } from "../../atoms/SvgIcon";
import _ from "lodash";
import { AddressModal } from "./AddressModal";
import { Address } from "../../../types";

type AddressButtonProps = {
  name?: string;
  value?: any;
  placeholder?: string;
  onBlur?: (...args: any) => void;
  onChange?: (...args: any) => void;
  onChangeValue: (...args: any) => void;
};

export const AddressButton: FC<AddressButtonProps> = (props) => {
  const [showModal, setShowModal] = useState(false);

  const handleChange = (address: Address) => {
    props.onChangeValue(address);

    if (typeof props.onChange === "function") {
      props.onChange(
        `${address.street}, ${address.city}, ${address.stateCode} ${address.zip}, ${address.countryName}`
      );
    }
  };

  return (
    <>
      <div
        className="flex border border-[#9DA4AF15] rounded-2.5 py-2.5 px-4 bg-white cursor-pointer"
        onClick={() => setShowModal(true)}
      >
        <div className="flex items-center border-r-[1.5px] pr-3.75">
          <SvgIcon name="location-outlined" />
        </div>

        {!props.value && (
          <div className="h-[30px] leading-[30px] flex-1 overflow-hidden pl-4 w-full text-sm text-gray-700">
            {props.placeholder}
          </div>
        )}

        <div className="h-[30px] leading-[30px] flex-1 overflow-hidden pl-4 w-full text-sm font-semibold">
          {props.value}
        </div>

        <div className="flex items-center pl-3.75">
          <SvgIcon name="chevron-right" />
        </div>
      </div>

      <AddressModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onChange={handleChange}
        defaultValue={props.value}
      />
    </>
  );
};
