import React from "react";
import { IoMdCheckmark } from "react-icons/io";
import { CraftyzLogo } from "../atoms";
import { ILeadItem } from "./interfaces/LeadItem";
import { SetStateAction } from "jotai";

interface IQuoteConfirmationPage {
  leadItem: ILeadItem;
  setActiveQuoteState: React.Dispatch<SetStateAction<number>>;
}
const QuoteConfirmationPage: React.FC<IQuoteConfirmationPage> = ({
  leadItem,
  setActiveQuoteState,
}) => {
  return (
    <>
      <div className="px-4 pt-4">
        <div className="mb-14">
          <CraftyzLogo />
        </div>
        <div className="flex flex-col text-blue font-semibold text-sm">
          <IoMdCheckmark className="w-10 h-auto text-white p-2.5 bg-blue rounded-full " />
          <p className="mt-3 text-base">Hi, {leadItem?.artisanName}. </p>

          <p className="mt-14 text-3xl leading-normal">
            We Have Notified Your <br /> Artistan!
          </p>
        </div>
      </div>
      <div className="w-full h-auto bg-gray-100 px-4 py-10  mt-16">
        <p className="text-gray-750">Quote Info: </p>
        <p className="text-blue mt-3 font-medium">
          Your additional quote for order #{leadItem?.id} is in progress,we will
          send you an update when your artisan has sent you a final quote.
        </p>
        <div className="flex justify-between font-semibold mt-12 mb-3">
          <p className="text-semibold text-gray-750">Product Info</p>
          <p className="text-brightPrimary italic font-medium">
            Quote Requested
          </p>
        </div>
        <div className="bg-white rounded-md h-auto px-2.5 py-3 flex gap-3">
          <img
            src={leadItem?.coverPictureUrl}
            alt="img"
            className="w-16 rounded-lg border"
          />
          <div className="flex flex-col space-y-3 text-sm">
            <p className="text-blue text-base font-semibold">
              {" "}
              {leadItem?.productName}
            </p>
            <div>
              <p className="text-gray-800 font-semibold">Description:</p>
              <p className="text-gray-750 mt-0.5">{leadItem?.notes}</p>
            </div>
          </div>
        </div>

        <div className="flex justify-center mt-16 mb-3 font-semibold cursor-pointer">
          <button
            onClick={() =>setActiveQuoteState(3)}
            className=" bg-blue w-[70%] py-1.5 rounded-md text-primary"
          >
            Go to Conversation
          </button>
        </div>
      </div>
    </>
  );
};

export default QuoteConfirmationPage;
