import React, { useState } from "react";
import { NavigationBar } from "../../../components";
import { Picture } from "../../../types";
import QuoteDetailsSwipper from "../../../components/quote/QuoteDetailsSwipper";
import { SetStateAction } from "jotai";
import ArtisianInfo from "./ArtisianInfo";
import {
  ILeadItem,
  ISalesOrder,
} from "../../../components/quote/interfaces/LeadItem";

interface IReviewQuote {
  leadItem: ILeadItem;
  pictures?: Picture[];
  setActiveQuoteState: React.Dispatch<SetStateAction<number>>;
  selectedSalesOrder: ISalesOrder;
  setSelectedSalesOrder: React.Dispatch<SetStateAction<ISalesOrder>>;
}
const ReviewQuote: React.FC<IReviewQuote> = ({
  leadItem,
  pictures,
  setActiveQuoteState,
  selectedSalesOrder,
  setSelectedSalesOrder,
}) => {
  const [showDeclineModal, setShowDeclineModal] = useState<boolean>(false);
  const [selectedReason, setSelectedReason] = useState<any>(null);
  const [isConfirmDeclined, setIsConfirmDeclined] = useState<boolean>(false);

  const declineReasons = [
    "Price Too High",
    "Changed my mind",
    "Looking for somewhere else",
    "Going somewhere else for restoration",
  ];

  const handleCheckboxChange = (index: any) => {
    setSelectedReason(index === selectedReason ? null : index);
  };
  const handleConfirmDeclineModel = () => {
    setIsConfirmDeclined(true);
    setShowDeclineModal(false);
  };

  return (
    <div className="px-4 pt-4">
      <NavigationBar
        headingText="Review Quote Options"
        showDeclinePopUp={setShowDeclineModal}
      />
      <div className="flex flex-col pb-3">
        <ArtisianInfo leadItem={leadItem} />

        <div className="bg-white p-3 rounded-lg h-auto flex mt-4">
          <div className="mr-3">
            <img
              src={leadItem?.coverPictureUrl}
              alt="img"
              className="w-16 rounded-lg border"
            />
          </div>
          <div className="flex flex-col text-blue font-medium">
            <p className="w-[80%] py-0.5 mb-1.5 text-orange-300 border capitalize border-orange-300 text-xs rounded-md text-center">
              {leadItem?.currentWorkflowStepName}
            </p>
            <p className="font-semibold text-sm mb-1.5">
              {leadItem?.productName}
            </p>
            <p className="text-sm">{leadItem?.productBrandName}</p>
          </div>
        </div>
      </div>

      {/*Decline Model */}
      {showDeclineModal && (
        <div className="fixed top-0 left-0 bottom-0 right-0 bg-backdrop flex flex-col justify-end z-50">
          <div className="w-[98%] mr-[2%] h-auto bg-white px-5 py-6 rounded-t-large flex flex-col">
            <div className="flex justify-between items-center mb-2">
              <div className="text-lg font-extrabold capitalize">
                Decline Reason?
              </div>

              <div
                className="text-4xl font-normal text-backdrop cursor-pointer"
                onClick={() => setShowDeclineModal(false)}
              >
                &times;
              </div>
            </div>

            <ul className="">
              {declineReasons?.map((reason, i) => (
                <li className="capitalize mb-4" key={i}>
                  <input
                    type="checkbox"
                    className="w-6 h-auto stroke-white text-white accent-brightPrimary"
                    id={`checkbox-${i}`}
                    checked={i === selectedReason}
                    onChange={() => handleCheckboxChange(i)}
                  />
                  <label
                    htmlFor={`checkbox-${i}`}
                    className={`ml-6 text-sm ${
                      i === selectedReason ? "text-black" : "text-gray-750"
                    }`}
                  >
                    {reason}
                  </label>
                </li>
              ))}
            </ul>
            <button
              onClick={() => handleConfirmDeclineModel()}
              className="w-[60%] mx-auto font-semibold text-lg text-red-500 border rounded-md border-brightPrimary py-2 my-3"
            >
              Decline
            </button>
          </div>
        </div>
      )}

      {/*Confirm Decline Model */}
      {isConfirmDeclined && (
        <div className="fixed top-0 left-0 bottom-0 right-0 bg-backdrop flex flex-col justify-end z-50">
          <div className="w-[95%] mx-auto h-auto bg-white px-5 py-6 pb-10 rounded-t-large flex flex-col">
            <div className="text-lg font-bold text-center capitalize">
              Do You Want to Request a 4th Option?
            </div>

            <div className="mx-auto mt-16 font-semibold cursor-pointer">
              <button
                onClick={() => setIsConfirmDeclined(false)}
                className="w-30 py-1.5 mr-2 text-base text-red-500 border rounded-md border-brightPrimary"
              >
                No, Decline
              </button>
              <button
                onClick={() => setActiveQuoteState(2)}
                className="bg-blue w-30 py-1.5 rounded-md text-primary"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      <QuoteDetailsSwipper
        leadItem={leadItem}
        pictures={pictures}
        saleOrders={leadItem?.saleOrders}
        setActiveQuoteState={setActiveQuoteState}
        selectedSalesOrder={selectedSalesOrder}
        setSelectedSalesOrder={setSelectedSalesOrder}
      />
    </div>
  );
};

export default ReviewQuote;
