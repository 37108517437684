import React from 'react'
import { SvgIcon } from '../../../components';

const ArtisianInfo: React.FC<{leadItem: any}> = ( {leadItem}) => {
  return (
    <>
        <p className="text-2xs text-gray-750 uppercase mb-2">Artisan info:</p>
        <div className="bg-white">
          <div className="flex justify-between items-center p-3 gap-2.5 bg-white rounded-lg">
            <div className="flex gap-2.5">
              <div className="w-9.5 h-9.5 bg-gray-200 rounded-full">
                <img src="/images/kristy.png" alt="kristy" />
              </div>
              <div className="flex flex-col space-y-0.5">
                <div className="flex gap-0.5 text-sm items-center">
                  <p className="font-bold text-blue mr-1">
                    {leadItem?.artisanName}
                  </p>
                  <SvgIcon name="star" className="w-3 text-primary" />
                  <p className="text-xs">{`${leadItem?.artisanRating}`}</p>
                </div>
                <div className="flex gap-2 text-xs items-center text-blue">
                  <p>{leadItem?.artisanCompanyName}</p>
                  <p>{leadItem?.artisanJobsCount} Jobs</p>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-1 pl-2 pr-3 py-1 rounded-md bg-pinBorder">
              <SvgIcon name="inbox" width={12} height={12} />
              <p className="text-xs font-semibold">Chat</p>
            </div>
          </div>
        </div>
      </>
  )
}

export default ArtisianInfo;