import { useAtom } from "jotai";
import { FC, useEffect } from "react";
import { funnelDisabledAtom, newProductDetailsAtom } from "../../../jotai";

interface QuoteFunnelItemFixProps {
  setDisabled?: (disabled: boolean) => void;
}

const QuoteFunnelItemFix: FC<QuoteFunnelItemFixProps> = ({ setDisabled }) => {
  const [productDetail, setProductDetail] = useAtom(newProductDetailsAtom);
  const [_, setFunnelDisabled] = useAtom(funnelDisabledAtom);

  const notes = productDetail?.note;
  useEffect(() => {
    window.location.hash = "submit-notes";
  }, []);
  useEffect(() => {
    if (!productDetail.note) setFunnelDisabled(true);
    else setFunnelDisabled(false);
  }, [productDetail]);

  return (
    <textarea
      placeholder="Do your steps need replacement? Maybe your zipper is broken. Tell us here..."
      value={notes}
      onChange={(e) => {
        setProductDetail({ ...productDetail, note: e.target.value });
        setDisabled && setDisabled(false);
      }}
      className="w-full h-26 resize-none p-3 border border-borderPrimary placeholder-silver-2 rounded-lg"
    />
  );
};

export default QuoteFunnelItemFix;
