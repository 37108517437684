import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  NavigationBar,
  Button,
  LoadingView,
} from "../../components";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { CustomerApi } from "../../apis/customer.api";
import { Lead, OrderLine, Picture } from "../../types";
import { FaTimes } from "react-icons/fa";
import _ from "lodash";
import { useCustomers } from "../../contexts";

export const ReviewAfterPhotos = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [photos, setPhotos] = useState<Picture[]>([]);
  const [selectedPhoto, setSelectedPhoto] = useState<Picture | null>(null);
  const [leadItem, setLeadItem] = useState<Lead>();
  const { itemId, leadId } = useParams();
  const { currentUser } = useCustomers();
  const activePaymentMethodId = _.get(
    currentUser,
    "activePaymentMethodId",
    null
  );
  const defaultShippingAddressId = _.get(
    currentUser,
    "defaultShippingAddressId",
    null
  );

  useEffect(() => {
    if (!itemId || !leadId) return;

    CustomerApi.getCustomerItemDetail(itemId, leadId).then(setLeadItem);

    CustomerApi.getCustomerItemPictures(itemId, leadId, {
      filterType: "qualitycontrol",
    }).then(setPhotos);
  }, [itemId, leadId]);

  const orderLines: OrderLine[] = _.get(
    leadItem,
    "activeSalesOrder.orderLines",
    []
  );

  const handleApprove = async () => {
    try {
      setLoading(true);

      const acceptedSaleOrderId = leadItem.acceptedSaleOrder?.id;

      await CustomerApi.putApproveQC(
        leadItem.itemId,
        leadItem.id,
        acceptedSaleOrderId,
        {
          saleOrderId: acceptedSaleOrderId,
          paymentMethodId: activePaymentMethodId,
          addressId: defaultShippingAddressId,
        }
      );
    } catch (error) {
    } finally {
      setLoading(false);
      navigate(-2);
    }
  };

  const renderPopover = () => {
    if (!selectedPhoto) return null;

    const orderLine = orderLines.find(
      ({ pictureId }) => pictureId === selectedPhoto.id
    );

    return (
      <div className="fixed top-0 left-0 bottom-0 right-0 bg-backdrop flex flex-col justify-end">
        <div className="w-full h-[55vh] max-h-screen bg-white pt-4 pb-8 rounded-t-large flex flex-col">
          <div className="flex items-center justify-between px-4 py-3">
            <div className="text-textPrimary text-[1.125rem] font-bold">
              After Photos
            </div>

            <IconButton
              icon={
                <FaTimes size={24} opacity={0.3} className="text-textPrimary" />
              }
              onClick={() => setSelectedPhoto(null)}
            />
          </div>

          <div className="h-[280px] overflow-hidden bg-homeBg">
            <img
              src={selectedPhoto.url}
              alt={`${selectedPhoto.id}-photo`}
              className="w-full max-h-full h-full object-contain pointer-events-none"
            />
          </div>

          {!!orderLine && (
            <div className="flex items-center justify-between p-4">
              <div className="">
                <div className="text-xs text-textPrimary opacity-0.5 font-medium">
                  Total cost:
                </div>
                <div className="text-2xl text-textPrimary font-extrabold">
                  ${orderLine.unitPrice.toFixed(2)}
                </div>
              </div>

              <Button contained onClick={handleApprove}>
                Approve & pay balance
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <NavigationBar headingText="After Photos" showRightButton />

      <div className="p-4">
        <div className="bg-white shadow rounded-lg p-4">
          <div className="text-grayText text-2xs font-semibold uppercase mb-3">
            Click on Image to Expand
          </div>

          <ul role="list" className="grid grid-cols-3 gap-3">
            {photos.map((photo) => (
              <li
                key={photo.id}
                className="relative cursor-pointer"
                onClick={() => setSelectedPhoto(photo)}
              >
                <div
                  className={clsx(
                    "w-full h-30 rounded-primary overflow-hidden border-2",
                    selectedPhoto?.id === photo.id
                      ? "border-primary"
                      : "border-transparent"
                  )}
                >
                  <img
                    src={photo.url}
                    alt={`${photo.id}-photo`}
                    className="object-cover w-full h-full max-h-full pointer-events-none w-full h-full"
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {renderPopover()}

      <LoadingView show={isLoading} />
    </>
  );
};
