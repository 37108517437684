import React, { useState } from "react";
import { Input } from "../../../components/atoms/Input";
import { useFormik } from "formik";
import { useAtom } from "jotai";
import * as Yup from "yup";
import { InferType } from "yup";
import { StepFooter } from "./StepFooter";
import { CustomerApi } from "../../../apis/customer.api";
import { useCustomers } from "../../../contexts/customerContext";
import {
  productDetailsAtom,
  selectedBrandAtom,
  stepAtom,
} from "../../../jotai/quote.jotai";
import QuoteResult from "../QuoteResult";
import { LoadingView } from "../../../components";
import { validatePhoneNumber } from "../../../utils";

const PersonalInformation = () => {
  const [open, setOpen] = useState(false);
  const selectedBrand = useAtom(selectedBrandAtom);
  const { userId, leadId } = useCustomers();
  const [productDetails] = useAtom(productDetailsAtom);
  const [isLoading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    email: Yup.string().email("Invalid Email").required("Name is required."),
    mobile: Yup.string()
      .required("Phone number is required")
      .test(
        "is-valid-phone",
        "Invalid Phone Number. Check Country Code",
        (value) => {
          const result = validatePhoneNumber(value);
          if (!result) {
            if (!value.startsWith("+")) {
              form.setFieldValue("mobile", `+1 ${value}`);
            }
          }
          return result;
        }
      ),
    zip: Yup.string().required("Zip code is required"),
  });

  const onSubmit = async (values: InferType<typeof validationSchema>) => {
    if (!userId || !leadId || !productDetails) return;

    try {
      setLoading(true);

      localStorage.setItem("cobbler_lead_mobile", values.mobile);

      await CustomerApi.updatePersonalInformation(userId, {
        guid: userId,
        email: values.email,
        mobile: values.mobile,
        zip: values.zip,
        fullname: values.name,
      });

      await CustomerApi.updateProductDetails(leadId, productDetails);

      setOpen(true);
    } catch (err) {
      console.error("Update Personal Information::", err);
    } finally {
      setLoading(false);
    }
  };

  const form = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      zip: "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      <form onSubmit={form.handleSubmit}>
        <div className="flex flex-col gap-3">
          <Input
            icon="user"
            placeholder="Full Name"
            {...form.getFieldProps("name")}
            helperText={
              form.errors.name && form.touched.name ? form.errors.name : ""
            }
            error={!!form.errors.name && !!form.touched.name}
          />
          <Input
            icon="sms"
            placeholder="Email ID"
            {...form.getFieldProps("email")}
            helperText={
              form.errors.email && form.touched.email ? form.errors.email : ""
            }
            error={!!form.errors.email && !!form.touched.email}
          />
          <Input
            icon="phone"
            placeholder="Phone Number"
            {...form.getFieldProps("mobile")}
            helperText={
              form.errors.mobile && form.touched.mobile
                ? form.errors.mobile
                : ""
            }
            error={!!form.errors.mobile && !!form.touched.mobile}
          />
          <Input
            icon="hash-tag"
            placeholder="Zip Code (to optimise artisan matching)"
            {...form.getFieldProps("zip")}
            helperText={
              form.errors.zip && form.touched.zip ? form.errors.zip : ""
            }
            error={!!form.errors.zip && !!form.touched.zip}
          />
        </div>
        <StepFooter />
      </form>

      {open && (
        <QuoteResult name={form.values.name} brand={selectedBrand[0]?.name} />
      )}

      <LoadingView show={isLoading} />
    </div>
  );
};

export default PersonalInformation;
