import React from "react";
import { ReactComponent as BagSvg } from "./svg/bag.svg";
import { ReactComponent as BriefcaseSvg } from "./svg/briefcase.svg";
import { ReactComponent as CalendarSvg } from "./svg/calendar.svg";
import { ReactComponent as CameraSvg } from "./svg/camera.svg";
import { ReactComponent as CardSvg } from "./svg/card.svg";
import { ReactComponent as ChevronDownSvg } from "./svg/chevron-down.svg";
import { ReactComponent as ChevronLeftSvg } from "./svg/chevron-left.svg";
import { ReactComponent as ChevronRightSvg } from "./svg/chevron-right.svg";
import { ReactComponent as ChevronUpSvg } from "./svg/chevron-up.svg";
import { ReactComponent as ClockSvg } from "./svg/clock.svg";
import { ReactComponent as CloseSvg } from "./svg/close.svg";
import { ReactComponent as CloseCircleSvg } from "./svg/close-circle.svg";
import { ReactComponent as ClosetSvg } from "./svg/closet.svg";
import { ReactComponent as CrowdSvg } from "./svg/crowd.svg";
import { ReactComponent as CubeSvg } from "./svg/cube.svg";
import { ReactComponent as DollarSquareSvg } from "./svg/dollar-square.svg";
import { ReactComponent as FacebookSvg } from "./svg/facebook.svg";
import { ReactComponent as FiltersSvg } from "./svg/filters.svg";
import { ReactComponent as FollowSvg } from "./svg/follow.svg";
import { ReactComponent as GlobeSvg } from "./svg/globe.svg";
import { ReactComponent as HashTagSvg } from "./svg/hash-tag.svg";
import { ReactComponent as HomeSvg } from "./svg/home.svg";
import { ReactComponent as HomeContainedSvg } from "./svg/home-contained.svg";
import { ReactComponent as ImageSvg } from "./svg/image.svg";
import { ReactComponent as InboxSvg } from "./svg/inbox.svg";
import { ReactComponent as InfoSvg } from "./svg/info.svg";
import { ReactComponent as InstagramSvg } from "./svg/instagram.svg";
import { ReactComponent as JewellerySvg } from "./svg/jewellery.svg";
import { ReactComponent as LayerSvg } from "./svg/layer.svg";
import { ReactComponent as LocationSvg } from "./svg/location.svg";
import { ReactComponent as LocationContainedSvg } from "./svg/location-contained.svg";
import { ReactComponent as LocationOutlinedSvg } from "./svg/location-outlined.svg";
import { ReactComponent as LocationTickSvg } from "./svg/location-tick.svg";
import { ReactComponent as LogoutSvg } from "./svg/logout.svg";
import { ReactComponent as LuggageSvg } from "./svg/luggage.svg";
import { ReactComponent as ManShoesSvg } from "./svg/man-shoes.svg";
import { ReactComponent as OrdersSvg } from "./svg/orders.svg";
import { ReactComponent as PaymentSvg } from "./svg/payment.svg";
import { ReactComponent as PencilSvg } from "./svg/pencil.svg";
import { ReactComponent as PeopleSvg } from "./svg/people.svg";
import { ReactComponent as PhoneSvg } from "./svg/phone.svg";
import { ReactComponent as SearchSvg } from "./svg/search.svg";
import { ReactComponent as ShopSvg } from "./svg/shop.svg";
import { ReactComponent as SmsSvg } from "./svg/sms.svg";
import { ReactComponent as SneakersSvg } from "./svg/sneakers.svg";
import { ReactComponent as StarSvg } from "./svg/star.svg";
import { ReactComponent as StarOutlineSvg } from "./svg/star-outline.svg";
import { ReactComponent as StepSvg } from "./svg/step.svg";
import { ReactComponent as StepActiveSvg } from "./svg/step-active.svg";
import { ReactComponent as ThumbUpSvg } from "./svg/thumb-up.svg";
import { ReactComponent as ThreeDotsSvg } from "./svg/three-dots.svg";
import { ReactComponent as TickSvg } from "./svg/tick.svg";
import { ReactComponent as TickCircleSvg } from "./svg/tick-circle.svg";
import { ReactComponent as TimerSvg } from "./svg/timer.svg";
import { ReactComponent as TrashSvg } from "./svg/trash.svg";
import { ReactComponent as TruckSvg } from "./svg/truck.svg";
import { ReactComponent as UserSvg } from "./svg/user.svg";
import { ReactComponent as UserContainedSvg } from "./svg/user-contained.svg";
import { ReactComponent as WalletSvg } from "./svg/wallet.svg";
import { ReactComponent as WalletContainedSvg } from "./svg/wallet-contained.svg";
import { ReactComponent as WatchSvg } from "./svg/watch.svg";
import { ReactComponent as WarningSvg } from "./svg/warning.svg";
import { ReactComponent as WomanShoesSvg } from "./svg/woman-shoes.svg";
import { ReactComponent as CraftyzLogoSvg } from "./svg/craftyz-logo.svg";

export const iconsMap: Record<string, React.FC> = {
  bag: BagSvg,
  briefcase: BriefcaseSvg,
  calendar: CalendarSvg,
  camera: CameraSvg,
  card: CardSvg,
  "chevron-down": ChevronDownSvg,
  "chevron-left": ChevronLeftSvg,
  "chevron-right": ChevronRightSvg,
  "chevron-up": ChevronUpSvg,
  clock: ClockSvg,
  close: CloseSvg,
  "close-circle": CloseCircleSvg,
  closet: ClosetSvg,
  crowd: CrowdSvg,
  cube: CubeSvg,
  "dollar-square": DollarSquareSvg,
  facebook: FacebookSvg,
  filters: FiltersSvg,
  follow: FollowSvg,
  globe: GlobeSvg,
  "hash-tag": HashTagSvg,
  home: HomeSvg,
  "home-contained": HomeContainedSvg,
  image: ImageSvg,
  inbox: InboxSvg,
  info: InfoSvg,
  instagram: InstagramSvg,
  jewellery: JewellerySvg,
  layer: LayerSvg,
  location: LocationSvg,
  "location-contained": LocationContainedSvg,
  "location-outlined": LocationOutlinedSvg,
  "location-tick": LocationTickSvg,
  logout: LogoutSvg,
  luggage: LuggageSvg,
  "man-shoes": ManShoesSvg,
  orders: OrdersSvg,
  payment: PaymentSvg,
  pencil: PencilSvg,
  people: PeopleSvg,
  phone: PhoneSvg,
  search: SearchSvg,
  shop: ShopSvg,
  sms: SmsSvg,
  sneakers: SneakersSvg,
  star: StarSvg,
  "star-outline": StarOutlineSvg,
  step: StepSvg,
  "step-active": StepActiveSvg,
  "three-dots": ThreeDotsSvg,
  "thumb-up": ThumbUpSvg,
  tick: TickSvg,
  "tick-circle": TickCircleSvg,
  timer: TimerSvg,
  trash: TrashSvg,
  truck: TruckSvg,
  user: UserSvg,
  "user-contained": UserContainedSvg,
  wallet: WalletSvg,
  "wallet-contained": WalletContainedSvg,
  watch: WatchSvg,
  warning: WarningSvg,
  "woman-shoes": WomanShoesSvg,
};

export interface SvgIconProps {
  className?: string;
  name: string;
  onClick?: (e: any) => void;
  strokeWidth?: number;
  width?: number;
  height?: number;
}

export const SvgIcon = ({ className, name, ...others }: SvgIconProps) => {
  const Component = iconsMap[name];

  if (!Component) {
    return <div />;
  }

  return React.cloneElement(<Component data-testid="svg-icon" />, {
    className,
    ...others,
  });
};

export interface CraftyzLogoProps {
  className?: string;
  onClick?: (e: any) => void;
  strokeWidth?: number;
  width?: number;
  height?: number;
}

export const CraftyzLogo = ({
  className,
  width: initWidth,
  height: initHeight = 50,
  ...others
}: CraftyzLogoProps) => {
  let width = initWidth,
    height = initHeight;

  if (!width && initHeight) width = (initHeight * 800) / 279;
  if (!height && initWidth) height = (initWidth * 279) / 800;

  return React.cloneElement(<CraftyzLogoSvg data-testid="svg-icon" />, {
    className,
    width,
    height,
    ...others,
  });
};
