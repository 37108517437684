import { atom } from "jotai";
import { NavbarType } from "../types";

const navbarAtom = atom<NavbarType>({
  selectedButton: "Home",
});

const hideBottomBarAtom = atom<boolean>(window.localStorage.getItem("hide_bottombar") === "true");

export { navbarAtom, hideBottomBarAtom };
