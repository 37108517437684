import clsx from "clsx";
import { ReactNode, MouseEvent } from "react";

type Props = {
  children: ReactNode;
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  contained?: boolean;
  type?: "button" | "submit" | "reset";
};
export const Button = ({
  children,
  className,
  onClick,
  disabled = false,
  fullWidth = false,
  contained = false,
  type = "button",
}: Props) => {
  return (
    <button
      type={type}
      className={clsx(
        "rounded-primary outline-none px-8 py-3.5 font-semibold leading-none border-1.5",
        disabled
          ? "bg-gray-300 text-gray-400-5 cursor-not-allowed"
          : contained
          ? "bg-blue text-primary border-blue"
          : "border-borderPrimary text-blue bg-transparent",
        { "w-full": fullWidth },
        className
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
