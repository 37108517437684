import React, { useState } from 'react';
import { SvgIcon } from '../../components/atoms/SvgIcon';
import clsx from 'clsx';
import Button from '../../components/atoms/Button';
import ConfirmShippingAddress from './ConfirmShippingAddress';

const photos = [
  '/images/after_photos/photo1.png',
  '/images/after_photos/photo2.png',
  '/images/after_photos/photo3.png',
  '/images/after_photos/photo4.png',
  '/images/after_photos/photo5.png',
];

export type AfterPhotosProps = {
  onBack: () => void;
  onConfirmAddress: () => void;
}

const AfterPhotos = ({ onBack, onConfirmAddress }: AfterPhotosProps) => {
  const [focusedPhoto, setFocusedPhoto] = useState<string>();
  const [openPhotoDetail, setOpenPhotoDetail] = useState<boolean>(false);
  const [openConfirmAddress, setOpenConfirmAddress] = useState<boolean>(false);

  const handleBack = () => {
    onBack();
  }

  const handleClick = (photo: string) => {
    setFocusedPhoto(photo);
    setOpenPhotoDetail(true);
  }

  const handleClose = () => {
    setOpenPhotoDetail(false);
  }

  const handleApprove = () => {
    setOpenConfirmAddress(true);
  }

  const handleCloseConfirmAddress = () => {
    setOpenConfirmAddress(false);
  }

  const handleConfirmAddress = () => {
    onConfirmAddress();
  }
  
  return (
    <div className="absolute top-0 left-0 w-screen h-screen z-10 flex flex-col bg-homeBg px-3.75">
      <div className="pt-15 pb-5 flex justify-between items-center">
        <SvgIcon name="chevron-left" onClick={handleBack} />
        <h2 className="text-lg font-bold">After Photos</h2>
        <SvgIcon name="three-dots" />
      </div>
      <div className="flex-1 overflow-y-auto">
        <div className="bg-white p-3.75 rounded-primary">
          <p className="text-2xs text-grayText font-semibold uppercase mb-2">Click on Image to Expand</p>
          <div className="grid grid-cols-3 gap-3">
            {photos.map((photo: string, index: number) => (
              <div
                key={index}
                className={clsx(
                  'rounded-primary border-2',
                  focusedPhoto === photo ? 'border-primary' : 'border-transparent',
                )}
                onClick={() => handleClick(photo)}
              >
                <img src={photo} className="rounded-primary" />
              </div>
            ))}
          </div>
        </div>
      </div>
      {openPhotoDetail && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-backdrop flex flex-col justify-end">
          {openConfirmAddress ? (
            <ConfirmShippingAddress
              onClose={handleCloseConfirmAddress}
              onConfirmAddress={handleConfirmAddress}
            />
          ) : (
            <div className="bg-white rounded-t-large py-5">
              <div className="flex justify-between items-center px-4">
                <h2 className="text-lg font-bold">After Photos</h2>
                <p className="text-4xl font-normal opacity-30" onClick={handleClose}>&times;</p>
              </div>
              <div className="my-3 w-full h-82">
                <img src={focusedPhoto} className="w-full h-full" />
              </div>
              <div className="flex justify-between items-center gap-4 px-4">
                <div>
                  <p className="text-xs opacity-50 mb-1">Total cost:</p>
                  <p className="text-2xl font-extrabold">$200.00</p>
                </div>
                <Button
                  fullWidth
                  contained
                  onClick={handleApprove}
                  className="!text-brandTag"
                >
                  Approve & pay balance
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AfterPhotos;