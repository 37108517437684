import { atom } from "jotai";
import { Brand, Metadata } from "../types/metadata";
import { ProductDetail, ProductDetailRequest } from "../types";

const selectedMetadataAtom = atom<Metadata | null>(null);

const selectedBrandAtom = atom<Brand | null>(null);

const stepAtom = atom<number>(0);

const serviceAtom = atom<string>("");

const imagesAtom = atom<string[]>([]);

const productDetailsAtom = atom<ProductDetailRequest | null>(null);

const defaultProductDetail: ProductDetail = {
  note: "",
  indicatedIntent: 0,
  indicatedValue: 100,
  pictures: [],
  productId: 0,
  serviceHints: [],
};

const newProductDetailsAtom = atom<ProductDetail>(defaultProductDetail);

const funnelDisabledAtom = atom<boolean>(true);

export {
  selectedMetadataAtom,
  selectedBrandAtom,
  stepAtom,
  serviceAtom,
  imagesAtom,
  productDetailsAtom,
  newProductDetailsAtom,
  funnelDisabledAtom,
};
