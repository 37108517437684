import { useParams } from "react-router";
import { DefaultClosetItem } from "./DefaultClosetItem";
import { useEffect, useState } from "react";
import { Picture } from "../../../types";
import { CustomerApi } from "../../../apis/customer.api";
import _ from "lodash";
import { ToPrintLabel } from "./ToPrintLabel";
import { ApproveQuality } from "./ApproveQuality";
import { ApproveService } from "./ApproveService";

export const CloseItemDetail = () => {
  const { itemId, leadId } = useParams();
  const [leadItem, setLeadItem] = useState<any>({});
  const [pictures, setPictures] = useState<Picture[]>([]);

  useEffect(() => {
    if (!itemId || !leadId) return;

    CustomerApi.getCustomerItemDetail(itemId, leadId).then((leadItem) => {
      setLeadItem(leadItem);

      CustomerApi.getCustomerItemPictures(
        itemId,
        leadId,
        leadItem?.currentWorkflowStep === 9 ? { filterType: "qualitycontrol" } : {}
      ).then((res) => {
        setPictures(res);
      });
    });
  }, [itemId, leadId]);

  const currentWorkflowStep = _.get(leadItem, "currentWorkflowStep", -1);

  switch (currentWorkflowStep) {
    case 3:
      return <DefaultClosetItem leadItem={leadItem} pictures={pictures} />;

    case 4:
      return <ToPrintLabel leadItem={leadItem} />;

    case 5:
    case 6:
    case 7:
    case 8:
      return <ToPrintLabel leadItem={leadItem} hidePrintLabel />;

    case 9:
      return <ApproveQuality leadItem={leadItem} />;

    case 12:
      return <ApproveService leadItem={leadItem} pictures={pictures} />;

    default:
      return null;
  }
};
