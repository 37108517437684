import React from 'react';
import { SvgIcon } from '../SvgIcon';

export type StepperProps = {
  stepsCount: number;
  activeStep: number;
  onChangeStep?: (step: number) => void;
}

export const Stepper = ({ activeStep, stepsCount, onChangeStep }: StepperProps) => {
  const handleChangeStep = (step: number) => {
    onChangeStep && onChangeStep(step);
  }

  return (
    <div className="flex space-x-2 items-center">
      {Array(stepsCount).fill('').map((_, index) => index === activeStep
        ? <SvgIcon key={index} name="step-active" />
        : <SvgIcon key={index} name="step" onClick={() => handleChangeStep(index)} />)}
    </div>
  );
};

export default Stepper;
