import React, { useEffect, useState } from "react";
import QuoteContent from "./QuoteContent";
import { Picture } from "../../types";
import { ISalesOrder } from "./interfaces/LeadItem";
import { SetStateAction } from "jotai";
import { ILeadItem } from "./interfaces/LeadItem";

interface IQuoteDetailsSwipper {
  leadItem: ILeadItem;
  saleOrders?: ISalesOrder[];
  pictures?: Picture[];
  setActiveQuoteState?: React.Dispatch<SetStateAction<number>>;
  selectedSalesOrder: ISalesOrder;
  setSelectedSalesOrder: React.Dispatch<SetStateAction<ISalesOrder>>;
}

const QuoteDetailsSwipper: React.FC<IQuoteDetailsSwipper> = ({
  leadItem,
  saleOrders,
  pictures,
  setActiveQuoteState,
  selectedSalesOrder,
  setSelectedSalesOrder,
}) => {
  const [activeSlideQuote, setActiveSlideQuote] = useState(0);

  const handleClickQuoteType = (position: number) => {
    setActiveSlideQuote(position);
    if (saleOrders != null) {
      setSelectedSalesOrder(saleOrders[position]);
    }
  };

  useEffect(() => {
    if (saleOrders != null) {
      setSelectedSalesOrder(saleOrders[0]);
    }
  }, [saleOrders]);

  return (
    <div className="">
      <ul className="flex overflow-x-scroll md:overflow-hidden md:justify-center">
        {saleOrders?.map((saleOrder, i) => (
          <li key={i} onClick={() => handleClickQuoteType(i)}>
            {/*Quotes Header */}
            <div
              className={`py-4 px-8 border-b-2 ${
                i === activeSlideQuote
                  ? "border-b-2 border-b-brightPrimary"
                  : ""
              } `}
            >
              <div
                className={`flex flex-col text-center text-brandTag font-medium ${
                  i === activeSlideQuote ? "text-brightPrimary" : "text-blue"
                }`}
              >
                <p>{saleOrder.categoryName}</p>
                <p>${saleOrder.amountTotal}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {/*Quote Content */}
      {
        <QuoteContent
          leadItem={leadItem}
          selectedSalesOrder={selectedSalesOrder}
          pictures={pictures}
          setActiveQuoteState={setActiveQuoteState}
        />
      }
    </div>
  );
};

export default QuoteDetailsSwipper;
