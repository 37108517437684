import React from 'react';
import clsx from 'clsx';
import { SvgIcon } from '../../atoms/SvgIcon';

export type StepType = {
  title: string,
  timeLog?: string
}

export type QuoteStepperProps = {
  steps: StepType[];
  activeStep: number;
}

export const QuoteStepper = ({ activeStep, steps }: QuoteStepperProps) => {
  return (
    <div className="flex space-x-3">
      <div className="flex flex-col items-center py-5">
        {new Array(steps.length).fill('').map((_, index: number) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <div className={clsx(
                'w-0.5 h-11.5 border-none my-1',
                activeStep > index ? 'bg-primary' : 'bg-gray-600'
              )} />
            )}
            {activeStep > index ? (
              <SvgIcon
                name="tick-circle"
                width={28}
                height={28}
                className="text-primary"
              />
            ) : (
              <div className="w-7 h-7 min-w-7 min-h-7 bg-gray-600 rounded-full" />
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="flex-1 flex flex-col divide-y divide-borderPrimary">
        {steps.map((step: StepType, index: number) => (
          <div key={index} className="py-5">
            <p className={clsx(
              'text-quoteStatus font-bold capitalize',
              activeStep > index ? 'text-textPrimary' : 'text-gray-800'
            )}>
              {step.title}
            </p>
            <div className="flex items-center mt-2 gap-1 text-gray-800">
              <SvgIcon name="clock" width={14} />
              <p className="text-2xs">{step.timeLog || '-'}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuoteStepper;
