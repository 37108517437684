import React, { ReactNode, useEffect, useState } from "react";
import { CustomerApi } from "../apis/customer.api";
import { ACCESS_TOKEN, SAS_TOKEN } from "../constants";
import { useNavigate } from "react-router-dom";
import { User } from "../types";
import _ from "lodash";

import { datadogRum } from "@datadog/browser-rum";
import { getUTMPayload } from "../utils";

type CustomerContextType = {
  userId?: string;
  setUserId: (...args: any) => void;
  leadId?: string;
  setLeadId: (...args: any) => void;
  leadName?: string;
  setLeadName: (...args: any) => void;
  sasToken?: string;
  isAuthenticated?: boolean | null;
  currentUser?: User;
  onLogOut: () => void;
  fetchMe: (callback?: any) => void;
  address: null | {
    street: string;
    street2?: string;
    zip: number;
    stateCode: string;
    countryCode: string;
    city: string;
    countryName: string;
    stateName: string;
  };
};

const CustomerContext = React.createContext<CustomerContextType>({
  onLogOut: () => {},
  fetchMe: () => {},
  setUserId: () => {},
  setLeadId: () => {},
  setLeadName: () => {},
  address: null,
});

type CustomerProviderProps = {
  children: ReactNode | ReactNode[];
};

function CustomerProvider(props: CustomerProviderProps) {
  const [userId, setUserId] = useState<string>();
  const [leadId, setLeadId] = useState<string>();
  const [leadName, setLeadName] = useState<string>();
  const [sasToken, setSasToken] = useState<string>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [currentUser, setCurrentUser] = useState<any>();
  const [address, setAddress] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.guid || userId)
      datadogRum.setUser({ id: currentUser?.guid || userId });
  }, [currentUser, userId]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const utm_source = searchParams.get("utm_source");
    if (utm_source) localStorage.setItem("cobblers_utm_source", utm_source);
    const utm_medium = searchParams.get("utm_medium");
    if (utm_medium) localStorage.setItem("cobblers_utm_medium", utm_medium);
    const utm_campaign = searchParams.get("utm_campaign");
    if (utm_campaign)
      localStorage.setItem("cobblers_utm_campaign", utm_campaign);
    const utm_content = searchParams.get("utm_content");
    if (utm_content) localStorage.setItem("cobblers_utm_content", utm_content);
  }, [window.location]);

  useEffect(() => {
    const user_id = localStorage.getItem("cobbler_user_id");
    if (user_id) {
      setUserId(user_id);
    } else {
      CustomerApi.getUserGuid(getUTMPayload()).then((res) => {
        setUserId(res.guid);
        localStorage.setItem("cobbler_user_id", res.guid);
      });
    }
  }, []);

  useEffect(() => {
    const lead_id = localStorage.getItem("cobbler_lead_id");
    const lead_name = localStorage.getItem("cobbler_lead_name");
    if (lead_id && lead_name) {
      setLeadId(lead_id);
      setLeadName(lead_name);
    } else {
      if (userId) {
        CustomerApi.getLeadId(userId, getUTMPayload()).then((res) => {
          setLeadId(res.guid);
          setLeadName(res.name);
          localStorage.setItem("cobbler_lead_id", res.guid);
          localStorage.setItem("cobbler_lead_name", res.name);
        });
      }
    }
  }, [userId]);

  useEffect(() => {
    if (leadId) {
      CustomerApi.getUploadSasToken(leadId).then((res) => {
        localStorage.setItem(SAS_TOKEN, res.sas);
        setSasToken(res.sas);
      });
    }
  }, [leadId]);

  useEffect(() => {
    fetchMe();
  }, []);

  const onLogOut = () => {
    navigate("/otp-login");
    localStorage.removeItem(ACCESS_TOKEN);
    setIsAuthenticated(false);
  };

  const fetchMe = async (callback?: () => null) => {
    const token = localStorage.getItem(ACCESS_TOKEN);

    if (token) {
      const user = await CustomerApi.getMe();
      setCurrentUser(user);

      const addresses = await CustomerApi.getAddresses();
      setAddress(addresses?.length ? _.first(addresses) : null);

      if (typeof callback === "function") callback();
    }

    setIsAuthenticated(!!token);
  };

  return (
    <CustomerContext.Provider
      value={{
        userId,
        setUserId,
        leadId,
        setLeadId,
        leadName,
        setLeadName,
        sasToken,
        onLogOut,
        currentUser,
        isAuthenticated,
        fetchMe,
        address,
      }}
      {...props}
    />
  );
}

function useCustomers() {
  const context = React.useContext(CustomerContext);

  if (context === undefined) {
    throw new Error("useCustomers must be used within a CustomerProvider");
  }
  return context;
}

export { CustomerProvider, useCustomers };
